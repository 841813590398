<template>
  <div class="container-fluid">
    <div class="row">
      <ShowHeader
        class="col-12 mt-3"
        :image="item.image"
        :icon="'user'"
        :title="item.name"
        :stat="item.stat"
        :statText="item.stat ? $t('Active') : $t('Inactive')"
        :subData="[
          {
            title: $t('tax Identification Number'),
            value: item.tax_number
          }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('customers_edit')
          },
          {
            title: $t('Comprehensive report'),
            icon: 'file-chart-pie',
            link: '/customersDetailsAccounts?customer_id=' + $route.params.id,
            role: $checkRole('reports_show')
          },
          {
            title: $t('Add a batch'),
            icon: 'dollar-sign',
            link: '/salesPayments/create?customer_id=' + $route.params.id,
            role: $checkRole('sales_payments_add')
          },
          {
            title: $t('AddABid'),
            icon: 'calculator',
            link: '/quotations/create?customer_id=' + $route.params.id,
            role: $checkRole('quotations_add')
          },
          {
            title: $t('AddASalesBill'),
            icon: 'file-invoice-dollar',
            link: '/invoices/create?customer_id=' + $route.params.id,
            role: $checkRole('invoices_add')
          },
          {
            title: $t('Add a request'),
            icon: 'briefcase',
            link: '/orders/create?customer_id=' + $route.params.id,
            role: $checkRole('orders_add')
          }
        ]"
      />

      <showCard
        class="col-12"
        :title="$t('Background')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'user',
              title: $t('Customer name'),
              value: item.name
            },
            {
              icon: 'dollar-sign',
              title: $t('Balance'),
              value: $moneyFormat(item.balance)
            }
          ],
          scondCloum: [
            {
              icon: 'dollar-sign',
              title: $t('Opening balance'),
              value: $moneyFormat(item.opening_balance)
            },
            {
              icon: 'toggle-on',
              title: $t('CustomerStatus'),
              value: item.stat == 1 ? $t('allerts.effective') : $t('allerts.Ineffective')
            }
          ]
        }"
      />

      <showCard
        class="col-12"
        :title="$t('BillingAddress')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Address'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('The side'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Zip code'),
              value: item.zip
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('The sequel'),
              value: item.address2
            },
            {
              icon: 'city',
              title: $t('City'),
              value: item.city
            },
            {
              icon: 'globe-americas',
              title: $t('State'),
              value: item.country
            }
          ]
        }"
      />

      <showCard
        class="col-12"
        :title="$t('Communication Information')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'phone',
              title: $t('Mobile number'),
              value: item.mobile2 ? item.mobile + ' - ' + item.mobile2 : item.mobile
            }
          ],
          scondCloum: [
            {
              icon: 'at',
              title: $t('Email'),
              value: item.email
            }
          ]
        }"
      />
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
      <notes class="col-12 col-xl-7" />
      <contacts class="col-12 col-lg-5" />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import ShowHeader from '@/components/show/showHeader.vue'
import showCard from '@/components/show/showCard.vue'
import notes from './components/notes.vue'
import contacts from './components/contacts/index.vue'
import HistoryCard from '@/components/historyCard.vue'

export default {
  data() {
    return {
      path: '/customers',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      var item = this.$database.customers.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
      })
    }
  },
  components: {
    ShowHeader,
    showCard,
    notes,
    contacts,
    HistoryCard
  }
}
</script>
