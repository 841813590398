<template>
  <div v-if="!loading">
    <div
      class="inv-design"
      :class="$option.purchase_returns_print_type == 3 ? 'inv-design-5cm' : ''"
      v-if="$option.purchase_returns_print_type == 1 || $option.purchase_returns_print_type == 3"
    >
      <img :src="logo" class="img-fluid mb-4 sm-inv-img" v-if="$option.image != null" />
      <div class="text-center">
        <p class="f-14">{{ $option.name }}</p>
        <p class="f-12" v-if="$option.address1">{{ $option.address1 }}</p>
        <p class="f-14">{{ item.code }}</p>
        <div style="display: flex">
          <div style="text-align: center">
            <p class="f-12">
              التاريخ :
              <strong>{{
                item.date ? $dateFormat(item.date) : $dateFormat(Date.now() / 1000)
              }}</strong>
            </p>
            <p class="f-12">
              أسم المسئول :
              <strong>{{ item?.user?.name ?? $user?.name }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div>
        <hr />
        <div style="text-align: center">
          <p class="f-12" v-if="item.supplier.name">
            اسم المورد: <strong>{{ item.supplier.name }}</strong>
          </p>
          <p class="f-12" v-if="item.supplier.city || item.supplier.country">
            العنوان:
            <strong>
              {{ item.supplier.city }}{{ item.supplier.city && item.supplier.country ? ',' : '' }}
              {{ item.supplier.country }}</strong
            >
          </p>
          <p class="f-12" v-if="item.supplier?.phone1">
            رقم الهاتف : <strong>{{ item.supplier?.phone1 }}</strong>
          </p>
        </div>
        <hr />
      </div>
      <div class="">
        <table class="table my-4">
          <thead>
            <tr>
              <th class="px-0 bg-transparent border-top-0 text-right">
                <span class="h6">المنتج</span>
              </th>
              <th class="px-0 bg-transparent border-top-0 text-right">
                <span class="h6">الكمية</span>
              </th>
              <th
                class="px-0 bg-transparent border-top-0 text-right"
                v-if="$option.purchase_returns_print_type != 3"
              >
                <span class="h6">السعر</span>
              </th>
              <th class="px-0 bg-transparent border-top-0 text-right">
                <span class="h6">الأجمالي</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="qItem in item.quotationItems" :key="qItem.id">
              <td class="px-0">
                {{ qItem.product_name }}
              </td>
              <td class="px-0">
                {{ parseFloat(qItem.quantity).toFixed(2) }}
                {{ $option.purchase_returns_print_type == 3 ? '* ' + qItem.price : '' }}
              </td>
              <td class="px-0" v-if="$option.purchase_returns_print_type != 3">
                {{ $moneyFormat(qItem.price) }}
              </td>
              <td class="px-0">
                {{ $moneyFormat(qItem.total) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="$option.purchase_returns_print_type != 3 ? 2 : 1" class="px-0">
                <strong>اجمالي الفاتورة</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{
                    parseFloat(
                      item.quotationItems.reduce(
                        (sum, currentItem) => sum + parseFloat(currentItem.quantity),
                        0
                      )
                    ).toFixed(2)
                  }}
                  قطعة
                </span>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.subtotal + item.discount) }}
                </span>
              </td>
            </tr>
            <tr v-if="item.edit">
              <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                <strong>{{ item.edit_text ? item.edit_text : $t('Modification') }}</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.edit) }}
                </span>
              </td>
            </tr>
            <tr v-if="item.delivery">
              <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                <strong>التوصيل</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.delivery) }}
                </span>
              </td>
            </tr>
            <tr v-if="item.edit || item.delivery">
              <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                <strong>المجموع الكلي</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.total) }}
                </span>
              </td>
            </tr>
            <template v-if="$option.show_old_balance">
              <tr>
                <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                  <strong>الرصيد السابق</strong>
                </td>
                <td class="px-0">
                  <span class="h3">
                    {{ $moneyFormat(item.old_balance) }}
                  </span>
                </td>
              </tr>
              <tr>
                <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                  <strong>الرصيد الكلي</strong>
                </td>
                <td class="px-0">
                  <span class="h3">
                    {{ $moneyFormat(item.new_balance) }}
                  </span>
                </td>
              </tr>
              <tr v-if="item.paid">
                <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                  <strong>المدفوع</strong>
                </td>
                <td class="px-0">
                  <span class="h3">
                    {{ $moneyFormat(item.paid) }}
                  </span>
                </td>
              </tr>
              <tr v-if="item.paid">
                <td :colspan="$option.purchase_returns_print_type != 3 ? 3 : 2" class="px-0">
                  <strong>الرصيد النهائي</strong>
                </td>
                <td class="px-0">
                  <span class="h3">
                    {{
                      item.purchasePayment
                        ? $moneyFormat(item.purchasePayment.new_balance)
                        : $moneyFormat(item.new_balance)
                    }}
                  </span>
                </td>
              </tr>
            </template>
          </tfoot>
        </table>
        <p
          class="text-muted mb-0"
          v-html="
            $option.purchase_returns_print_footer != null
              ? $option.purchase_returns_print_footer
              : ''
          "
        ></p>
      </div>
    </div>
    <div class="row invoice-show" v-if="$option.purchase_returns_print_type == 0">
      <div class="col-4">
        <h6 class="text-uppercase text-muted">مرتجع شراء من</h6>
        <p class="text-muted mb-4">
          <strong class="text-body">{{ item.supplier.name }}</strong> <br />
          {{ item.supplier.tax_number }} <br />
          {{ item.supplier?.phone1 }} <br />
          {{ item.supplier.email }} <br />
          {{ item.supplier.address1 }} <br />
          {{ item.supplier.address2 }} <br />
          {{ item.supplier.city }}{{ item.supplier.city && item.supplier.country ? ',' : '' }}
          {{ item.supplier.country }}
          <br v-if="!item.supplier.city || !item.supplier.country" />
        </p>
        <h6 class="text-uppercase text-muted">
          {{ $t('Date') }}
        </h6>
        <p class="mb-2">
          {{ item.date ? $dateFormat(item.date) : $dateFormat(Date.now() / 1000) }}
        </p>
      </div>
      <div class="col-4 text-center">
        <img
          :src="logo"
          class="img-fluid mb-4"
          style="max-width: 4.5rem"
          v-if="$options.image != null"
        />
        <h2 class="mb-2">
          {{ $option.name }}
        </h2>
        <p class="text-muted">{{ item.code }}</p>
      </div>
      <div class="col-4 text-md-right">
        <h6 class="text-uppercase text-muted">مرتجع شراء لـ</h6>
        <p class="text-muted mb-4">
          <strong class="text-body">{{ $option.name }}</strong> <br />
          {{ $option.tax_number }} <br />
          {{ $option.telephone }} <br />
          {{ $option.email }} <br />
          {{ $option.address1 }} <br />
          {{ $option.address2 }} <br />
          {{ $option.city }} {{ $option.city && $option.country ? ',' : '' }}
          {{ $option.country }}
          <br v-if="!$option.city || !$option.country" />
        </p>
        <h6 class="text-uppercase text-muted">
          {{ $t('Expiry date') }}
        </h6>
        <p class="mb-2">
          {{ $dateFormat(item.expiry_date) }}
        </p>
      </div>
    </div>
    <div class="row invoice-show" v-if="$option.purchase_returns_print_type == 0">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table my-2">
            <thead>
              <tr>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('ProductItem') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('description') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Quantity') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Price') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0" v-if="item.discount != 0">
                  <span class="h6">{{ $t('Discount') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0" v-if="item.tax != 0">
                  <span class="h6">{{ $t('Tax') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Total') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="qItem in item.quotationItems" :key="qItem.id">
                <td class="px-0">
                  {{ qItem.product_name }}
                </td>
                <td class="px-0">
                  {{ qItem.description }}
                </td>
                <td class="px-0">
                  {{ parseFloat(qItem.quantity).toFixed(2) }}
                  {{ qItem.unit ? qItem.unit.unit : '' }}
                </td>
                <td class="px-0">
                  {{ $moneyFormat(qItem.price) }}
                </td>
                <td class="px-0" v-if="item.discount != 0">-{{ $moneyFormat(qItem.discount) }}</td>
                <td class="px-0" v-if="item.tax != 0">
                  {{ $moneyFormat(qItem.average) }}
                </td>
                <td class="px-0">
                  {{ $moneyFormat(qItem.total) }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="px-0 border-top border-top-2">
                  <strong>اجمالي الفاتورة</strong>
                </td>
                <td class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{
                      parseFloat(
                        item.quotationItems.reduce(
                          (sum, currentItem) => sum + parseFloat(currentItem.quantity),
                          0
                        )
                      ).toFixed(2)
                    }}
                    قطعة
                  </span>
                </td>
                <td colspan="6" class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{ $moneyFormat(item.subtotal + item.discount) }}
                  </span>
                </td>
              </tr>
              <tr v-if="item.edit">
                <td colspan="2" class="px-0 border-top border-top-2">
                  <strong>{{ item.edit_text ? item.edit_text : $t('Modification') }}</strong>
                </td>
                <td colspan="6" class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{ $moneyFormat(item.edit) }}
                  </span>
                </td>
              </tr>
              <tr v-if="item.delivery">
                <td colspan="2" class="px-0 border-top border-top-2">
                  <strong>التوصيل</strong>
                </td>
                <td colspan="6" class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{ $moneyFormat(item.delivery) }}
                  </span>
                </td>
              </tr>
              <tr v-if="item.edit || item.delivery">
                <td colspan="2" class="px-0 border-top border-top-2">
                  <strong>المجموع الكلي</strong>
                </td>
                <td colspan="6" class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{ $moneyFormat(item.total) }}
                  </span>
                </td>
              </tr>
              <template v-if="$option.show_old_balance">
                <tr>
                  <td colspan="2" class="px-0 border-top border-top-2">
                    <strong>الرصيد السابق</strong>
                  </td>
                  <td colspan="6" class="px-0 border-top border-top-2">
                    <span class="h3">
                      {{ $moneyFormat(item.old_balance) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="px-0 border-top border-top-2">
                    <strong>الرصيد الكلي</strong>
                  </td>
                  <td colspan="6" class="px-0 border-top border-top-2">
                    <span class="h3">
                      {{ $moneyFormat(item.new_balance) }}
                    </span>
                  </td>
                </tr>
                <tr v-if="item.paid">
                  <td colspan="2" class="px-0 border-top border-top-2">
                    <strong>المدفوع</strong>
                  </td>
                  <td colspan="6" class="px-0 border-top border-top-2">
                    <span class="h3">
                      {{ $moneyFormat(item.paid) }}
                    </span>
                  </td>
                </tr>
                <tr v-if="item.paid">
                  <td colspan="2" class="px-0 border-top border-top-2">
                    <strong>الرصيد النهائي</strong>
                  </td>
                  <td colspan="6" class="px-0 border-top border-top-2">
                    <span class="h3">
                      {{ $moneyFormat(parseFloat(item.new_balance) - parseFloat(item.paid)) }}
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="w-100" v-if="item.notes != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">{{ $t('notes') }}</h4>
          <p class="text-muted mb-0">{{ item.notes }}</p>
        </div>
        <div class="w-100" v-if="item.terms != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">{{ $t('Terms of offer') }}</h4>
          <p class="text-muted mb-0">{{ item.terms }}</p>
        </div>
        <div class="w-100 inv-footer" v-if="$option.purchase_returns_print_footer != null">
          <hr class="my-3" />
          <p
            class="text-muted mb-0"
            v-html="
              $option.purchase_returns_print_footer != null
                ? $option.purchase_returns_print_footer
                : ''
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="design-1-container" v-if="$option.purchase_returns_print_type == 4">
      <div class="header">
        <qrcode-vue
          :value="item.qrCode"
          size="130"
          level="H"
          render-as="svg"
          v-if="$option.tax_invoice"
        />
        <div class="companydetails">
          <h1>{{ $option.name }}</h1>
          <p>الرقم الضريبي: {{ $option.tax_number }}</p>
          <p v-if="$option.commercial_register">السجل التجاري :{{ $option.commercial_register }}</p>
          <p v-if="$option.telephone">الهاتف: {{ $option.telephone }}</p>
          <p>{{ $option.country }}</p>
          <p>
            {{ $option.address1 }}
            {{ $option.address1 && $option.city ? ',' : '' }}
            {{ $option.city }}
          </p>
        </div>
      </div>
      <div class="info">
        <div class="invoive-detail">
          <div class="invoice-num">
            <strong>رقم الفاتورة<br />Invoice No</strong>
            <p>{{ item.code }}</p>
          </div>
          <div class="invoice-date">
            <strong>تاريخ الفاتورة<br />Invoice Date</strong>
            <p>
              {{ item.date ? $dateFormat(item.date) : $dateFormat(Date.now() / 1000) }}
            </p>
          </div>
        </div>
        <div class="cleint">
          <h2 class="cleint-name">العميل<br />Cleint</h2>
          <div class="cleint-info" v-if="item.client_type == 0">
            <strong>{{ item.customer.name }}</strong>
            <p v-if="item.customer.tax_number">الرقم الضريبي :{{ item.customer.tax_number }}</p>
            <p>{{ item.customer?.phone1 }}</p>
            <p>{{ item.customer.country }}</p>
            <p>
              {{ item.customer.address1 }}
              {{ item.customer.address1 && item.customer.city ? ',' : '' }}
              {{ item.customer.city }}
            </p>
          </div>
          <div class="cleint-info" v-if="item.client_type == 1">
            <strong>{{ item.supplier.name }}</strong>
            <p v-if="item.supplier.tax_number">الرقم الضريبي :{{ item.supplier.tax_number }}</p>
            <p>{{ item.supplier?.phone1 }}</p>
            <p>{{ item.supplier.country }}</p>
            <p>
              {{ item.supplier.address1 }}
              {{ item.supplier.address1 && item.supplier.city ? ',' : '' }}
              {{ item.supplier.city }}
            </p>
          </div>
        </div>
      </div>
      <div class="container-of-table">
        <table class="mytable">
          <thead>
            <td v-if="$option.item_number">#</td>
            <td>
              الصنف
              <br />
              Item
            </td>
            <td>
              الوصف
              <br />
              Description
            </td>
            <td>
              الكمية
              <br />
              Qty
            </td>
            <td>
              السعر
              <br />
              Price
            </td>
            <td v-if="item.discount != 0 && $option.show_discount_table">
              قيمة الخصم
              <br />
              Discount Amount
            </td>
            <td v-if="item.tax != 0 && $option.show_tax_table">
              قيمة الضريبة
              <br />
              VAT Amount
            </td>
            <td>
              الاجمالي مع الضريبة
              <br />
              Total VAT
            </td>
          </thead>
          <tbody>
            <tr v-for="(qItem, index) in item.quotationItems" :key="index">
              <td v-if="$option.item_number">{{ index + 1 }}</td>
              <td>{{ qItem.product_name }}</td>
              <td>{{ qItem.description }}</td>
              <td>{{ parseFloat(qItem.quantity).toFixed(2) }}</td>
              <td>{{ $moneyFormat(qItem.price) }}</td>
              <td v-if="item.discount != 0 && $option.show_discount_table">
                -{{ $moneyFormat(qItem.discount) }}
              </td>
              <td v-if="item.tax != 0 && $option.show_tax_table">
                {{ $moneyFormat(qItem.average) }}
              </td>
              <td>{{ $moneyFormat(qItem.total) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="money-output">
        <div class="total">
          <strong
            >الإجمالي قبل الضريبة<br />
            Total Before VAT</strong
          >
          <p>
            {{ $moneyFormat(item.subtotal + item.discount) }}
          </p>
        </div>
        <div class="total" v-if="item.edit">
          <strong>
            {{ item.edit_text ? item.edit_text : $t('Modification') }}
          </strong>
          <p>{{ $moneyFormat(item.edit) }}</p>
        </div>
        <div class="total" v-if="item.delivery">
          <strong
            >التوصيل<br />
            delivery</strong
          >
          <p>{{ $moneyFormat(item.delivery) }}</p>
        </div>
        <div class="total" v-if="item.discount">
          <strong
            >خصم<br />
            Discount</strong
          >
          <p>- {{ $moneyFormat(item.discount) }}</p>
        </div>
        <div class="total" v-if="item.tax">
          <strong
            >الضريبة<br />
            Tax</strong
          >
          <p>{{ $moneyFormat(item.tax) }}</p>
        </div>
        <div class="total" v-if="item.edit || item.delivery || item.discount || item.tax">
          <strong>الإجمالي بعد الضريبة<br />Total After VAT</strong>
          <p>
            <strong>{{ $moneyFormat(item.total) }} </strong>
          </p>
        </div>
        <template v-if="$option.show_old_balance">
          <div class="total">
            <strong
              >الرصيد السابق<br />
              Previous Balance</strong
            >
            <p>
              {{
                $moneyFormat($option.balance_type == 0 ? item.old_balance : item.old_balance * -1)
              }}
            </p>
          </div>
          <div class="total">
            <strong
              >الرصيد الكلي<br />
              Total Balance</strong
            >
            <p>
              {{
                $moneyFormat($option.balance_type == 0 ? item.new_balance : item.new_balance * -1)
              }}
            </p>
          </div>
          <div class="total" v-if="item.paid">
            <strong>المدفوع<br />Paid</strong>
            <p>{{ $moneyFormat(item.paid) }}</p>
          </div>
          <div class="total" v-if="item.paid">
            <strong>الرصيد النهائي<br />final balance</strong>
            <p>
              {{
                item.salesPayment
                  ? $moneyFormat(
                      it$option.balance_type == 0
                        ? item.salesPayment.new_balance
                        : item.salesPayment.new_balance * -1
                    )
                  : $moneyFormat(
                      $option.balance_type == 0 ? item.new_balance : item.new_balance * -1
                    )
              }}
            </p>
          </div>
        </template>
      </div>
      <p class="tafqeet-style">اجمالي الفاتورة فقط {{ $tafqeet(item.total) }}</p>
      <div class="w-100" v-if="item.notes != null">
        <hr class="my-3" />
        <h4 class="text-uppercase">{{ $t('invoices.show.notes') }}</h4>
        <div class="mb-0" v-html="item.notes"></div>
      </div>
      <div class="w-100" v-if="item.terms != null">
        <hr class="my-3" />
        <h4 class="text-uppercase">
          {{ $t('invoices.form.termsOfOffer') }}
        </h4>
        <div class="mb-0" v-html="item.terms"></div>
      </div>

      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $option.purchase_returns_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },
  props: {
    item: {},
    loading: null
  }
}
</script>
