<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader
          :title="$t('reports')"
          :description="$t('remaining Balance Of Current Accounts')"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('report Options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('account')"
                :plaseholder="$t('choose Account')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.account_id"
                :value="params.account_id"
                :values="accounts"
              />
              <!-- <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div> -->
              <searchInput
                class="col-md-3"
                :title="$t('section Account')"
                :plaseholder="$t('choose Section')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.account_id"
                :value="params.account_id"
                :values="$database.customerSections"
              />
              <selectInput
                class="col-md-3"
                :title="$t('statue')"
                :plaseholder="$t('chooseStatue')"
                v-model="params.status"
                :values="[
                  { name: $t('debtor'), value: 0 },
                  { name: $t('Creditor'), value: 1 },
                  { name: $t('stopped'), value: 2 }
                ]"
              />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('remaining Balance Of Current Accounts')"
            :details="[
              { title: $t('account Name'), value: accountName },
              {
                title: $t('section Account'),
                value: sectionCustomerName
              },
              { title: $t('statue'), value: statusName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there No Data The Moment')"
            :emptyTableSubText="$t('try Adding Some And Try Again')"
            :cloumns="[
              {
                column: 'name',
                link: '/accounts',
                title: $t('account Name'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'balance',
                title: $t('Balance'),
                type: 'text',
                sort: 'true'
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('Total') },
              { colspan: 1, value: balance }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import searchInput from '@/elements/reports/searchInput.vue'
import selectInput from '@/elements/reports/selectInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
export default {
  data() {
    return {
      path: '/reports/accounts/balance',
      items: [],
      branchArray: [],

      loading: 0,

      balance: 0,

      accounts: [],
      accountName: '',
      sectionCustomerName: '',

      params: {
        branch_id: '',
        account_id: '',
        status: ''
      },
      excel: {
        fileName: 'account balance',
        reportData: '[]'
      }
    }
  },
  methods: {
    getItems() {
      this.$setParams(this.params)
      this.getData()
    },
    getData() {
      var params = this.params
      this.$setParams(params)
      var items = JSON.parse(JSON.stringify(this.$database.accounts))
      items = items.filter((el) => el.stat == 1)
      if (params.orderBy) {
        items = items.sort(this.$dynamicSort(params))
      }
      if (params.account_id) {
        items = items.filter((el) => el.id == params.account_id)
      }

      if (params.status) {
        if (params.status == 0) {
          items = items.filter((el) => el.balance < 0)
        } else if (params.status == 1) {
          items = items.filter((el) => el.balance > 0)
        } else if (params.status == 2) {
          items = items.filter((el) => el.balance == 0)
        }
      }

      if (params.account_id) {
        items = items.filter((el) => el.account_id == params.account_id)
      }

      this.items = items
      this.balance = 0
      this.items.forEach((item) => {
        this.balance += item.balance
        if (this.$option.balance_type == 1) {
          item.balance = item.balance * -1
        }
        item.balance = this.$moneyFormat(item.balance)
      })
      var account = this.accounts.find((el) => el.id == this.params.account_id)
      if (account) {
        this.accountName = account.name
      } else {
        this.accountName = ''
      }

      var sectionCustomer = this.$database.customerSections.find(
        (el) => el.id == this.params.account_id
      )
      if (sectionCustomer) {
        this.sectionCustomerName = sectionCustomer.name
      } else {
        this.sectionCustomerName = ''
      }

      if (params.status) {
        if (params.status == 0) {
          this.statusName = this.$t('debtor')
        } else if (params.status == 1) {
          this.statusName = this.$t('Creditor')
        } else if (params.status == 2) {
          this.statusName = this.$t('stopped')
        } else {
          this.statusName = ''
        }
      } else {
        this.statusName = ''
      }

      if (this.$option.balance_type == 1) {
        this.balance = this.balance * -1
      }
      this.balance = this.$moneyFormat(this.balance)
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    this.accounts = this.$database.accounts.filter((el) => el.stat == 1)
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    searchInput,
    actionButtons,
    selectInput
  }
}
</script>
