import { $t } from '@/helpers/translate'
const shortcuts = [
  { name: 'F1', ctrl: false, button: 'F1' },
  { name: 'F2', ctrl: false, button: 'F2' },
  { name: 'F3', ctrl: false, button: 'F3' },
  { name: 'F4', ctrl: false, button: 'F4' },
  { name: 'F5', ctrl: false, button: 'F5' },
  { name: 'F6', ctrl: false, button: 'F6' },
  { name: 'F7', ctrl: false, button: 'F7' },
  { name: 'F8', ctrl: false, button: 'F8' },
  { name: 'F9', ctrl: false, button: 'F9' },
  { name: 'F10', ctrl: false, button: 'F10' },
  { name: 'F11', ctrl: false, button: 'F11' },
  { name: 'F12', ctrl: false, button: 'F12' },
  { name: 'ctrl+F1', ctrl: true, button: 'F1' },
  { name: 'ctrl+F2', ctrl: true, button: 'F2' },
  { name: 'ctrl+F3', ctrl: true, button: 'F3' },
  { name: 'ctrl+F4', ctrl: true, button: 'F4' },
  { name: 'ctrl+F5', ctrl: true, button: 'F5' },
  { name: 'ctrl+F6', ctrl: true, button: 'F6' },
  { name: 'ctrl+F7', ctrl: true, button: 'F7' },
  { name: 'ctrl+F8', ctrl: true, button: 'F8' },
  { name: 'ctrl+F9', ctrl: true, button: 'F9' },
  { name: 'ctrl+F10', ctrl: true, button: 'F10' },
  { name: 'ctrl+F11', ctrl: true, button: 'F11' },
  { name: 'ctrl+F12', ctrl: true, button: 'F12' },
  { name: 'ctrl+1', ctrl: true, button: '1' },
  { name: 'ctrl+2', ctrl: true, button: '2' },
  { name: 'ctrl+3', ctrl: true, button: '3' },
  { name: 'ctrl+4', ctrl: true, button: '4' },
  { name: 'ctrl+5', ctrl: true, button: '5' },
  { name: 'ctrl+6', ctrl: true, button: '6' },
  { name: 'ctrl+7', ctrl: true, button: '7' },
  { name: 'ctrl+8', ctrl: true, button: '8' },
  { name: 'ctrl+9', ctrl: true, button: '9' },
  { name: 'ctrl+0', ctrl: true, button: '0' }
]

const actions = [
  { name: $t('Customers'), type: 'route', action: '/customers' },
  { name: $t('Add customer'), type: 'route', action: '/customers/create' },
  { name: $t('Invoices'), type: 'route', action: '/invoices' },
  { name: $t('Add invoice'), type: 'route', action: '/invoices/create' },
  { name: $t('Sale returns'), type: 'route', action: '/saleReturns/create' },
  { name: $t('Add sale return'), type: 'route', action: '/saleReturns/create' },

  { name: $t('Suppliers'), type: 'route', action: '/suppliers' },
  { name: $t('Add supplier'), type: 'route', action: '/suppliers/create' },
  { name: $t('Bills'), type: 'route', action: '/bills' },
  { name: $t('Add bill'), type: 'route', action: '/bills/create' },
  { name: $t('Purchase returns'), type: 'route', action: '/purchaseReturns' },
  { name: $t('Add purchase return'), type: 'route', action: '/purchaseReturns/create' },

  { name: $t('Safes'), type: 'route', action: '/safes' },
  { name: $t('Add safe'), type: 'route', action: '/safes/create' },
  { name: $t('Bank accounts'), type: 'route', action: '/bankAccounts' },
  { name: $t('Add bank account'), type: 'route', action: '/bankAccounts/create' },
  { name: $t('Sales returns'), type: 'route', action: '/salesReturns' },
  { name: $t('Add sales return'), type: 'route', action: '/salesReturns/create' },
  { name: $t('Purchase returns'), type: 'route', action: '/purchaseReturns' },
  { name: $t('Add purchase return'), type: 'route', action: '/purchaseReturns/create' },
  { name: $t('Expenses'), type: 'route', action: '/expenses' },
  { name: $t('Add expense'), type: 'route', action: '/expenses/create' },
  { name: $t('Incomes'), type: 'route', action: '/incomes' },
  { name: $t('Add income'), type: 'route', action: '/incomes/create' },
  { name: $t('Safes deposits'), type: 'route', action: '/safesDeposits' },
  { name: $t('Add safes deposit'), type: 'route', action: '/safesDeposits/create' },
  { name: $t('Safes withdrawals'), type: 'route', action: '/safesWithdrawals' },
  { name: $t('Add safes withdrawal'), type: 'route', action: '/safesWithdrawals/create' },
  { name: $t('Safes transfers'), type: 'route', action: '/safesTransfers' },
  { name: $t('Add safes transfer'), type: 'route', action: '/safesTransfers/create' },

  { name: $t('Stores'), type: 'route', action: '/stores' },
  { name: $t('Add store'), type: 'route', action: '/stores/create' },
  { name: $t('Products'), type: 'route', action: '/products' },
  { name: $t('Add product'), type: 'route', action: '/products/create' },
  { name: $t('Materials'), type: 'route', action: '/materials' },
  { name: $t('Add material'), type: 'route', action: '/materials/create' },
  { name: $t('Stores deposits'), type: 'route', action: '/storesDeposits' },
  { name: $t('Add stores deposit'), type: 'route', action: '/storesDeposits/create' },
  { name: $t('Stores withdrawals'), type: 'route', action: '/storesWithdrawals' },
  { name: $t('Add stores withdrawal'), type: 'route', action: '/storesWithdrawals/create' },
  { name: $t('Stores transfers'), type: 'route', action: '/storesTransfers' },
  { name: $t('Add stores transfer'), type: 'route', action: '/storesTransfers/create' },
  { name: $t('Stores orders'), type: 'route', action: '/storesOrders' },
  { name: $t('Add stores Order'), type: 'route', action: '/storesOrders/create' },
  { name: $t('Stores settlements'), type: 'route', action: '/storesSettlements' },
  { name: $t('Add stores settlement'), type: 'route', action: '/storesSettlements/create' },

  { name: $t('Sales cheques'), type: 'route', action: '/salesCheques' },
  { name: $t('Add sales cheque'), type: 'route', action: '/salesCheques/create' },

  { name: $t('Purchase cheques'), type: 'route', action: '/purchaseCheques' },
  { name: $t('Add purchase cheque'), type: 'route', action: '/purchaseCheques/create' },

  { name: $t('Sales discounts'), type: 'route', action: '/salesDiscounts' },
  { name: $t('Add sales discount'), type: 'route', action: '/salesDiscounts/create' },

  { name: $t('Purchase discounts'), type: 'route', action: '/purchaseDiscounts' },
  { name: $t('Add purchase discount'), type: 'route', action: '/purchaseDiscounts/create' },

  { name: $t('Employees'), type: 'route', action: '/employees' },
  { name: $t('Add employee'), type: 'route', action: '/employees/create' },
  { name: $t('Employees salaries'), type: 'route', action: '/employeesSalaries' },
  { name: $t('Add employees salary'), type: 'route', action: '/employeesSalaries/create' },
  { name: $t('Employees incentives'), type: 'route', action: '/employeesIncentives' },
  { name: $t('Add employees incentive'), type: 'route', action: '/employeesIncentives/create' },
  { name: $t('Employees deductions'), type: 'route', action: '/employeesDeductions' },
  { name: $t('Add employees deduction'), type: 'route', action: '/employeesDeductions/create' },
  { name: $t('Employees withdrawals'), type: 'route', action: '/employeesWithdrawals' },
  { name: $t('Add employees withdrawal'), type: 'route', action: '/employeesWithdrawals/create' },

  { name: $t('Partners'), type: 'route', action: '/partners' },
  { name: $t('Add partner'), type: 'route', action: '/partners/create' },
  { name: $t('Partners deposits'), type: 'route', action: '/partnersDeposits' },
  { name: $t('Add partners deposit'), type: 'route', action: '/partnersDeposits/create' },
  { name: $t('Partners withdrawals'), type: 'route', action: '/partnersWithdrawals' },
  { name: $t('Add partners withdrawal'), type: 'route', action: '/partnersWithdrawals/create' },

  { name: $t('Capitals'), type: 'route', action: '/capitals' },
  { name: $t('Add capital'), type: 'route', action: '/capitals/create' },

  { name: $t('Manufacturing models'), type: 'route', action: '/manufacturingModels' },
  { name: $t('Add manufacturing model'), type: 'route', action: '/manufacturingModels/create' },
  { name: $t('Manufacturing operations'), type: 'route', action: '/manufacturingOperations' },
  {
    name: $t('Add manufacturing operation'),
    type: 'route',
    action: '/manufacturingOperations/create'
  },

  { name: $t('Point of sales'), type: 'route', action: '/pointOfSales' },
  { name: $t('Add point of sale'), type: 'route', action: '/pointOfSales/create' },
  { name: $t('Point of sales sessions'), type: 'route', action: '/pointOfSalesSessions' },

  { name: $t('Branches'), type: 'route', action: '/branches' },
  { name: $t('Add branch'), type: 'route', action: '/branches/create' },

  { name: $t('Reports'), type: 'route', action: '/mainReport' },

  { name: $t('Accounting restrictions'), type: 'route', action: '/accountingRestrictions' },
  {
    name: $t('Add accounting restriction'),
    type: 'route',
    action: '/accountingRestrictions/create'
  },
  { name: $t('Accounting tree'), type: 'route', action: '/accountingTree' },
  { name: $t('profits and losses'), type: 'route', action: '/profitsAndLosses' },
  { name: $t('Total report'), type: 'route', action: '/totalReport' },
  { name: $t('Cost centers'), type: 'route', action: '/costCenters' },
  { name: $t('Add cost center'), type: 'route', action: '/costCenters/create' },
  { name: $t('Total accounts'), type: 'route', action: '/totalAccounts' },
  { name: $t('Plugins'), type: 'route', action: '/plugins' },
  { name: $t('Options'), type: 'route', action: '/options' },

  { name: $t('Save invoice'), type: 'action', action: 'checkoutPointOfSale' },
  { name: $t('Restart point of sale'), type: 'action', action: 'restartPointOfSale' },
  { name: $t('Open cash flow'), type: 'action', action: 'openCashPointOfSale' },
  { name: $t('Add product comment'), type: 'action', action: 'addProductCommentPointOfSale' }
]
export { shortcuts, actions }
