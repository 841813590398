<template>
  <div class="row pt-4 quotation-item mb-3 pb-3">
    <div class="col-xl-4">
      <label class="d-xl-none"> {{ $t('Product name') }}</label>
      <div class="search-container">
        <div :class="item.product_id != 0 ? 'add-input' : ''">
          <input
            type="text"
            class="form-control w-100"
            :class="{ 'is-invalid': item.productNameError }"
            autocomplete="off"
            :placeholder="$t('Product name')"
            v-model="item.product_name"
            @change="updateItem()"
            :disabled="true"
          />
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <label class="d-xl-none"> {{ $t('Unit') }} </label>
      <select class="form-control w-100" v-model="item.unit_id">
        <option value="0">{{ $t('Basic unit') }}</option>
        <option v-for="unit in item.units" :key="unit.id" :value="unit.id">
          {{ unit.name }} [{{ unit.quantity }}]
        </option>
      </select>
    </div>
    <div class="col-xl-4">
      <label class="d-xl-none"> {{ $t('Quantity') }} </label>
      <input
        type="number"
        class="form-control w-100"
        :class="{ 'is-invalid': item.quantityError }"
        placeholder="0"
        v-model="item.quantity"
        @change="updateItem()"
      />
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const data = ref({
  path: '/invoiceItems',
  searchProducts: {},
  noSearch: false,
  showItemsList: false
})
const updateItem = () => {
  item.value.total = item.value.quantity * item.value.price
  changePrice()
}
const changePrice = () => {
  emit('changePrice')
}
defineProps(['item', 'index'])
const emit = defineEmits(['changePrice'])
</script>
