export const print = (head, body, copies = 1, printer = 'default', url = 'localhost') => {
  const ws = new WebSocket(`ws://${url}:8080`)
  const content = `<head>${head}</head><body>${body}</body>`
  if (window.location.hostname == 'localhost') return offlinePrint(head, body, copies)

  const printDetails =
    printer == 'default'
      ? {
          options: {
            preview: false,
            copies: 1,
            printBackground: true,
            silent: true
          },
          data: content
        }
      : {
          options: {
            preview: false,
            copies: 1,
            deviceName: printer,
            printBackground: true,
            silent: true
          },
          data: content
        }

  ws.onopen = () => {
    for (let i = 0; i < copies; i++) ws.send(JSON.stringify(printDetails))
  }

  ws.onerror = (error) => {
    offlinePrint(head, body, copies)
    // offlinePrint(content, copies)
  }
}

// old-offlineprint
// const offlinePrint = (content, copies = 1) => {
//   const iframe = document.createElement('iframe')
//   document.body.appendChild(iframe)
//   iframe.contentWindow.document.open()
//   iframe.classList.add('printIframe')
//   iframe.contentWindow.document.write(content)
//   iframe.contentWindow.document.close()
//   // // const doc = iframe.contentDocument || iframe.contentWindow.document
//   // iframe.contentWindow.document.open() // Open document before writing
//   // // Ensure <html>, <head>, and <body> exist
//   // if (!iframe.contentWindow.document.documentElement) {
//   //   iframe.contentWindow.document.appendChild(iframe.contentWindow.document.createElement('html'))
//   // }
//   // if (!iframe.contentWindow.document.head) {
//   //   const headElement = iframe.contentWindow.document.createElement('head')
//   //   iframe.contentWindow.document.documentElement.appendChild(headElement)
//   // }
//   // if (!iframe.contentWindow.document.body) {
//   //   iframe.contentWindow.document.documentElement.appendChild(
//   //     iframe.contentWindow.document.createElement('body')
//   //   )
//   // }

//   iframe.onload = function () {
//     iframe.focus()
//     for (let i = 0; i < copies; i++) iframe.contentWindow.print()
//     iframe.remove()

//     // Print the required number of copies
//     //   for (let i = 0; i < copies; i++) {
//     //     iframe.contentWindow.print()
//     //   }

//     //   // Remove iframe after printing (with delay to ensure print completes)
//     //   setTimeout(() => iframe.remove(), 500)
//     // }

//     // Ensure iframe loads correctly
//     // iframe.src = 'about:blank'
//   }
// }
export const offlinePrint = (head, body, copies = 1) => {
  const myFrame = document.getElementById('inviceIframe')
  const iframeDoc = myFrame.contentWindow.document

  // Close the document to ensure it's ready to be written to
  iframeDoc.close()

  // Inject the CSS into the iframe's <head> section
  iframeDoc.open()
  iframeDoc.write('<head>' + head + '</head>') // Write the CSS in the head
  iframeDoc.write('<body>' + body + '</body>') // Write the HTML content into the body

  // Make sure everything is loaded before printing
  setTimeout(function () {
    myFrame.focus()
    for (let i = 0; i < copies; i++) {
      myFrame.contentWindow.print()
    }
  }, 100)
}
