var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c(_setup.ShowHeader,{staticClass:"col-12 mt-3",attrs:{"image":_setup.item.image,"icon":'user',"title":_setup.item.name,"stat":_setup.item.stat,"statText":_setup.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: '/' + _setup.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('partners_edit')
        },
        {
          title: _vm.$t('Comprehensive report'),
          icon: 'file-chart-pie',
          link: '/partnersDetailsAccounts?partner_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        }
      ]}}),_c('div',{staticClass:"col-12"},[_c(_setup.ShowFile,{attrs:{"file":_setup.item.file}})],1),_c(_setup.ShowCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Background'),"columns":{
        firstColumn: [
          {
            icon: 'user',
            title: _vm.$t('Partner name'),
            value: _setup.item.name
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Balance'),
            value: _vm.$moneyFormat(_vm.$option.balance_type == 1 ? _setup.item.balance * -1 : _setup.item.balance)
          }
        ],
        secondColumn: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Opening balance'),
            value: _vm.$moneyFormat(_setup.item.opening_balance)
          },
          {
            icon: 'toggle-on',
            title: _vm.$t('Partner status'),
            value: _setup.item.stat == 1 ? _vm.$t('Effective') : _vm.$t('Ineffective')
          }
        ]
      }}}),_c(_setup.ShowCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Billing address'),"columns":{
        firstColumn: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Address'),
            value: _setup.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('The side'),
            value: _setup.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('Zip code'),
            value: _setup.item.zip
          }
        ],
        secondColumn: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('The sequel'),
            value: _setup.item.address2
          },
          { icon: 'city', title: _vm.$t('City'), value: _setup.item.city },
          {
            icon: 'globe-americas',
            title: _vm.$t('State'),
            value: _setup.item.country
          }
        ]
      }}}),_c(_setup.ShowCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Communication  information'),"columns":{
        firstColumn: [
          {
            icon: 'phone',
            title: _vm.$t('Mobile number'),
            value: _setup.item.mobile
          }
        ],
        secondColumn: [{ icon: 'at', title: _vm.$t('Email'), value: _setup.item.email }]
      }}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }