<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Validity alerts')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Store')"
                :plaseholder="$t('Choose store')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
              <searchInput
                class="col-md-3"
                :title="$t('Supplier')"
                :plaseholder="$t('Choose supplier')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.supplier_id"
                :value="params.supplier_id"
                :values="$database.suppliers"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Category')"
                :plaseholder="$t('Choose category')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.section_id"
                :value="params.section_id"
                :values="$database.sections"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Product')"
                :plaseholder="$t('Choose product')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.product_id"
                :value="params.product_id"
                :values="$database.products"
              />
              <label for="zero_quantity_allow" class="custom-control-label"></label>
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="days_remaining"> {{ $t('Days remaining') }} </label>
            <input
              id="days_remaining"
              v-model="params.days_remaining"
              class="form-control"
              type="number"
            />
          </div>
          <actionButtons class="col-md-3" />
        </div>
      </div>
    </div>

    <div id="reportShow">
      <reportHeader
        :title="$t('Validity alerts')"
        :details="[
          { title: 'اسم المخزن', value: storeName },
          { title: 'اسم المورد', value: supplierName },
          { title: 'التصنيف', value: sectionName },
          { title: 'المنتج', value: productName }
        ]"
      />
      <div class="card">
        <div class="card-header">
          <h4 class="card-header-title p-4">
            <i class="fal fa-chart-bar"></i>
            {{ $t('Validity alerts') }}
          </h4>
          <div style="text-align: center" class="col-4">
            <p class="mt-1" v-if="storeName">
              <strong>{{ $t('Store name') }}: </strong>{{ storeName }}
            </p>

            <p class="mt-1" v-if="supplierName">
              <strong>{{ $t('Supplier name') }}: </strong>{{ supplierName }}
            </p>

            <p class="mt-1" v-if="sectionName">
              <strong> {{ $t('Category') }}: </strong>{{ sectionName }}
            </p>

            <p class="mt-1" v-if="productName">
              <strong> {{ $t('Product') }}: </strong>{{ productName }}
            </p>
          </div>
          <div class="col-4"></div>
        </div>
      </div>
      <reportTable
        :emptyTableText="$t('there are no data at the moment')"
        :emptyTableSubText="$t('Try adding some items and try again')"
        :cloumns="[
          {
            column: 'name',
            link: '/products',
            title: $t('Product'),
            type: 'reportMainLink',
            sort: true
          },
          {
            column: 'section_name',
            title: $t('Section'),
            type: 'text'
          },
          { column: 'store_name', title: $t('Store'), type: 'text' },
          { column: 'quantity', title: $t('Quantity'), type: 'text' },
          { column: 'expire_date', title: $t('Expire date'), type: 'text' }
        ]"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/stores/validityAlerts',
      items: [],
      branchArray: [],

      loading: 0,
      storeName: '',
      supplierName: '',
      sectionName: '',
      productName: '',

      params: {
        store_id: '',
        supplier_id: '',
        section_id: '',
        product_id: '',
        branch_id: '',
        days_remaining: 5
      },
      excel: {
        fileName: 'Validity alerts',
        reportData: '[]'
      }
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []

      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        var supplier = this.$database.suppliers.find((el) => el.id == this.params.supplier_id)
        if (supplier) {
          this.supplierName = supplier.name
        } else {
          this.supplierName = ''
        }

        var section = this.$database.sections.find((el) => el.id == this.params.section_id)
        if (section) {
          this.sectionName = section.name
        } else {
          this.sectionName = ''
        }

        var store = this.$database.stores.find((el) => el.id == this.params.store_id)
        if (store) {
          this.storeName = store.name
        } else {
          this.storeName = ''
        }
        var product = this.$database.products.find((el) => el.id == this.params.product_id)
        if (product) {
          this.productName = product.name
        } else {
          this.productName = ''
        }

        this.loading = 0
      })
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title')?.textContent
        var data = document.querySelector('.report-data')?.textContent
        var date = document.querySelector('.report-date')?.textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    // this.params = this.$getParams()
    this.$updateDatabase(['stores'])
    this.getData()
  },
  components: {
    reportTable,
    PageHeader,
    searchInput,
    actionButtons
  }
}
</script>
