var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.vueCustomScrollbar,{staticClass:"navbar navbar-vertical fixed-left navbar-expand-md navbar-light",attrs:{"settings":_setup.settings}},[_c('div',{ref:"elementRef",staticClass:"container-fluid"},[_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#sidebarCollapse","aria-controls":"sidebarCollapse","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_setup.toggleMobile}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#sidebarCollapse","aria-controls":"sidebarCollapse","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":function($event){_vm.toggleMobileNave
        _setup.showMobileMenu = false}}},[(_vm.$checkRole('profile_edit'))?_c('router-link',{staticClass:"navbar-user-link nav-icon dropdown-toggle w-100",attrs:{"to":'/profile'}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(_vm.$user.name)+" ")])]):_vm._e(),(!_vm.$checkRole('profile_edit'))?_c('div',{staticClass:"navbar-user-link nav-icon dropdown-toggle w-100"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(_vm.$user.name)+" ")])]):_vm._e()],1),_c(_setup.SearchBar),_c(_setup.vueCustomScrollbar,{staticClass:"collapse navbar-collapse",class:{ show: _setup.showMobileMenu },attrs:{"settings":_setup.settings2,"id":"sidebarCollapse"}},[(_setup.selectedLinks.length != 0)?_c('ul',{staticClass:"navbar-nav"},_vm._l((_setup.selectedLinks),function(link,index){return _c(_setup.singleItem,{key:index,attrs:{"to":link.link.substring(1),"icon":'far fa-file',"headName":link.name}})}),1):_vm._e(),(_setup.selectedLinks.length == 0)?_c('ul',{staticClass:"navbar-nav"},[_c(_setup.singleItem,{attrs:{"to":'',"icon":'far fa-tachometer-alt-fastest',"headName":_vm.$t('dashboard')}})],1):_vm._e(),(_setup.selectedLinks.length == 0)?_c('hr',{staticClass:"navbar-divider my-3"}):_vm._e(),(_setup.selectedLinks.length == 0)?_c('ul',{staticClass:"navbar-nav"},[(
            _vm.$checkMultiRoles([
              'customers_show',
              'quotations_show',
              'invoices_show',
              'sale_returns_show',
              'orders_show'
            ])
          )?_c(_setup.multiItem,{attrs:{"icon":'far fa-chart-line',"headName":_vm.$t('sales'),"items":[
            {
              name: _vm.$t('customers'),
              link: '/customers',
              role: _vm.$checkRole('customers_show')
            },
            {
              name: _vm.$t('salesInvoices'),
              link: '/invoices',
              role: _vm.$checkRole('invoices_show')
            },
            {
              name: _vm.$t('salesReturns'),
              link: '/saleReturns',
              role: _vm.$checkRole('sale_returns_show')
            }
          ]}}):_vm._e(),(_vm.$checkMultiRoles(['purchase_returns_show', 'bills_show', 'suppliers_show']))?_c(_setup.multiItem,{attrs:{"icon":'far fa-shopping-cart',"headName":_vm.$t('purchases'),"items":[
            {
              name: _vm.$t('Suppliers'),
              link: '/suppliers',
              role: _vm.$checkRole('suppliers_show')
            },
            {
              name: _vm.$t('Bills'),
              link: '/bills',
              role: _vm.$checkRole('bills_show')
            },
            {
              name: _vm.$t('purchaseReturns'),
              link: '/purchaseReturns',
              role: _vm.$checkRole('purchase_returns_show')
            }
          ]}}):_vm._e(),(
            _vm.$checkMultiRoles([
              'safes_show',
              'safes_deposits_show',
              'safes_withdrawals_show',
              'safes_transfers_show',
              'bank_accounts_show',
              'sales_payments_show',
              'purchase_payments_show',
              'expenses_show',
              'sales_discounts_show',
              'purchase_discounts_show'
            ])
          )?_c(_setup.multiItem,{attrs:{"icon":'far fa-treasure-chest',"headName":_vm.$t('Safes'),"items":[
            {
              name: _vm.$t('lockersList'),
              link: '/safes',
              role: _vm.$checkRole('safes_show')
            },
            {
              name: _vm.$t('bankAccounts'),
              link: '/bankAccounts',
              role: _vm.$checkRole('bank_accounts_show')
            },
            {
              name: _vm.$t('salePayments'),
              link: '/salesPayments',
              role: _vm.$checkRole('sales_payments_show')
            },
            {
              name: _vm.$t('purchasePayments'),
              link: '/purchasePayments',
              role: _vm.$checkRole('purchase_payments_show')
            },
            {
              name: _vm.$t('Expenses'),
              link: '/expenses',
              role: _vm.$checkRole('expenses_show')
            },
            {
              name: _vm.$t('Regular Expenses'),
              link: '/regularExpenses',
              role: _vm.$checkRole('expenses_show')
            },
            {
              name: _vm.$t('Incomes'),
              link: '/incomes',
              role: _vm.$checkRole('expenses_show')
            },
            // {
            //   name: $t('Tax payment'),
            //   link: '/taxPayments/',
            //   role: $checkRole('tax_payments_show')
            // },

            {
              name: _vm.$t('Deposits'),
              link: '/safesDeposits',
              role: _vm.$checkRole('safes_deposits_show')
            },

            {
              name: _vm.$t('Withdrawals'),
              link: '/safesWithdrawals',
              role: _vm.$checkRole('safes_withdrawals_show')
            },
            {
              name: _vm.$t('Transfers'),
              link: '/safesTransfers',
              role: _vm.$checkRole('safes_transfers_show')
            }
          ]}}):_vm._e(),(
            _vm.$checkMultiRoles([
              'stores_show',
              'products_show',
              'stores_deposits_show',
              'stores_withdrawals_show',
              'stores_transfers_show'
            ])
          )?_c(_setup.multiItem,{attrs:{"icon":'far fa-store-alt',"headName":_vm.$t('stores'),"items":[
            {
              name: _vm.$t('storesList'),
              link: '/stores',
              role: _vm.$checkRole('stores_show')
            },
            {
              name: _vm.$t('Products and services'),
              link: '/products',
              role: _vm.$checkRole('products_show')
            },
            {
              name: _vm.$t('Materials'),
              link: '/materials',
              role: _vm.$checkRole('materials_show')
            },
            {
              name: _vm.$t('Deposits'),
              link: '/storesDeposits',
              role: _vm.$checkRole('stores_deposits_show')
            },
            {
              name: _vm.$t('Withdrawals'),
              link: '/storesWithdrawals',
              role: _vm.$checkRole('stores_withdrawals_show')
            },
            {
              name: _vm.$t('Transfers'),
              link: '/storesTransfers',
              role: _vm.$checkRole('stores_transfers_show')
            },

            {
              name: _vm.$t('Stores loading'),
              link: '/storesOrders',
              role: _vm.$checkRole('stores_orders_show')
            },
            {
              name: _vm.$t('Stores Settlements'),
              link: '/storesSettlements',
              role: true
            }
          ]}}):_vm._e(),(_vm.$checkMultiRoles(['sales_cheques_show', 'purchase_cheques_show']))?_c(_setup.multiItem,{attrs:{"icon":'far fa-money-check-alt',"headName":_vm.$t('Cheques'),"items":[
            {
              name: _vm.$t('Sales cheques'),
              link: '/salesCheques',
              role: _vm.$checkRole('sales_cheques_show')
            },
            {
              name: _vm.$t('purchaseCheques'),
              link: '/purchaseCheques',
              role: _vm.$checkRole('purchase_cheques_show')
            }
          ]}}):_vm._e(),(_vm.$checkMultiRoles(['sales_discounts_show', 'purchase_discounts_show']))?_c(_setup.multiItem,{attrs:{"icon":'far fa-badge-percent',"headName":_vm.$t('Discounts'),"items":[
            {
              name: _vm.$t('Sales Discounts'),
              link: '/salesDiscounts',
              role: _vm.$checkRole('sales_discounts_show')
            },
            {
              name: _vm.$t('Purchase discounts'),
              link: '/purchaseDiscounts',
              role: _vm.$checkRole('purchase_discounts_show')
            }
          ]}}):_vm._e(),(
            _vm.$checkMultiRoles([
              'employees_show',
              'employees_Salaries_show',
              'employees_Salaries_show',
              'employees_incentives_show',
              'employees_deductions_show',
              'employees_withdrawals_show'
            ])
          )?_c(_setup.multiItem,{attrs:{"icon":'far fa-user-chart',"headName":_vm.$t('Employees'),"items":[
            {
              name: _vm.$t('employeesList'),
              link: '/employees',
              role: _vm.$checkRole('employees_show')
            },
            {
              name: _vm.$t('employeeSalaries'),
              link: '/employeesSalaries',
              role: _vm.$checkRole('employees_Salaries_show')
            },
            {
              name: _vm.$t('EmployeeIncentives'),
              link: '/employeesIncentives',
              role: _vm.$checkRole('employees_incentives_show')
            },
            {
              name: _vm.$t('EmployeeDiscounts'),
              link: '/employeesDeductions',
              role: _vm.$checkRole('employees_deductions_show')
            },
            {
              name: _vm.$t('Employees withdrawals'),
              link: '/employeesWithdrawals',
              role: _vm.$checkRole('employees_withdrawals_show')
            }
          ]}}):_vm._e(),(
            _vm.$checkMultiRoles([
              'partners_show',
              'partners_deposits_show',
              'partners_withdrawals_show',
              'partners_settlements_show',
              'partners_profits_show'
            ])
          )?_c(_setup.multiItem,{attrs:{"icon":'far fa-handshake',"headName":_vm.$t('partners'),"items":[
            {
              name: _vm.$t('partners'),
              link: '/partners',
              role: _vm.$checkRole('partners_show')
            },
            {
              name: _vm.$t('Partners deposits'),
              link: '/partnersDeposits',
              role: _vm.$checkRole('partners_deposits_show')
            },
            {
              name: _vm.$t('Withdrawal of partners'),
              link: '/partnersWithdrawals',
              role: _vm.$checkRole('partners_withdrawals_show')
            }
            // {
            //   name: $t('Partners settlement'),
            //   link: '/partnersSettlements',
            //   role: $checkRole('partners_settlements_show')
            // },
            // {
            //   name: $t('Divide the profits'),
            //   link: '/partnersProfits',
            //   role: $checkRole('partners_profits_show')
            // }
          ]}}):_vm._e(),(_vm.$checkRole('capitals_show'))?_c(_setup.singleItem,{attrs:{"to":'capitals',"icon":'far fa-building',"headName":_vm.$t('Fixed assets')}}):_vm._e(),(_vm.$checkMultiRoles(['manufacturing_models_show', 'manufacturing_operations_show']))?_c(_setup.multiItem,{attrs:{"icon":'far fa-hammer',"headName":_vm.$t('Manufacturing processes'),"items":[
            {
              name: _vm.$t('ManufacturingModels'),
              link: '/manufacturingModels',
              role: _vm.$checkRole('manufacturing_models_show')
            },
            {
              name: _vm.$t('OperationsList'),
              link: '/manufacturingOperations',
              role: _vm.$checkRole('manufacturing_operations_show')
            }
            // {
            //   name: $t('materialDeposit'),
            //   link: '/manufacturingDeposits',
            //   role: $checkRole('manufacturing_operations_show')
            // },
            // {
            //   name: $t('productsWithdrawal'),
            //   link: '/manufacturingWithdrawals',
            //   role: $checkRole('manufacturing_operations_show')
            // }
          ]}}):_vm._e(),(_vm.$checkMultiRoles(['cities_show']))?_c(_setup.multiItem,{attrs:{"icon":'far fa-shipping-fast',"headName":_vm.$t('Shipping'),"items":[
            {
              name: _vm.$t('freightForwarders'),
              link: '/deliveries',
              role: true
            },
            {
              name: _vm.$t('Cities'),
              link: '/cities',
              role: _vm.$checkRole('cities_show')
            },
            {
              name: _vm.$t('pickupLocations'),
              link: '/pickupLocations',
              role: _vm.$checkRole('cities_show')
            },
            {
              name: _vm.$t('shippingOperations'),
              link: '/shippingOperations',
              role: _vm.$checkRole('cities_show')
            },
            {
              name: _vm.$t('shippingAddresses'),
              link: '/shippingAddresses',
              role: _vm.$checkRole('cities_show')
            }
          ]}}):_vm._e(),(_vm.$checkMultiRoles(['point_of_sales_show']))?_c(_setup.multiItem,{attrs:{"icon":'far fa-cash-register',"headName":_vm.$t('pointOfSales'),"items":[
            {
              name: _vm.$t('pointsOfSale'),
              link: '/pointOfSales',
              role: true
            },
            {
              name: _vm.$t('points Of Sales Sessions'),
              link: '/pointOfSalesSessions',
              role: _vm.$checkRole('point_of_sales_sessions_show')
            }
          ]}}):_vm._e(),(_vm.$checkRole('branches_show'))?_c(_setup.singleItem,{attrs:{"to":'branches',"icon":'far Example of th-large fa-th-large',"headName":_vm.$t('Branches')}}):_vm._e()],1):_vm._e(),(_vm.$checkRole('reports_show') && _setup.selectedLinks.length == 0)?_c('ul',{staticClass:"navbar-nav"},[(_vm.$checkRole('reports_show'))?_c(_setup.singleItem,{attrs:{"to":'mainReport',"icon":'far fa-chart-bar',"headName":_vm.$t('Reports')}}):_vm._e(),(_vm.$checkRole('accounting_show'))?_c(_setup.multiItem,{attrs:{"icon":'far fa-balance-scale',"headName":_vm.$t('Accounting'),"items":[
            // {
            //   name: $t('Daily restrictions'),
            //   link: '/dailyRestrictions',
            //   role: true
            // },
            {
              name: _vm.$t('Accounting restrictions'),
              link: '/accountingRestrictions',
              role: _vm.$checkRole('accounting_restrictions_show')
            },
            // {
            //   name: $t('Auto restrictions'),
            //   link: '/autoRestrictions',
            //   role: $checkRole('accounting_restrictions_show')
            // },
            {
              name: _vm.$t('Accounting tree'),
              link: '/accountingTree',
              role: true
            },
            {
              name: _vm.$t('Profits and losses'),
              link: '/profitsAndLosses',
              role: true
            },
            {
              name: _vm.$t('Balance sheet'),
              link: '/totalReport',
              role: true
            },
            {
              name: _vm.$t('Profits by invoices'),
              link: '/profitsByInvoices',
              role: true
            },
            // {
            //   name: $t('Daily restrictions'),
            //   link: '/dailyRestrictions',
            //   role: true
            // },
            {
              name: _vm.$t('Cost Centers'),
              link: '/costCenters',
              role: true
            },
            {
              name: _vm.$t('Account Statements'),
              link: '/totalAccounts',
              role: true
            }
          ]}}):_vm._e()],1):_vm._e(),(_setup.selectedLinks.length == 0)?_c('div',{staticClass:"mt-auto"}):_vm._e(),(_setup.selectedLinks.length == 0)?_c('ul',{staticClass:"navbar-nav"},[(_vm.$checkRole('options_show'))?_c(_setup.singleItem,{attrs:{"to":'plugins',"icon":'far fa-plug',"headName":_vm.$t('Plugins')}}):_vm._e(),(_vm.$checkRole('options_show'))?_c(_setup.singleItem,{attrs:{"to":'options',"icon":'far fa-cog',"headName":_vm.$t('Settings')}}):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link c-pointer",on:{"click":_vm.$logout}},[_c('i',{staticClass:"far fa-sign-out"}),_vm._v(" "+_vm._s(_vm.$t('logout'))+" ")])])],1):_vm._e()])],1),_c('div',{staticClass:"sidebar-user"},[_c('router-link',{attrs:{"to":'/profile'}},[_c('div',{staticClass:"sidebar-user-flex"},[_c('div',{staticClass:"sidebar-user-img",style:(_setup.shownImage ? 'background-image: ' + _setup.shownImage + ';' : '')}),_c('div',{staticClass:"sidebar-user-info"},[_c('h3',[_vm._v(_vm._s(_vm.$user.name))]),_c('p',[_vm._v(_vm._s(_vm.$user.email))])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }