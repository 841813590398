<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <PurchasePayment :item="item" />
    </div>
  </div>
</template>
<script setup>
import PurchasePayment from './components/purchasePayment.vue'

import { print } from '@/helpers/print'
import Vue from 'vue'
const printItem = () => {
  // Prepare content
  const html = document.getElementById('printMe').innerHTML
  const css =
    '<meta charset="utf-8" /><style>' +
    Vue.prototype.$print +
    Vue.prototype.$option.invoice_css +
    '</style>'
  const content = '<head>' + css + '</head><body>' + html + '</body>'
  print(css, html)
  emits('onFinish')
}

// Print item
setTimeout(() => printItem(), 40)

const props = defineProps(['item'])
const emits = defineEmits(['onFinish'])
</script>
