var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Inactive products')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Product'),"plaseholder":_vm.$t('Choose product'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.$database.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('div',{staticClass:"col-md-3"},[_c('label',{staticClass:"col-md-12"},[_vm._v(_vm._s(_vm.$t('Branches')))]),_c('multiselect',{attrs:{"options":_vm.$database.branches,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.$t('Choose the branch'),"label":"name","track-by":"name","hide-selected":true},model:{value:(_vm.branchArray),callback:function ($$v) {_vm.branchArray=$$v},expression:"branchArray"}})],1),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Inactive products'),"details":[{ title: _vm.$t('Product name'), value: _vm.customerName }]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'name',
              link: '/customers',
              title: _vm.$t('Product name'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              title: _vm.$t('Quantity'),
              type: 'text',
              sort: true
            },
            {
              column: 'date',
              title: _vm.$t('last operation date'),
              type: 'text',
              sort: true
            }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }