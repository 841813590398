var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Inactive Customers')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Customer'),"plaseholder":_vm.$t('Choose Customer'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.$database.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('section Customer'),"plaseholder":_vm.$t('choose Section'),"show":'name',"refrance":'id',"value":_vm.params.account_id,"values":_vm.$database.customerSections},model:{value:(_vm.params.account_id),callback:function ($$v) {_vm.$set(_vm.params, "account_id", $$v)},expression:"params.account_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Inactive Customers'),"details":[
            { title: _vm.$t('Customer name'), value: _vm.customerName },
            {
              title: _vm.$t('section Customer'),
              value: _vm.sectionCustomerName
            }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data'),"emptyTableSubText":_vm.$t('Try adding some and try again'),"cloumns":[
            {
              column: 'name',
              link: '/customers',
              title: _vm.$t('Customer Name'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              title: _vm.$t('Balance'),
              type: 'text',
              sort: true
            },
            {
              column: 'date',
              title: _vm.$t('date of the last transaction'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: _vm.$t('amount of the last transaction'),
              type: 'text',
              sort: true
            }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }