<template>
  <Layout>
    <!-- Card header -->
    <div class="card-header mt-2">
      <h4 class="card-header-title mt-2">{{ $t('Add Item') }}</h4>
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <!-- Card body -->
    <div class="p-lg-4 p-3">
      <SearchInput
        :title="$t('PriceList')"
        :options="$database.priceLists"
        v-model:value="item.pricelist_id"
        :errors="getInputError('pricelist_id')"
      />
    </div>

    <div class="p-lg-4 p-3">
      <div>
        <SearchInput
          :title="$t('Product Details')"
          :options="$database.details"
          v-model:value="item.detail_id"
          :errors="getInputError('product_details')"
        />
      </div>
    </div>
    <div class="p-lg-4 p-3" v-if="getProductUnits(item.product_id)">
      <SearchInput
        :title="$t('Unit')"
        :options="item.product_id ? getProductUnits(item.product_id) : $database.units"
        v-model:value="item.unit_id"
        :errors="getInputError('unit_id')"
      />
    </div>
    <div class="p-lg-4 p-3">
      <div>
        <Input
          type="number"
          :title="$t('Price')"
          v-model:value="item.price"
          :errors="getInputError('price')"
        />
      </div>
    </div>
    <div class="p-lg-4 p-3">
      <div>
        <Input
          type="number"
          :title="$t('Cost')"
          v-model:value="item.cost"
          :errors="getInputError('cost')"
        />
      </div>
    </div>

    <!-- Card footer -->
    <div class="card-footer card-footer-boxed">
      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-danger m-1" @click="$emit('close')">
          <i class="far fa-times"></i>
          {{ $t('Close') }}
        </button>
        <button class="btn btn-dark m-1" @click="$emit('addItem')">
          <i class="far fa-save"></i>
          {{ $t('Save') }}
        </button>
      </div>
    </div>
  </Layout>
</template>
<script setup>
import { ref } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'
import Vue from 'vue'
import Layout from '@/layouts/popUpLayout.vue'
// form components
import Form from '@/components/form/index'
import Input from '@/components/form/inputs/input'
import Select from '@/components/form/inputs/select.vue'
import FileInput from '@/components/form/inputs/fileInput'
import SearchInput from '@/components/form/inputs/searchInput.vue'
import Textarea from '@/components/form/inputs/textarea'
import TableInput from '@/components/form/table/index.vue'
// helpers functions
import formHock from '@/helpers/formHock'

// form validations
import { required, minLength, maxLength, numeric, helpers } from '@vuelidate/validators'

// Use toast
const toast = createToastInterface()
const Database = Vue.prototype.$database
const getProductUnits = (product_id) => {
  if (product_id == null || product_id == undefined) {
    return false
  }
  if (Database.productUnits.filter((el) => el.product_id == product_id).length == 0) {
    return false
  }
  const unit_id = Database.productUnits.filter((el) => el.product_id == product_id)[0].unit_id
  if (unit_id != null || unit_id != undefined) {
    return Database.units.filter((el) => el.id == unit_id)
  } else return false
}
// Item logic
const formSetup = {
  priceList_id: null,
  detail_id: null,
  cost: 0,
  price: 0
}

// Validations
const rules = {}
console.log('props-filteredItems', props.filteredItems)

// Import from form hock
const { item, getInputError, saveItem } = formHock({
  checkOnly: true,
  formSetup: formSetup,
  rules: rules
})

const props = defineProps(['item', 'filteredItems', 'addItem'])
const emits = defineEmits(['close', 'addItem'])
</script>
