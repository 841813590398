<template>
  <Layout>
    <!-- page header component -->
    <PageHeader
      :title="$t('Add Customer')"
      :description="$t('From here you can create a new customer')"
      v-if="$route.name == 'customersCreate'"
    />
    <PageHeader
      :title="$t('Edit customer')"
      :description="$t('From here you can modify the customer information')"
      v-if="$route.name == 'customersEdit'"
    />
    <!-- Form component-->
    <Form @submit="onSubmit" :loading="loading" :params="params">
      <div class="row">
        <div class="col-12">
          <Divider
            :title="$t('Basic information')"
            :description="$t('Customer basic information')"
            :noLine="true"
          />
        </div>
        <div class="col-md-6">
          <Input
            type="text"
            :title="$t('Customer Name')"
            v-model:value="item.name"
            :errors="getInputError('name')"
          />
        </div>
        <div class="col-md-6">
          <Input
            type="number"
            :title="$t('Code')"
            v-model:value="item.code"
            :errors="getInputError('code')"
          />
        </div>
        <div class="col-md-6">
          <Input
            type="number"
            :title="$t('Tax identification number')"
            v-model:value="item.tax_number"
            :errors="getInputError('tax_number')"
          />
        </div>
        <div class="col-md-6">
          <SearchInput
            :title="$t('Account section')"
            :options="$database.customerSections"
            v-model:value="item.account_id"
            :errors="getInputError('account_id')"
          />
        </div>
        <div class="col-md-6">
          <Checkbox
            :title="$t('Customer case')"
            v-model:value="item.status"
            :errors="getInputError('status')"
          />
        </div>
        <div class="col-12">
          <Divider
            :title="$t('Account Settings')"
            :description="$t('From here you can control settings accounts')"
          />
        </div>
        <div class="col-md-6">
          <Select
            :title="$t('Balance type')"
            v-model:value="item.balance_type"
            :disabled="$route.name == 'customersEdit' && !$checkRole('customers_edit_balance')"
            :values="[
              { value: 0, name: $t('Cumulative') },
              { value: 1, name: $t('Creditor and Debitor') }
            ]"
            :errors="getInputError('balance_type')"
          />
        </div>

        <div class="col-md-6">
          <Input
            type="number"
            :title="$t('Initial balance')"
            v-model:value="item.opening_balance"
            :disabled="
              ($route.name == 'customersEdit' && !$checkRole('customers_edit_balance')) ||
              item.balance_type == 1
            "
            :errors="getInputError('opening_balance')"
            :after="$option.currency"
          />
        </div>

        <div class="col-md-6">
          <Input
            type="number"
            v-if="item.balance_type == 1"
            :title="$t('Debit')"
            v-model:value="item.opening_debit"
            :disabled="$route.name == 'customersEdit' && !$checkRole('customers_edit_balance')"
            :errors="getInputError('opening_debit')"
            :after="$option.currency"
          />
        </div>

        <div class="col-md-6">
          <Input
            type="number"
            v-if="item.balance_type == 1"
            :title="$t('Creditor')"
            v-model="item.opening_credit"
            :disabled="$route.name == 'customersEdit' && !$checkRole('customers_edit_balance')"
            :errors="getInputError('opening_credit')"
            :after="$option.currency"
          />
        </div>
        <div class="col-md-6">
          <SearchInput
            :title="$t('Price list')"
            :options="$database.priceLists"
            v-model:value="item.priceList_id"
            :errors="getInputError('priceList_id')"
          />
        </div>
        <div class="col-md-6">
          <Input
            type="number"
            :title="$t('Debit limit')"
            v-model:value="item.max_balance"
            :errors="getInputError('max_balance')"
            :after="$option.currency"
          />
        </div>

        <div class="col-12">
          <Divider
            :title="$t('Billing address')"
            :description="$t('The title Of billing and the invoice is recorded')"
          />
        </div>
        <div class="col-md-6">
          <Input
            :title="$t('Title')"
            v-model:value="item.address1"
            :errors="getInputError('address1')"
          />
        </div>
        <div class="col-md-6">
          <Input
            :title="$t('Itinerary')"
            v-model:value="item.address2"
            :errors="getInputError('address2')"
          />
        </div>
        <div class="col-md-6">
          <Input :title="$t('City')" v-model:value="item.city" :errors="getInputError('city')" />
        </div>
        <div class="col-md-6">
          <Input
            :title="$t('Postal code')"
            v-model:value="item.zip_code"
            :errors="getInputError('zip_code')"
          />
        </div>
        <div class="col-md-6">
          <Input :title="$t('State')" v-model:value="item.state" :errors="getInputError('state')" />
        </div>
        <div class="col-md-12">
          <Divider
            :title="$t('Contact information')"
            :description="$t('Supplier contact information')"
          />
        </div>
        <div class="col-md-6">
          <Input
            :title="$t('Mobile number')"
            v-model:value="item.phone1"
            :errors="getInputError('phone1')"
          />
        </div>
        <div class="col-md-6">
          <Input
            :title="$t('Another number')"
            v-model:value="item.phone2"
            :errors="getInputError('phone2')"
          />
        </div>
        <div class="col-md-6">
          <Input :title="$t('Email')" v-model:value="item.email" :errors="getInputError('email')" />
        </div>
      </div>
    </Form>
  </Layout>
</template>
<script setup>
import Vue, { watch } from 'vue'
// layout component
import Layout from '@/layouts/main.vue'
// page components
import PageHeader from '@/components/pageHeader'
import Divider from '@/components/divider'
// form components
import Form from '@/components/form/index'
import Input from '@/components/form/inputs/input'
import Select from '@/components/form/inputs/select.vue'
import SearchInput from '@/components/form/inputs/searchInput'
import Checkbox from '@/components/form/inputs/checkbox'

// helpers functions
import formHock from '@/helpers/formHock'

// form validations
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'

// Form item
const formSetup = {
  name: '',
  tax_number: '',
  balance_type: 0,
  account_id: 0,
  balance: 0,
  opening_debit: null,
  opening_credit: null,
  max_balance: null,
  address1: '',
  address2: '',
  city: '',
  zip_code: '',
  status: 1,
  state: '',
  priceList_id: 0,
  phone1: '',
  phone2: '',
  email: ''
}

// Validations
const rules = {
  name: {
    required: helpers.withMessage('Name is required', required),
    minLength: helpers.withMessage('Name must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Name must be smaller than or equal 121', maxLength(121))
  },
  address1: {
    minLength: helpers.withMessage('zip_code must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('zip_code must be smaller than or equal 121', maxLength(125))
  },
  address2: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  },
  city: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  },
  zip_code: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  },

  state: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  },
  phone1: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  },
  phone2: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  },
  email: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  }
}

// Params to show edit and add route
const params = {
  add: {
    routeName: 'customersCreate'
  },
  edit: {
    routeName: 'customersEdit'
  }
}

// Import from form hock
const { item, saveItem, loading, getInputError } = formHock({
  path: 'customers',
  localData: true,
  formSetup: formSetup,
  rules: rules
})
//updateDatabase
Vue.prototype.$updateDatabase(['customers', 'priceLists'])

const onSubmit = async () => {
  const response = await saveItem()
  if (response.status) {
    window.history.back()
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

// Watch balance
watch(
  () => item,
  () => {
    if (item.value.balance_type == 1) {
      item.value.opening_balance =
        (item.value.opening_debit ?? 0) - (item.value.opening_credit ?? 0)
    }
  },
  { deep: true }
)
</script>
