<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create company')"
          :description="$t('from Here You Can Create A New Company')"
          v-if="$route.name == 'companiesCreate'"
        />
        <addHeader
          :title="$t('Edit company')"
          :description="$t('from Here You Can Amend The Company Information')"
          v-if="$route.name == 'companiesEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('basic Company formation')"
            noLine="true"
          />
          <fileInput
            :title="$t('Company logo')"
            v-model="item.image"
            :image="item.image"
            :path="'logos'"
            :hasErorr="errors.image"
            :error="$t('Choose A Suitable File')"
            :col="'col-12'"
          />
          <formInput
            :title="$t('The companies name')"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('This field Is required')"
          />
          <formInput :title="$t('Tax Identification Number')" v-model="item.tax_number" />
          <formInput :title="$t('Commercial Register')" v-model="item.commercial_register" />

          <monyInput :title="$t('Opening Balance')" v-model="item.opining_balance" />
          <checkInput
            :title="$t('Electronic bill')"
            :value="item.e_invoicing"
            v-model="item.e_invoicing"
          />
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Front Reports') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.main_reports"
                :options="reports"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose The Required Reports In The Interface')"
                label="name"
                track-by="link"
                :hide-selected="true"
              />
            </div>
          </div>

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Exhibits In The Facade') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.main_elements"
                :options="elements"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose Displays In The Interface')"
                label="name"
                track-by="id"
                :hide-selected="true"
              />
            </div>
          </div>

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Main Actions') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.main_actions"
                :options="actions"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose Actions In The Interface')"
                label="name"
                track-by="link"
                :hide-selected="true"
              />
            </div>
          </div>

          <dvider
            :title="$t('Time and Currency')"
            :description="$t('Settings for time and Currency')"
          />

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Time Zone') }}</label>
            <div class="col-md-7">
              <select
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.time_zone }"
                v-model="item.time_zone"
                @change="errors.time_zone = false"
              >
                <option v-for="(timeZone, index) in timeZones" :key="index">
                  {{ timeZone }}
                </option>
              </select>
              <div class="invalid-feedback">
                {{ $t('This field Is required') }}
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('currency') }}</label>
            <div class="col-md-7">
              <select
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.currency }"
                v-model="item.currency"
                @change="errors.currency = false"
              >
                <option
                  v-for="(currency, index) in currencyList"
                  :value="currency.code"
                  :key="index"
                >
                  {{ currency.name }}
                </option>
              </select>

              <div class="invalid-feedback">
                {{ $t('This field Is required') }}
              </div>
            </div>
          </div>

          <checkInput
            :title="$t('Showing Time next to Date')"
            :value="item.show_time"
            v-model="item.show_time"
          />

          <checkInput
            :title="$t('Showing Currency By invoices')"
            :value="item.show_time"
            v-model="item.show_time"
          />
          <dvider
            :title="$t('account Settings')"
            :description="$t('from Here You Can Control The Account Settings')"
          />

          <checkInput
            :title="$t('Safe Negative Balance')"
            :value="item.allow_minus_safes"
            v-model="item.allow_minus_safes"
          />
          <checkInput
            :title="$t('Store Negative Balance')"
            :value="item.allow_minus_stores"
            v-model="item.allow_minus_stores"
          />

          <dvider
            :title="$t('Billing address')"
            :description="$t('The title Of billing and the invoice is recorded')"
          />

          <formInput :title="$t('Title')" v-model="item.address1" maxlength="255" />
          <formInput :title="$t('Supplement')" v-model="item.address2" maxlength="255" />
          <formInput :title="$t('The entity')" v-model="item.entity" maxlength="255" />
          <formInput :title="$t('City')" v-model="item.city" maxlength="255" />
          <formInput :title="$t('Postal code')" v-model="item.zip" maxlength="255" />
          <formInput :title="$t('The state')" v-model="item.country" maxlength="255" />

          <dvider
            :title="$t('Invoice Settings')"
            :description="$t('Adjust The Invoice To Suit Your Business')"
          />

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Print The Invoice') }}</label>
            <div class="col-md-7">
              <select
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.print_type }"
                v-model="item.print_type"
                @change="errors.print_type = false"
              >
                <option value="0">{{ $t('A4 Paper') }}</option>
                <option value="4">
                  {{ $t('A4 Electronic Invoice Paper') }}
                </option>
                <option value="1">{{ $t('Thermal Paper') }} (8cm)</option>
                <option value="3">{{ $t('Thermal Paper') }} (5cm)</option>
              </select>
              <div class="invalid-feedback">
                {{ $t('This field Is required') }}
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Painting Design') }}</label>
            <div class="col-md-7">
              <select
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.theme_type }"
                v-model="item.theme_type"
                @change="errors.theme_type = false"
              >
                <option value="0">
                  {{ $t('traditional') }}
                </option>
                <option value="1">{{ $t('Design1') }}</option>
                <option value="2">{{ $t('Design2') }}</option>
                <option value="3">{{ $t('Design3') }}</option>
                <option value="4">{{ $t('Design4') }}</option>
                <option value="5">{{ $t('Design5') }}</option>
              </select>
              <div class="invalid-feedback">
                {{ $t('This field Is required') }}
              </div>
            </div>
          </div>

          <checkInput
            :title="$t('Allow Negative Amounts')"
            :value="item.allow_minus_quantity"
            v-model="item.allow_minus_quantity"
          />
          <checkInput
            :title="$t('Previous Balance View')"
            :value="item.show_old_balance"
            v-model="item.show_old_balance"
          />
          <checkInput
            :title="$t('Sign offer')"
            :value="item.show_signature"
            v-model="item.show_signature"
          />
          <checkInput
            :title="$t('Discount offer')"
            :value="item.show_discount_table"
            v-model="item.show_discount_table"
          />
          <checkInput
            :title="$t('Tax offer')"
            :value="item.show_tax_table"
            v-model="item.show_tax_table"
          />

          <checkInput
            :title="$t('Count allow')"
            :value="item.count_allow"
            v-model="item.count_allow"
          />

          <checkInput
            :title="$t('Show Item Number')"
            :value="item.item_number"
            v-model="item.item_number"
          />

          <checkInput
            :title="$t('Print after operation')"
            :value="item.print_after"
            v-model="item.print_after"
          />

          <formTextarea :title="$t('Bill Footer')" v-model="item.invoice_footer" />
          <formTextarea :title="$t('Css adjustments to the bill')" v-model="item.invoice_css" />
          <template v-if="$site.quotations_allow">
            <dvider
              :title="$t('Offers settings')"
              :description="$t('From Here You Can Control The Display Settings')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.quotation_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.quotations_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.quotations_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.quotations_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.quotations_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.quotations_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.invoices_allow">
            <dvider
              :title="$t('Sales Invoice Settings')"
              :description="$t('From Here You Can Control The Settings Of Sales Invoices')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.invoice_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.invoices_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The Home Page') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.invoices_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.invoices_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.invoices_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.invoices_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print The Invoice')"
              v-model="item.invoices_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 Electronic Invoice Paper') },
                { value: 1, name: $t('thermalPaper') + ' (8cm)' },
                { value: 3, name: $t('thermalPaper') + ' (5cm)' },
                { value: 5, name: $t('Simple electronic invoice') }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.invoices_print_footer" />
          </template>
          <template v-if="$site.bills_allow">
            <dvider
              :title="$t('purchase Invoice Settings')"
              :description="$t('from Here You Can Control The Settings Of Purchase Invoices')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.bill_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.bills_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The Home Page') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.bills_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.bills_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.bills_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.bills_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter Data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print the invoice')"
              v-model="item.bills_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 ElectronicInvoicePaper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.bills_print_footer" />
          </template>
          <template v-if="$site.saleReturns_allow">
            <dvider
              :title="$t('Sales Returns Settings')"
              :description="$t('From Here You Can Control Sales Returns')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.Sale_return_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_returns_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_returns_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_returns_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_returns_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_returns_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print The Invoice')"
              v-model="item.sale_returns_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 ElectronicInvoicePaper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.sale_returns_print_footer" />
          </template>
          <template v-if="$site.purchaseReturns_allow">
            <dvider
              :title="$t('purchase Returns')"
              :description="$t('from Here You Can Control Purchase Returns')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.Purchase_return_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_returns_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_returns_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_returns_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_returns_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_returns_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print The Invoice')"
              v-model="item.purchase_returns_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 ElectronicInvoicePaper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.purchase_returns_print_footer" />
          </template>
          <!-- <template v-if="$site.orders_allow">
            <dvider
              :title="$t('Demand Bonds')"
              :description="$t('from Here You Can Control The OrdeR Bonds')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.order_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.orders_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.orders_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.orders_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.orders_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.orders_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print The Invoice')"
              v-model="item.orders_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 ElectronicInvoicePaper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.orders_print_footer" />
          </template>
          <template v-if="$site.purchaseOrders_allow">
            <dvider
              :title="$t('Purchase Orders')"
              :description="$t('From Here You Can Control Purchase Orders')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.purchase_order_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_orders_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_orders_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_orders_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_orders_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_orders_required_data"
                  :options="requiredData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Enter Data')"
                  label="name"
                  track-by="id"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print the invoice')"
              v-model="item.purchase_orders_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 ElectronicInvoicePaper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.purchase_orders_print_footer" />
          </template> -->
          <template v-if="$site.salesPayments_allow">
            <dvider
              :title="$t('Sales Payments')"
              :description="$t('from Here You Can Control The Settings Of The Sales Payments')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.sales_payment_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_payments_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The Home Page') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_payments_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_payments_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_payments_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.sales_payments_print_footer" />
          </template>
          <template v-if="$site.purchasePayments_allow">
            <dvider
              :title="$t('Purchase Payments')"
              :description="$t('From Here You Can Control The Settings Of The Purchase Payments')"
            />
            <connectionInput
              :title="$t('Invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.purchase_payment_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_payments_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_payments_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_payments_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_payments_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea
              :title="$t('Bill Footer')"
              v-model="item.purchase_payments_print_footer"
            />
          </template>
          <template v-if="$site.salesCheques_allow">
            <dvider
              :title="$t('Sales cheques')"
              :description="$t('From Here You Can Control The Settings Of Sales Cheques')"
            />
            <connectionInput
              :title="$t('Invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.sales_cheque_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_cheques_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The Home Page') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_cheques_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_cheque_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_cheque_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.sales_cheques_print_footer" />
          </template>
          <template v-if="$site.purchaseCheques_allow">
            <dvider
              :title="$t('Purchase Cheques')"
              :description="$t('From Here You Can Control The Settings Of Purchase Cheques')"
            />
            <connectionInput
              :title="$t('Invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.purchase_cheque_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_cheques_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On TheHomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_cheques_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_cheques_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_cheques_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.purchase_cheques_print_footer" />
          </template>

          <template v-if="$site.salesDiscounts_allow">
            <dvider
              :title="$t('Sales Discounts')"
              :description="$t('from Here You Can Control The Settings For SaleDiscounts')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.sale_discount_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_discounts_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The HomePage') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sales_discounts_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_discounts_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.sale_discounts_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.sale_discounts_print_footer" />
          </template>
          <template v-if="$site.purchaseDiscounts_allow">
            <dvider
              :title="$t('purchase Discounts')"
              :description="$t('from Here You Can Control The Settings For Purchase Discounts')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.purchase_discount_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required Customer Data') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_discounts_customer_data"
                  :options="customersData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required Customer Data')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Items Shown On The Home Page') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_discounts_index_data"
                  :options="indexData"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Main Items Shown')"
                  label="title"
                  track-by="show"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_discounts_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.purchase_discounts_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea
              :title="$t('Bill Footer')"
              v-model="item.purchase_discounts_print_footer"
            />
          </template>
          <template v-if="$site.expenses_allow">
            <dvider
              :title="$t('Expense')"
              :description="$t('from Here You Can Control The Settings Of Expenses')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.expense_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.expenses_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.expenses_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.expenses_print_footer" />
          </template>
          <template v-if="$site.safesDeposits_allow">
            <dvider
              :title="$t('Cabinet deposits')"
              :description="$t('from Here You Can Control The Deposit Processes')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.safes_deposit_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.safes_deposits_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.safes_deposits_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.safes_deposits_print_footer" />
          </template>
          <template v-if="$site.safesWithdrawals_allow">
            <dvider
              :title="$t('Cabinet Withdrawals')"
              :description="$t('From Here You Can Control Withdrawals')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.safes_withdrawal_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.safes_withdrawals_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.safes_withdrawals_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea
              :title="$t('Bill Footer')"
              v-model="item.safes_withdrawals_print_footer"
            />
          </template>
          <template v-if="$site.safesTransfers_allow">
            <dvider
              :title="$t('Cabinet transfers')"
              :description="$t('From Here You Can Control Transfers')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.safes_transfer_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.safes_transfers_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.safes_transfers_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <formTextarea :title="$t('Bill Footer')" v-model="item.safes_transfers_print_footer" />
          </template>
          <template v-if="$site.storesDeposits_allow">
            <dvider
              :title="$t('Warehouse deposits')"
              :description="$t('From Here You Can Control The Deposit Processes')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.stores_deposit_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.stores_deposits_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available Cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.stores_deposits_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available Cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print The Invoice')"
              v-model="item.stores_deposits_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 Electronic Invoice Paper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.stores_deposits_print_footer" />
          </template>
          <template v-if="$site.storesWithdrawals_allow">
            <dvider
              :title="$t('Warehouse Withdrawals')"
              :description="$t('from Here You Can Control Withdrawals')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.stores_withdrawal_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.stores_withdrawals_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.stores_withdrawals_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print The Invoice')"
              v-model="item.stores_withdrawals_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 Electronic Invoice Paper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea
              :title="$t('Bill Footer')"
              v-model="item.stores_withdrawals_print_footer"
            />
          </template>
          <template v-if="$site.storesTransfers_allow">
            <dvider
              :title="$t('Warehouse transfers')"
              :description="$t('From here you can control transfers')"
            />
            <connectionInput
              :title="$t('invoice Numbering')"
              :group="$t('Groups')"
              v-model="item.stores_transfer_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.stores_transfers_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.stores_transfers_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <selectInput
              :title="$t('Print the invoice')"
              v-model="item.stores_transfers_print_type"
              :values="[
                { value: 0, name: $t('A4 Paper') },
                { value: 4, name: $t('A4 Electronic Invoice Paper') },
                { value: 1, name: $t('thermal Paper') + ' (8cm)' },
                { value: 3, name: $t('thermal Paper') + ' (5cm)' }
              ]"
            />
            <formTextarea :title="$t('Bill Footer')" v-model="item.stores_transfers_print_footer" />
          </template>
          <template v-if="$site.partnersDeposits_allow">
            <dvider
              :title="$t('partner Deposit')"
              :description="$t('From Here You Can Control The Partner Deposits')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.partners_deposits_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_deposits_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_deposits_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.partnersWithdrawals_allow">
            <dvider
              :title="$t('Withdrawal Partners')"
              :description="$t('From Here You Can Control The Partner withdrawal')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.partners_withdrawals_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_withdrawals_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_withdrawals_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.partnersSettlements_allow">
            <dvider
              :title="$t('Partner Settlement')"
              :description="$t('From Here You Can Control The partner Settlement')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.partners_settlements_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_settlements_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_settlements_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <!-- <template v-if="$site.partnersProfits_allow">
            <dvider
              :title="$t('Dividends')"
              :description="$t('from Here You Can Control The dividends')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.partners_profit_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_profits_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.partners_profits_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template> -->

          <template v-if="$site.manufacturingModels_allow">
            <dvider
              :title="$t('Manufacturing Model Operations')"
              :description="
                $t(
                  'From Here You Can Control The Deposit Processes Of Manufacturing Model Operations'
                )
              "
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.manufacturing_model_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_model_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_model_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.manufacturingOperations_allow">
            <dvider
              :title="$t('list Operations')"
              :description="
                $t(
                  'From Here You Can Control The Deposit Processes Of Manufacturing List Operations'
                )
              "
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.manufacturing_operations_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_operations_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_operations_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.manufacturingOperations_allow">
            <dvider
              :title="$t('Deposit Raw Materials Manufacturing Processes')"
              :description="$t('From Here You Can Control The Deposit Processes Of Raw Materials')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.manufacturing_deposits_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_deposits_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_deposits_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.manufacturingOperations_allow">
            <dvider
              :title="$t('Product Recalls')"
              :description="$t('from Here You Can Control Product Withdrawal Settings')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.manufacturing_withdrawals_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_withdrawals_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.manufacturing_withdrawals_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>

          <template v-if="$site.employeesSalaries_allow">
            <dvider
              :title="$t('Salaries Employees')"
              :description="$t('from Here You Can Control Product Salaries Employees')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.employees_salaries_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_salaries_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_salaries_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.employeesIncentives_allow">
            <dvider
              :title="$t('Employee Incentive')"
              :description="$t('From Here You Can Control Product Employee Incentive')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.employees_incentives_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_incentives_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_incentives_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.employeesDeductions_allow">
            <dvider
              :title="$t('Employee Discount')"
              :description="$t('From Here You Can Control Product Employee Discount')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.employees_deductions_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_deductions_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_deductions_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.employeesWithdrawals_allow">
            <dvider
              :title="$t('Staff Withdrawal')"
              :description="$t('From Here You Can Control Product Staff Withdrawal')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.employees_withdrawals_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_withdrawals_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.employees_withdrawals_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.cities_allow">
            <dvider
              :title="$t('Shipping Operations')"
              :description="$t('from Here You Can Control Product Shipping Operations')"
            />
            <connectionInput
              :title="$t('Invoice numbering')"
              :group="$t('Groups')"
              v-model="item.shipping_operations_invoice_group_id"
              :values="$database.invoiceGroups"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.shipping_operations_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Available cases') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.shipping_operations_stats_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Available cases')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <template v-if="$site.reports_allow">
            <dvider
              :title="$t('Report Option')"
              :description="$t('from Here You Can Change Report Options')"
            />
            <div class="form-group col-md-6 row">
              <label class="col-md-4">{{ $t('Required signatures') }}</label>
              <div class="col-md-7">
                <multiselect
                  v-model="item.reports_signatures_data"
                  :options="filterdSignatures"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Required signatures')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
            </div>
          </template>
          <dvider
            :title="$t('Accounting Restrictions')"
            :description="$t('from Here You Can Change accounting restrictions Options')"
          />
          <connectionInput
            :title="$t('Invoice numbering')"
            :group="$t('Groups')"
            v-model="item.transaction_invoice_group_id"
            :values="$database.invoiceGroups"
          />
          <dvider
            :title="$t('Poster Settings')"
            :description="$t('Dimensions And Details Of The Poster')"
          />
          <checkInput
            :title="$t('View The Price With TheS ticker')"
            :value="item.barcode_price_show"
            v-model="item.barcode_price_show"
          />
          <checkInput
            :title="$t('View The Company Name With The Poster')"
            :value="item.barcode_company_show"
            v-model="item.barcode_company_show"
          />
          <formInput :title="$t('Poster Height')" v-model="item.barcode_height" type="number" />
          <formInput :title="$t('Poster Display')" v-model="item.barcode_width" type="number" />
          <formInput
            :title="$t('Main Margin')"
            v-model="item.barcode_height_padding"
            type="number"
          />
          <formInput
            :title="$t('Horizontal Margin')"
            v-model="item.barcode_width_padding"
            type="number"
          />
          <selectInput
            :title="$t('Label Type')"
            v-model="item.barcode_type"
            :values="[
              { name: $t('Normal'), value: 1 },
              { name: $t('double'), value: 2 }
            ]"
          />
          <dvider
            :title="$t('Store Settings')"
            :description="$t('From Here You Can Control The Settings Of The Stores')"
          />

          <formInput
            :title="$t('Minimum Quantity In Stock')"
            v-model="item.min_quantity"
            type="number"
          />

          <dvider
            :title="$t('Contact Information')"
            :description="$t('customer Contact Information')"
          />
          <iconInput
            :type="'number'"
            :title="$t('telephone Number')"
            :icon="'phone-office'"
            v-model="item.telephone"
          />
          <iconInput :type="'number'" :title="$t('Fax Number')" :icon="'fax'" v-model="item.fax" />
          <iconInput
            :type="'number'"
            :title="$t('Mobile number')"
            :icon="'phone'"
            v-model="item.mobile"
          />
          <iconInput :type="'email'" :title="$t('email')" :icon="'at'" v-model="item.email" />
          <iconInput
            :type="'link'"
            :title="$t('The Site Is On The Internet')"
            :icon="'browser'"
            v-model="item.website"
            :col="'col-md-12'"
          />
          <dvider
            :title="$t('social Networks')"
            :description="$t('companies Social Media Accounts')"
          />
          <iconInput
            :type="'text'"
            :title="$t('linkedIn Account')"
            :icon="'linkedin'"
            :iconTtype="'social'"
            v-model="item.linkedin"
          />
          <iconInput
            :type="'text'"
            :title="$t('facebook Account')"
            :icon="'facebook'"
            :iconTtype="'social'"
            v-model="item.facebook"
          />
          <iconInput
            :type="'text'"
            :title="$t('twitter Account')"
            :icon="'twitter'"
            :iconTtype="'social'"
            v-model="item.twitter"
          />
          <iconInput
            :type="'text'"
            :title="$t('google Plus Account')"
            :icon="'google-plus'"
            :iconTtype="'social'"
            v-model="item.google_plus"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'companiesCreate' && !$parent.stopEdit"
          >
            {{ $t('Create Company') }}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'companiesEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit Company') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import fileInput from '@/elements/add/form/fileInput.vue'
import formInput from '@/elements/add/form/formInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import iconInput from '@/elements/add/form/iconInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
export default {
  data() {
    return {
      path: '/companies',
      filterdSignatures: [],
      item: {
        print_type: 0,
        theme_type: 1,
        barcode_price_show: 1,
        barcode_company_show: 0,
        show_old_balance: 0,
        show_signature: 0,
        show_discount_table: 0,
        show_tax_table: 0,
        show_custom_logo: 0,
        show_currency: 0,
        min_quantity: 0,
        salary_expense_section_id: 0,
        item_number: 0,
        print_after: 1,
        main_reports: [],
        main_elements: [],
        main_actions: [],

        quotations_customer_data: [],
        quotations_index_data: [],
        invoices_customer_data: [],
        invoices_index_data: [],
        bills_customer_data: [],
        bills_index_data: [],
        sale_returns_customer_data: [],
        sale_returns_index_data: [],
        purchase_returns_customer_data: [],
        purchase_returns_index_data: [],
        orders_customer_data: [],
        orders_index_data: [],
        purchase_orders_customer_data: [],
        purchase_orders_index_data: [],
        sales_payments_customer_data: [],
        sales_payments_index_data: [],
        purchase_payments_customer_data: [],
        purchase_payments_index_data: [],
        sales_cheques_customer_data: [],
        sales_cheques_index_data: [],
        purchase_cheques_customer_data: [],
        purchase_cheques_index_data: [],
        sales_installments_customer_data: [],
        sales_installments_index_data: [],
        purchase_installments_customer_data: [],
        purchase_installments_index_data: [],
        sales_discounts_customer_data: [],
        sales_discounts_index_data: [],
        purchase_discounts_customer_data: [],
        purchase_discounts_index_data: [],
        signatures_data: [],
        time_zone: 'Africa/Cairo',
        currency: 'EGP'
      },
      customersData: [
        { title: this.$t('Customer name'), show: 'name' },
        { title: this.$t('Customer code'), show: 'tax_number' },
        {
          title: this.$t('Balance'),
          show: 'balance',
          moany: true
        },
        { title: this.$t('The Address'), show: 'address1' },
        { title: this.$t('complement'), show: 'address2' },
        { title: this.$t('The side'), show: 'entity' },
        { title: this.$t('City'), show: 'city' },
        { title: this.$t('Postal code'), show: 'zip' },
        { title: this.$t('country'), show: 'country' },
        { title: this.$t('Telephone Number'), show: 'mobile' },
        {
          title: this.$t('Another Phone Number'),
          show: 'mobile2'
        },
        { title: this.$t('EMail'), show: 'email' }
      ],
      indexData: [
        {
          column: 'code',
          title: this.$t('Sales Order'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'date',
          title: this.$t('Order Date'),
          type: 'text',
          sort: true
        },
        {
          column: 'client_type',
          title: this.$t('Customer name'),
          type: 'clintLink',
          sort: false
        },
        {
          column: 'total',
          title: this.$t('Cost'),
          type: 'text',
          sort: true
        }
      ],
      timeZones: [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville',
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay',
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
      ],
      currencyList: [
        { name: 'Afghan Afghani', code: 'AFA' },
        { name: 'Albanian Lek', code: 'ALL' },
        { name: 'Algerian Dinar', code: 'DZD' },
        { name: 'Angolan Kwanza', code: 'AOA' },
        { name: 'Argentine Peso', code: 'ARS' },
        { name: 'Armenian Dram', code: 'AMD' },
        { name: 'Aruban Florin', code: 'AWG' },
        { name: 'Australian Dollar', code: 'AUD' },
        { name: 'Azerbaijani Manat', code: 'AZN' },
        { name: 'Bahamian Dollar', code: 'BSD' },
        { name: 'Bahraini Dinar', code: 'BHD' },
        { name: 'Bangladeshi Taka', code: 'BDT' },
        { name: 'Barbadian Dollar', code: 'BBD' },
        { name: 'Belarusian Ruble', code: 'BYR' },
        { name: 'Belgian Franc', code: 'BEF' },
        { name: 'Belize Dollar', code: 'BZD' },
        { name: 'Bermudan Dollar', code: 'BMD' },
        { name: 'Bhutanese Ngultrum', code: 'BTN' },
        { name: 'Bitcoin', code: 'BTC' },
        { name: 'Bolivian Boliviano', code: 'BOB' },
        { name: 'Bosnia-Herzegovina Convertible Mark', code: 'BAM' },
        { name: 'Botswanan Pula', code: 'BWP' },
        { name: 'Brazilian Real', code: 'BRL' },
        { name: 'British Pound Sterling', code: 'GBP' },
        { name: 'Brunei Dollar', code: 'BND' },
        { name: 'Bulgarian Lev', code: 'BGN' },
        { name: 'Burundian Franc', code: 'BIF' },
        { name: 'Cambodian Riel', code: 'KHR' },
        { name: 'Canadian Dollar', code: 'CAD' },
        { name: 'Cape Verdean Escudo', code: 'CVE' },
        { name: 'Cayman Islands Dollar', code: 'KYD' },
        { name: 'CFA Franc BCEAO', code: 'XOF' },
        { name: 'CFA Franc BEAC', code: 'XAF' },
        { name: 'CFP Franc', code: 'XPF' },
        { name: 'Chilean Peso', code: 'CLP' },
        { name: 'Chinese Yuan', code: 'CNY' },
        { name: 'Colombian Peso', code: 'COP' },
        { name: 'Comorian Franc', code: 'KMF' },
        { name: 'Congolese Franc', code: 'CDF' },
        { name: 'Costa Rican ColÃ³n', code: 'CRC' },
        { name: 'Croatian Kuna', code: 'HRK' },
        { name: 'Cuban Convertible Peso', code: 'CUC' },
        { name: 'Czech Republic Koruna', code: 'CZK' },
        { name: 'Danish Krone', code: 'DKK' },
        { name: 'Djiboutian Franc', code: 'DJF' },
        { name: 'Dominican Peso', code: 'DOP' },
        { name: 'East Caribbean Dollar', code: 'XCD' },
        { name: 'Egyptian Pound', code: 'EGP' },
        { name: 'Eritrean Nakfa', code: 'ERN' },
        { name: 'Estonian Kroon', code: 'EEK' },
        { name: 'Ethiopian Birr', code: 'ETB' },
        { name: 'Euro', code: 'EUR' },
        { name: 'Falkland Islands Pound', code: 'FKP' },
        { name: 'Fijian Dollar', code: 'FJD' },
        { name: 'Gambian Dalasi', code: 'GMD' },
        { name: 'Georgian Lari', code: 'GEL' },
        { name: 'German Mark', code: 'DEM' },
        { name: 'Ghanaian Cedi', code: 'GHS' },
        { name: 'Gibraltar Pound', code: 'GIP' },
        { name: 'Greek Drachma', code: 'GRD' },
        { name: 'Guatemalan Quetzal', code: 'GTQ' },
        { name: 'Guinean Franc', code: 'GNF' },
        { name: 'Guyanaese Dollar', code: 'GYD' },
        { name: 'Haitian Gourde', code: 'HTG' },
        { name: 'Honduran Lempira', code: 'HNL' },
        { name: 'Hong Kong Dollar', code: 'HKD' },
        { name: 'Hungarian Forint', code: 'HUF' },
        { name: 'Icelandic KrÃ³na', code: 'ISK' },
        { name: 'Indian Rupee', code: 'INR' },
        { name: 'Indonesian Rupiah', code: 'IDR' },
        { name: 'Iranian Rial', code: 'IRR' },
        { name: 'Iraqi Dinar', code: 'IQD' },
        { name: 'Israeli New Sheqel', code: 'ILS' },
        { name: 'Italian Lira', code: 'ITL' },
        { name: 'Jamaican Dollar', code: 'JMD' },
        { name: 'Japanese Yen', code: 'JPY' },
        { name: 'Jordanian Dinar', code: 'JOD' },
        { name: 'Kazakhstani Tenge', code: 'KZT' },
        { name: 'Kenyan Shilling', code: 'KES' },
        { name: 'Kuwaiti Dinar', code: 'KWD' },
        { name: 'Kyrgystani Som', code: 'KGS' },
        { name: 'Laotian Kip', code: 'LAK' },
        { name: 'Latvian Lats', code: 'LVL' },
        { name: 'Lebanese Pound', code: 'LBP' },
        { name: 'Lesotho Loti', code: 'LSL' },
        { name: 'Liberian Dollar', code: 'LRD' },
        { name: 'Libyan Dinar', code: 'LYD' },
        { name: 'Lithuanian Litas', code: 'LTL' },
        { name: 'Macanese Pataca', code: 'MOP' },
        { name: 'Macedonian Denar', code: 'MKD' },
        { name: 'Malagasy Ariary', code: 'MGA' },
        { name: 'Malawian Kwacha', code: 'MWK' },
        { name: 'Malaysian Ringgit', code: 'MYR' },
        { name: 'Maldivian Rufiyaa', code: 'MVR' },
        { name: 'Mauritanian Ouguiya', code: 'MRO' },
        { name: 'Mauritian Rupee', code: 'MUR' },
        { name: 'Mexican Peso', code: 'MXN' },
        { name: 'Moldovan Leu', code: 'MDL' },
        { name: 'Mongolian Tugrik', code: 'MNT' },
        { name: 'Moroccan Dirham', code: 'MAD' },
        { name: 'Mozambican Metical', code: 'MZM' },
        { name: 'Myanmar Kyat', code: 'MMK' },
        { name: 'Namibian Dollar', code: 'NAD' },
        { name: 'Nepalese Rupee', code: 'NPR' },
        { name: 'Netherlands Antillean Guilder', code: 'ANG' },
        { name: 'New Taiwan Dollar', code: 'TWD' },
        { name: 'New Zealand Dollar', code: 'NZD' },
        { name: 'Nicaraguan CÃ³rdoba', code: 'NIO' },
        { name: 'Nigerian Naira', code: 'NGN' },
        { name: 'North Korean Won', code: 'KPW' },
        { name: 'Norwegian Krone', code: 'NOK' },
        { name: 'Omani Rial', code: 'OMR' },
        { name: 'Pakistani Rupee', code: 'PKR' },
        { name: 'Panamanian Balboa', code: 'PAB' },
        { name: 'Papua New Guinean Kina', code: 'PGK' },
        { name: 'Paraguayan Guarani', code: 'PYG' },
        { name: 'Peruvian Nuevo Sol', code: 'PEN' },
        { name: 'Philippine Peso', code: 'PHP' },
        { name: 'Polish Zloty', code: 'PLN' },
        { name: 'Qatari Rial', code: 'QAR' },
        { name: 'Romanian Leu', code: 'RON' },
        { name: 'Russian Ruble', code: 'RUB' },
        { name: 'Rwandan Franc', code: 'RWF' },
        { name: 'Salvadoran ColÃ³n', code: 'SVC' },
        { name: 'Samoan Tala', code: 'WST' },
        { name: 'Saudi Riyal', code: 'SAR' },
        { name: 'Serbian Dinar', code: 'RSD' },
        { name: 'Seychellois Rupee', code: 'SCR' },
        { name: 'Sierra Leonean Leone', code: 'SLL' },
        { name: 'Singapore Dollar', code: 'SGD' },
        { name: 'Slovak Koruna', code: 'SKK' },
        { name: 'Solomon Islands Dollar', code: 'SBD' },
        { name: 'Somali Shilling', code: 'SOS' },
        { name: 'South African Rand', code: 'ZAR' },
        { name: 'South Korean Won', code: 'KRW' },
        { name: 'Special Drawing Rights', code: 'XDR' },
        { name: 'Sri Lankan Rupee', code: 'LKR' },
        { name: 'St. Helena Pound', code: 'SHP' },
        { name: 'Sudanese Pound', code: 'SDG' },
        { name: 'Surinamese Dollar', code: 'SRD' },
        { name: 'Swazi Lilangeni', code: 'SZL' },
        { name: 'Swedish Krona', code: 'SEK' },
        { name: 'Swiss Franc', code: 'CHF' },
        { name: 'Syrian Pound', code: 'SYP' },
        { name: 'São Tomé and Príncipe Dobra', code: 'STD' },
        { name: 'Tajikistani Somoni', code: 'TJS' },
        { name: 'Tanzanian Shilling', code: 'TZS' },
        { name: 'Thai Baht', code: 'THB' },
        { name: "Tongan Pa'anga", code: 'TOP' },
        { name: 'Trinidad & Tobago Dollar', code: 'TTD' },
        { name: 'Tunisian Dinar', code: 'TND' },
        { name: 'Turkish Lira', code: 'TRY' },
        { name: 'Turkmenistani Manat', code: 'TMT' },
        { name: 'Ugandan Shilling', code: 'UGX' },
        { name: 'Ukrainian Hryvnia', code: 'UAH' },
        { name: 'United Arab Emirates Dirham', code: 'AED' },
        { name: 'Uruguayan Peso', code: 'UYU' },
        { name: 'US Dollar', code: 'USD' },
        { name: 'Uzbekistan Som', code: 'UZS' },
        { name: 'Vanuatu Vatu', code: 'VUV' },
        { name: 'Venezuelan BolÃvar', code: 'VEF' },
        { name: 'Vietnamese Dong', code: 'VND' },
        { name: 'Yemeni Rial', code: 'YER' },
        { name: 'Zambian Kwacha', code: 'ZMK' }
      ],

      uploading: 0,
      errors: {
        name: false,
        currency: false
      },
      reports: [
        { name: this.$t('Overview'), link: 'overview' },
        { name: this.$t('Total Report'), link: 'totalReport' },

        {
          name: this.$t('Total Customer Account'),
          link: 'customersTotalAccounts'
        },
        {
          name: this.$t('Customer Account Details'),
          link: 'customersDetailsAccounts'
        },
        {
          name: this.$t('Remaining Balance For Customers'),
          link: 'customersBalance'
        },
        { name: this.$t('Customer Guide'), link: 'customersGuide' },

        {
          name: this.$t('Total Supplier Account'),
          link: 'suppliersTotalAccounts'
        },
        {
          name: this.$t('Supplier Account Details'),
          link: 'suppliersDetailsAccounts'
        },
        {
          name: this.$t('Remaining Balance For Suppliers'),
          link: 'suppliersBalance'
        },
        {
          name: this.$t('Supplier Directory'),
          link: 'suppliersGuide'
        },

        {
          name: this.$t('Partner Is Total Account'),
          link: 'partnersTotalAccounts'
        },
        {
          name: this.$t('Partner Account Details'),
          link: 'partnersDetailsAccounts'
        },
        {
          name: this.$t('The Remaining Balance Of The Partners'),
          link: 'partnersBalance'
        },
        {
          name: this.$t('AGuideForPartners'),
          link: 'partnersGuide'
        },

        {
          name: this.$t('totalProjectAccount'),
          link: 'projectsTotalAccounts'
        },
        {
          name: this.$t('Project AccountDetails'),
          link: 'projectsDetailsAccounts'
        },
        { name: this.$t('Project Assets'), link: 'projectsBalance' },
        {
          name: this.$t('A General Glimpse Of The Project'),
          link: 'partnersGuide'
        },

        {
          name: this.$t('Treasury statement'),
          link: 'safesAccounts'
        },
        { name: this.$t('SafeBalances'), link: 'safersBalance' },

        {
          name: this.$t('Inventory Of Stores'),
          link: 'warehouseInventory'
        },
        {
          name: this.$t('The Value Of Products'),
          link: 'ProductsValue'
        },

        { name: this.$t('Sales By Invoice'), link: 'salesByInvoice' },
        { name: this.$t('Sales By Product'), link: 'salesByItem' },

        {
          name: this.$t('Purchases By Invoice'),
          link: 'purchasesByBill'
        },
        {
          name: this.$t('Purchases By Product'),
          link: 'purchasesByItem'
        },

        {
          name: this.$t('Returned By Invoice'),
          link: 'salesReturnsByInvoice'
        },
        {
          name: this.$t('Returned By Product'),
          link: 'salesReturnsByItem'
        },

        {
          name: this.$t('Purchase Returns On Invoice'),
          link: 'purchasesReturnsByBill'
        },
        {
          name: this.$t('Returns Purchase By Product'),
          link: 'purchasesReturnsByItem'
        },

        {
          name: this.$t('Invoice Requests'),
          link: 'purchasesReturnsByBill'
        },
        {
          name: this.$t('Orders By Product'),
          link: 'purchasesReturnsByItem'
        },

        {
          name: this.$t('Invoice Purchase Orders'),
          link: 'purchasesOrdersByBill'
        },
        {
          name: this.$t('Purchase Orders By Product'),
          link: 'purchasesOrdersByItem'
        },

        {
          name: this.$t('Record Sales Payments'),
          link: 'paymentHistory'
        },
        {
          name: this.$t('Record Purchase Payments'),
          link: 'purchasePaymentHistory'
        },

        {
          name: this.$t('Register Sales Cheques'),
          link: 'chequesHistory'
        },
        {
          name: this.$t('Check In Record'),
          link: 'purchaseChequesHistory'
        },

        {
          name: this.$t('Record Sales Discounts'),
          link: 'discountsHistory'
        },
        {
          name: this.$t('Record Purchase Discounts'),
          link: 'purchaseDiscountsHistory'
        },

        {
          name: this.$t('Invoicing Projects'),
          link: 'projectsDepositsByInvoice'
        },
        {
          name: this.$t('Product Deposit Processes'),
          link: 'projectsDepositsByItem'
        },

        {
          name: this.$t('Invoice Project Withdrawals'),
          link: 'projectsWithdrawalsByBill'
        },
        {
          name: this.$t('Product Recalls'),
          link: 'projectsWithdrawalsByItem'
        },

        {
          name: this.$t('Invoice Project Transfers'),
          link: 'projectsTransfersByInvoice'
        },
        {
          name: this.$t('Project Transfers ByProduct'),
          link: 'projectsTransfersByItem'
        },

        { name: this.$t('Expenses List'), link: 'expenseHistory' },
        {
          name: this.$t('Expenses By Classification'),
          link: 'expenseByCategory'
        },

        {
          name: this.$t('Employee Account Statement'),
          link: 'employeeAccountStatement'
        }
      ],
      requiredData: [
        { id: '0', name: this.$t('report Invoice') },
        { id: '1', name: this.$t('Reference') },
        { id: '2', name: this.$t('due Data') },
        { id: '3', name: this.$t('price List') },
        { id: '4', name: this.$t('tax And DiscountOnTheProduct') },
        { id: '5', name: this.$t('tax And DiscountTotal') },
        { id: '6', name: this.$t('note To The Client') },
        { id: '7', name: this.$t('offer Terms') }
      ],
      elements: [
        { id: '1', name: this.$t('Billing Vs Payment') },
        { id: '2', name: this.$t('Profit And Loss') },
        { id: '3', name: this.$t('Store Alerts') },
        { id: '4', name: this.$t('An Overview Of The Offers') },
        { id: '5', name: this.$t('Recent Offers') },
        { id: '6', name: this.$t('Unpaid Bills') },
        { id: '7', name: this.$t('Unpaid Bills') },

        { id: '8', name: this.$t('Urgent Actions') },
        { id: '9', name: this.$t('Highest Expenses By Category') },
        { id: '10', name: this.$t('Most Active Customers') },
        { id: '11', name: this.$t('Billing Overview') },
        { id: '12', name: this.$t('Recent Bills') },
        {
          id: '13',
          name: this.$t('An Overview Of The Demand Bonds')
        },
        { id: '14', name: this.$t('Customer Balance Alert') }
      ],
      actions: [
        {
          name: this.$t('Add customer'),
          link: '/customers/create',
          icon: 'user-alt'
        },
        {
          name: this.$t('Add Supplier'),
          link: '/suppliers/create',
          icon: 'user-tie'
        },
        {
          name: this.$t('Add Partner'),
          link: '/partners/create',
          icon: 'handshake'
        },
        {
          name: this.$t('Add Account'),
          link: '/accounts/create',
          icon: 'coins'
        },
        {
          name: this.$t('Add Project'),
          link: '/projects/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('Add Safe'),
          link: '/safes/create',
          icon: 'treasure-chest'
        },
        {
          name: this.$t('Add Banck Account'),
          link: '/bankAccounts/create',
          icon: 'university'
        },
        {
          name: this.$t('Add Capital'),
          link: '/capitals/create',
          icon: 'archway'
        },
        {
          name: this.$t('Add Store'),
          link: '/stores/create',
          icon: 'store'
        },
        {
          name: this.$t('Add Product'),
          link: '/products/create',
          icon: 'store-alt'
        },
        {
          name: this.$t('Add Matrial'),
          link: '/matrials/create',
          icon: 'store-alt'
        },
        {
          name: this.$t('Add Subscription'),
          link: '/subscriptions/create',
          icon: 'history'
        },
        {
          name: this.$t('Add Employee'),
          link: '/employees/create',
          icon: 'user-cog'
        },
        {
          name: this.$t('Add Quotation'),
          link: '/quotations/create',
          icon: 'user-cog'
        },
        {
          name: this.$t('add Subscriptions Operation'),
          link: '/subscriptionsOperations/create',
          icon: 'stopwatch'
        },
        {
          name: this.$t('Add Invoice'),
          link: '/invoices/create',
          icon: 'file-invoice-dollar'
        },
        {
          name: this.$t('Add Bill'),
          link: '/bills/create',
          icon: 'file-invoice-dollar'
        },
        {
          name: this.$t('add Sale Return'),
          link: '/saleReturns/create',
          icon: 'exchange'
        },
        {
          name: this.$t('Add purchase Return'),
          link: '/purchaseReturns/create',
          icon: 'exchange'
        },
        {
          name: this.$t('Add order'),
          link: '/orders/create',
          icon: 'briefcase'
        },
        {
          name: this.$t('Add purchase Order'),
          link: '/purchaseOrders/create',
          icon: 'briefcase'
        },
        {
          name: this.$t('Add Sales Payment'),
          link: '/salesPayments/create',
          icon: 'credit-card-front'
        },
        {
          name: this.$t('Add Purchase Payment'),
          link: '/purchasePayments/create',
          icon: 'credit-card-front'
        },
        {
          name: this.$t('Add Sales Cheque'),
          link: '/salesCheques/create',
          icon: 'money-check-alt'
        },
        {
          name: this.$t('Add Purchase Cheque'),
          link: '/purchaseCheques/create',
          icon: 'money-check-alt'
        },
        {
          name: this.$t('Add Sales Discount'),
          link: '/salesDiscounts/create',
          icon: 'badge-percent'
        },
        {
          name: this.$t('Add Purchase Discount'),
          link: '/purchaseDiscounts/create',
          icon: 'badge-percent'
        },
        {
          name: this.$t('Add Sales Installment'),
          link: '/salesInstallments/create',
          icon: 'wallet'
        },
        {
          name: this.$t('Add Purchase Installment'),
          link: '/purchaseInstallments/create',
          icon: 'wallet'
        },
        {
          name: this.$t('Add Expense'),
          link: '/expenses/create',
          icon: 'usd-square'
        },
        {
          name: this.$t('Add Safes Deposit'),
          link: '/safesDeposits/create',
          icon: 'envelope-open-dollar'
        },
        {
          name: this.$t('Add Safes Withdrawal'),
          link: '/safesWithdrawals/create',
          icon: 'envelope-open-dollar'
        },
        {
          name: this.$t('Add Safes Transfer'),
          link: '/safesTransfers/create',
          icon: 'envelope-open-dollar'
        },
        {
          name: this.$t('Add Stores deposit'),
          link: '/storesDeposits/create',
          icon: 'boxes-alt'
        },
        {
          name: this.$t('Add Stores Withdrawal'),
          link: '/storesWithdrawals/create',
          icon: 'boxes-alt'
        },
        {
          name: this.$t('add StoresTransfer'),
          link: '/storesTransfers/create',
          icon: 'boxes-alt'
        },
        {
          name: this.$t('Add partners deposit'),
          link: '/partnersDeposits/create',
          icon: 'handshake'
        },
        {
          name: this.$t('Add partners withdrawal'),
          link: '/partnersWithdrawals/create',
          icon: 'handshake'
        },
        {
          name: this.$t('Add partners settlement'),
          link: '/partnersSettlements/create',
          icon: 'handshake'
        },
        {
          name: this.$t('Add partners profit'),
          link: '/partnersProfits/create',
          icon: 'handshake'
        },
        {
          name: this.$t('Add accounts deposit'),
          link: '/accountsDeposits/create',
          icon: 'hand-holding-usd'
        },
        {
          name: this.$t('Add Accounts withdrawal'),
          link: '/accountsWithdrawals/create',
          icon: 'hand-holding-usd'
        },
        {
          name: this.$t('Add projects deposit'),
          link: '/projectsDeposits/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('Add projects withdrawal'),
          link: '/projectsWithdrawals/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('Add projects transfer'),
          link: '/projectsTransfers/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('Add manufacturing model'),
          link: '/manufacturingModels/create',
          icon: 'hammer'
        },
        {
          name: this.$t('Add manufacturing operation'),
          link: '/manufacturingOperations/create',
          icon: 'hammer'
        },
        {
          name: this.$t('sow Point Of Sales'),
          link: '/pointOfSales',
          icon: 'cash-register'
        },
        {
          name: this.$t('Add Employees Salarie'),
          link: '/employeesSalaries/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('Add Employees Incentive'),
          link: '/employeesIncentives/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('Add Employees Deduction'),
          link: '/employeesDeductions/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('Add Employees Withdrawal'),
          link: '/employeesWithdrawals/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('Add Endorsement'),
          link: '/endorsements/create',
          icon: 'file-signature'
        },
        {
          name: this.$t('Add deposit'),
          link: '/deposits/create',
          icon: 'wallet'
        },
        {
          name: this.$t('Add city'),
          link: '/cities/create',
          icon: 'shipping-fast'
        },
        {
          name: this.$t('Add Pick up Location'),
          link: '/pickupLocations/create',
          icon: 'shipping-fast'
        },
        {
          name: this.$t('Add Shipping Operation'),
          link: '/shippingOperations/create',
          icon: 'shipping-fast'
        }
      ]
    }
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.$parent.stopEdit = false
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (typeof this.item.currency === 'undefined' || this.item.currency == null) {
        error = 1
        this.errors.currency = true
      }
      if (error == 0) {
        if (this.uploading == 0) {
          if (this.$route.name == 'companiesCreate') {
            this.$parent.stopEdit = true
            axios.post(this.$linkGenerator(this.path), this.item).then((response) => {
              if (response.data == 'limited') {
                this.$toast.error(
                  this.$t('You have reached the limit for companies please update package')
                )
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              } else {
                this.$toast.success(this.$t('the Company Has Been Added Successfully'))
                this.$router.push(this.path)
                this.$parent.stopEdit = false
              }
              return response
            })
          } else if (this.$route.name == 'companiesEdit') {
            this.item._method = 'PUT'
            this.$parent.stopEdit = true
            axios
              .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
              .then((response) => {
                this.$toast.success(this.$t('the Company Has Been Successfully Modified'))
                this.$router.go(-1)
                this.$parent.stopEdit = false
                this.$getCompanies()
                return response
              })
          }
        } else {
          this.$toast.error(this.$t('Wait For The Files To Upload'))
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        this.$getCompanies()
      } else {
        this.$toast.success(this.$t('the Company Has Been Added Successfully'))
        this.$router.push(this.path)
        this.$parent.stopEdit = false
      }
    }
  },
  mounted() {
    this.$updateDatabase(['expenseSections'])
    if (this.$route.name == 'companiesEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }

    this.filterdSignatures = []

    this.$database.signatures.forEach((element) => {
      this.filterdSignatures.push({
        name: element.name
      })
    })
  },
  components: {
    formInput,
    selectInput,
    formTextarea,
    fileInput,
    dvider,
    addHeader,
    iconInput,
    monyInput,
    loading,
    checkInput,
    connectionInput
  }
}
</script>
