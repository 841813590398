<template>
  <div v-if="!loading">
    <div class="row invoice-show hide-w-8">
      <div class="col-12 text-center">
        <img
          :src="logo"
          class="img-fluid mb-4"
          style="max-width: 4.5rem"
          v-if="$options.image != null"
        />
        <h2 class="mb-2">
          {{ $option?.name }}
          <br />
          شجرة الحسابات
        </h2>
        <qrcode-vue
          :value="item.qrCode"
          size="150"
          level="H"
          render-as="svg"
          v-if="$option?.tax_invoice"
        />
      </div>
    </div>
    <div class="row invoice-show hide-w-8">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table my-w">
            <thead>
              <tr>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Account Number') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Account Name') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Role') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Balance') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(qItem, index) in item" :key="index">
                <td class="px-0">
                  {{ qItem.code }}
                </td>
                <td class="px-0">
                  {{ qItem.name }}
                </td>
                <td class="px-0">
                  {{ qItem.is_main ? $t('Main account') : $t('Sub Account') }}
                </td>
                <td class="px-0">
                  {{ $moneyFormat(qItem.balance) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const logo = localStorage.getItem('logo')
const loading = ref(null)

const props = defineProps(['item'])
</script>
