<template>
  <!-- ............text input with search............... -->
  <div class="form-group has-search search-sidebar-style">
    <i class="fa fa-search form-control-feedback"></i>
    <input
      type="text"
      v-model="searchValue"
      class="form-control form-control-sm"
      placeholder="بحث"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue'
// import { searchLinks } from '@/helpers/searchLinks'
// const showSearchStat = ref(false)
const searchValue = ref('')
const searchLink = () => {
  showSearchStat.value = true
  console.log(searchValue.value)
  if (searchValue.value != '') {
    var editsearch = new RegExp(searchValue.value, 'i')
    var items = searchLinks
      .sort(Vue.prototype.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
      .filter(
        (el) =>
          editsearch.test(el.name) &&
          Vue.prototype.$site[el.mainRole] &&
          (Vue.prototype.$user.admin ||
            Vue.prototype.$user.role[el.role] ||
            Vue.prototype.$user.role.available_reports.find((report) => report.id == el.reportId))
      )
      .slice(0, 10)
    selectedLinks = items
  } else {
    selectedLinks = []
  }
}
// const showSearchState = ref(false)
// const removeSearch = () => {
//   showSearchStat.value = false
// }
// const searchLink = () => {
//   showSearchStat.value = true
//   console.log(searchValue.value)
//   if (searchValue.value != '') {
//     var editsearch = new RegExp(searchValue.value, 'i')
//     var items = searchLinks
//       .sort(Vue.prototype.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
//       .filter(
//         (el) =>
//           editsearch.test(el.name) &&
//           Vue.prototype.$site[el.mainRole] &&
//           (Vue.prototype.$user.admin ||
//             Vue.prototype.$user.role[el.role] ||
//             Vue.prototype.$user.role.available_reports.find((report) => report.id == el.reportId))
//       )
//       .slice(0, 10)
//     selectedLinks = items
//   } else {
//     selectedLinks = []
//   }
// }
// const removeSearch = () => {
//   showSearchStat.value = false
// }
</script>
