<template>
  <div class="row" :style="style">
    <!-- Invoices link -->
    <router-link
      v-if="options.invoice_allow"
      :to="'/pointOfSales/' + options.id + '/invoices'"
      class="btn pos-main-btn"
    >
      <i class="fal fa-file-invoice-dollar"></i>
      {{ $t('Invoices') }}
    </router-link>
    <!-- Expenses link-->
    <router-link
      :to="'/pointOfSales/' + options.id + '/expenses'"
      class="btn pos-main-btn"
      v-if="options.expense_allow"
    >
      <i class="fal fa-dollar-sign"></i>
      {{ $t('Expenses') }}
    </router-link>
    <!-- Payments link -->
    <!-- <button class="btn pos-main-btn" @click="$emit('showPayment')" v-if="options.payment_allow">
      <i class="fal fa-credit-card-front"></i>
      {{ $t('Payments') }}
    </button> -->
    <!-- Cash drawer link -->
    <button
      class="btn pos-main-btn"
      @click="$emit('openCashDraw')"
      v-if="options.open_cash_drawer_allow"
    >
      <i class="fal fa-cash-register"></i>
      {{ $t('Cash drawer') }}
    </button>
    <!-- Back link -->
    <router-link :to="'/pointOfSales'" class="btn pos-main-btn">
      <i class="fal fa-arrow-right"></i>
      {{ $t('Back') }}
    </router-link>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const style = ref(
  window.innerWidth > 770 ? 'top: 50px;height: calc(100% - 50px);' : 'top: 0px;height: auto;'
)
window.addEventListener('scroll', function (e) {
  if (window.innerWidth > 770) {
    if (this.scrollY <= 50) {
      style.value =
        'top: ' + (50 - this.scrollY) + 'px;height: calc(100% - ' + (50 - this.scrollY) + 'px);'
    } else {
      style.value = 'top: 0px;height: 100%);'
    }
  } else {
    style.value = 'top: 0px;height: auto;'
  }
})
window.addEventListener('resize', function (e) {
  if (window.innerWidth > 770) {
    if (this.scrollY <= 50) {
      style.value =
        'top: ' + (50 - this.scrollY) + 'px;height: calc(100% - ' + (50 - this.scrollY) + 'px);'
    } else {
      style.value = 'top: 0px;height: 100%);'
    }
  } else {
    style.value = 'top: 0px;height: auto;'
  }
})

defineProps(['options'])
</script>
