<template>
  <div
    class="pcoded iscollapsed"
    nav-type="st6"
    theme-layout="vertical"
    vertical-placement="left"
    vertical-layout="wide"
    pcoded-device-type="desktop"
    v-bind:vertical-nav-type="nav"
    vertical-effect="shrink"
    vnavigation-view="view1"
    fream-type="theme1"
    sidebar-img="false"
    sidebar-img-type="img1"
    layout-type="light"
  >
    <div class="pcoded-overlay-box"></div>
    <router-view />
  </div>
</template>
<script setup>
import Vue, { ref, onMounted, getCurrentInstance, onBeforeUnmount } from 'vue'

// Route config
const { proxy } = getCurrentInstance()
const router = proxy.$router

// Navbar logic
const nav = ref('expanded')
const toggleSide = () => (nav.value = nav.value == 'offcanvas' ? 'expanded' : 'offcanvas')

// Shortcuts logic
let isControl = ref(false)
const handleKeyDown = (event) => {
  if (event.key == 'Control') {
    isControl.value = true
    return
  } else if (event.key === 'Backspace' && !['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
    event.preventDefault() // Prevent default behavior of backspace
    window.history.back() // Go back to the previous page
    return
  } else {
    const button = event.key
  }
}

const handelKeyUp = (event) => {
  if (event.key == 'Control') {
    isControl.value = false
    return
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown)
  window.addEventListener('keyup', handelKeyUp)
})
onBeforeUnmount(() => {
  // Clean up the event listener when the component is unmounted
  window.removeEventListener('keydown', handleKeyDown)
})
</script>
