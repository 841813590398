<template>
  <div :class="!showSidebar ? 'remove-sidebar' : ''">
    <sidebar :showMobileMenu="showMobileMenu" @openModel="openModel" @closeModel="closeModel" />
    <div class="main-content">
      <navbar :padding="showSidebar" :showSidebar="showSidebar" />
      <transition name="slide" mode="out-in" v-if="!loading">
        <router-view @closeSidebar="closeSidebar"></router-view>
      </transition>
    </div>
    <div class="modal-backdrop fade show" v-if="model == 1"></div>
  </div>
</template>
<script setup>
import navbar from '@/includes/navbar.vue'
import sidebar from '@/includes/sidebar.vue'
import { i18n } from '@/helpers/i18n'
import Vue, { ref, onMounted, watch, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const router = proxy.$router

const model = ref(0)
const loading = ref(true)
const showSidebar = ref(true)
const showMobileMenu = ref(false)

const openModel = () => {
  model.value = 1
}
const closeModel = () => {
  model.value = 0
}
onMounted(() => {
  var token = localStorage.getItem('token')
  if (!token) {
    router.push('/login')
  } else {
    loading.value = false
    Vue.prototype.$intUser()
    Vue.prototype.$initCompany()
    Vue.prototype.$getUser()
    Vue.prototype.$getCompanies()
    Vue.prototype.$localStore()
    Vue.prototype.$updateDatabase()
    if (Vue.prototype.$user.language == 'en') {
      document.getElementById('stylesheetRtl').disabled = true
      i18n.locale = 'en'
    } else {
      document.getElementById('stylesheetRtl').disabled = false
      i18n.locale = 'ar'
    }
  }
})

const closeSidebar = () => {
  showSidebar.value = false
}
const internalInstance = getCurrentInstance()

// Watch for route changes
watch(
  () => internalInstance.proxy.$route.fullPath,
  () => {
    showSidebar.value = true
  }
)
</script>
<style>
.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 0.4s,
    transform 0.4s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: scale(0.97);
}
</style>
