var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Supplier account details')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Supplier'),"plaseholder":_vm.$t('Choose supplier'),"show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('div',{staticClass:"col-md-3"},[_c('label',{staticClass:"col-md-12"},[_vm._v(_vm._s(_vm.$t('Branches')))]),_c('multiselect',{attrs:{"options":_vm.$database.branches,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.$t('Choose the branch'),"label":"name","track-by":"name","hide-selected":true},model:{value:(_vm.branchArray),callback:function ($$v) {_vm.branchArray=$$v},expression:"branchArray"}})],1),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Total supplier account'),"details":[{ title: 'اسم المورد', value: _vm.supplierName }]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 8, value: 'الرصيد الأفتتاحي' },
            {
              colspan: 1,
              value: _vm.startBalance
            }
          ],"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            { column: 'code', title: _vm.$t('Code'), type: 'reportCode' },

            { column: 'date', title: _vm.$t('Date'), type: 'text' },
            { column: 'type', title: _vm.$t('Type'), type: 'reportType' },
            { column: 'statement', title: _vm.$t('Statement'), type: 'statement' },
            { column: 'quantity', title: _vm.$t('Quantity'), type: 'textOrEmpty' },
            {
              column: 'price',
              title: _vm.$t('Price'),
              type: 'textOrEmpty'
            },
            {
              column: 'debit',
              title: _vm.$t('Debtor'),
              type: 'textOrEmpty'
            },
            {
              column: 'creditor',
              title: _vm.$t('Creditor'),
              type: 'textOrEmpty'
            },
            {
              column: 'new_balance',
              type: 'text',
              title: _vm.$t('Balance')
            }
          ],"footer":[
            { colspan: 6, value: 'الرصيد النهائي' },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            {
              colspan: 1,
              value: _vm.endBalance
            }
          ],"tafqeet":_vm.tafqeet}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }