<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Settings')"
          :description="$t('From here you can control all settings')"
        />
        <div class="row">
          <div class="col-md-6">
            <reportsCard
              :cardTitle="$t('User settings')"
              :icon="'fal fa-user-cog'"
              :reports="[
                {
                  name: $t('profile'),
                  link: './profile',
                  role: $checkRole('profile_edit')
                },
                {
                  name: $t('Companies'),
                  link: './companies',
                  role: $checkRole('companies_show')
                },
                {
                  name: $t('User Roles'),
                  link: './roles',
                  role: $checkRole('roles_show')
                },
                {
                  name: $t('User Accounts'),
                  link: './users',
                  role: $checkRole('users_show')
                }
              ]"
            />
            <reportsCard
              :cardTitle="$t('Operations settings')"
              :icon="'fal fa-cogs'"
              :reports="[
                {
                  name: $t('Numbering Groups'),
                  link: './invoiceGroups',
                  role:
                    $user.admin ||
                    $checkRole('quotations_show') ||
                    $checkRole('invoices_show') ||
                    $checkRole('bills_show') ||
                    $checkRole('orders_show') ||
                    $checkRole('purchase_orders_show')
                },
                {
                  name: $t('Invoice Status'),
                  link: './invoiceStatus',
                  role:
                    $user.admin ||
                    $checkRole('quotations_show') ||
                    $checkRole('invoices_show') ||
                    $checkRole('bills_show') ||
                    $checkRole('orders_show') ||
                    $checkRole('purchase_orders_show')
                },
                {
                  name: $t('Assets Sections'),
                  link: './capitalsSections',
                  role:
                    $user.admin ||
                    $checkRole('quotations_show') ||
                    $checkRole('invoices_show') ||
                    $checkRole('bills_show') ||
                    $checkRole('orders_show') ||
                    $checkRole('purchase_orders_show')
                },
                {
                  name: $t('Signatures'),
                  link: './Signatures',
                  role: $user.admin || $user.signatures_show
                }
              ]"
            />
            <reportsCard
              :cardTitle="$t('product settings')"
              :icon="'fal fa-boxes-alt'"
              :reports="[
                {
                  name: $t('Units Product'),
                  link: './units',
                  role: $checkRole('products_show')
                },
                {
                  name: $t('Product Details'),
                  link: './details',
                  role: $checkRole('products_show')
                },
                {
                  name: $t('Products Options'),
                  link: './productOptions',
                  role: $checkRole('products_show')
                },
                {
                  name: $t('Product Categories'),
                  link: './sections',
                  role: $checkRole('products_show')
                },
                {
                  name: $t('Units Coefficient'),
                  link: '/unitsCoefficients',
                  role: $checkRole('products_show')
                }
              ]"
            />
          </div>
          <div class="col-md-6">
            <reportsCard
              :cardTitle="$t('Payment settings')"
              :icon="'fal fa-file-invoice-dollar'"
              :reports="[
                {
                  name: $t('payment Methods'),
                  link: './paymentMethods',
                  role:
                    $user.admin ||
                    $checkRole('sales_payments_show') ||
                    $checkRole('purchase_payments_show') ||
                    $checkRole('expenses_show')
                },
                {
                  name: $t('payment Fees'),
                  link: './paymentFees',
                  role:
                    $user.admin ||
                    $checkRole('sales_payments_show') ||
                    $checkRole('purchase_payments_show') ||
                    $checkRole('expenses_show')
                },
                {
                  name: $t('Tax Rates'),
                  link: './taxes',
                  role:
                    $user.admin ||
                    $checkRole('quotations_show') ||
                    $checkRole('invoices_show') ||
                    $checkRole('bills_show') ||
                    $checkRole('orders_show') ||
                    $checkRole('purchase_orders_show') ||
                    $checkRole('products_show')
                },
                {
                  name: $t('discount rates'),
                  link: './discounts',
                  role:
                    $user.admin ||
                    $checkRole('quotations_show') ||
                    $checkRole('invoices_show') ||
                    $checkRole('bills_show') ||
                    $checkRole('orders_show') ||
                    $checkRole('purchase_orders_show') ||
                    $checkRole('products_show')
                },
                {
                  name: $t('Shipping companies'),
                  link: './deliveries',
                  role: true
                },
                {
                  name: $t('marketers'),
                  link: './marketers',
                  role: true
                },
                {
                  name: $t('Price Lists'),
                  link: './PriceLists',
                  role: $checkRole('products_show')
                },
                // {
                //   name: $t('Sections bond'),
                //   link: './PaymentsSections',
                //   role: true
                // },
                {
                  name: $t('expenditure Categories'),
                  link: './expenseSections',
                  role: $checkRole('expenses_show')
                },
                {
                  name: $t('Incomes Categories'),
                  link: './incomeSections',
                  role: $checkRole('expenses_show')
                },
                {
                  name: $t('Customers Sections'),
                  link: './customerSections',
                  role: $checkRole('products_show')
                },
                {
                  name: $t('Suppliers Sections'),
                  link: './supplierSections',
                  role: $checkRole('products_show')
                }
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
export default {
  data() {
    return {
      path: '/options'
    }
  },
  components: {
    reportsCard,
    addHeader,
    loading,
    dvider
  }
}
</script>
