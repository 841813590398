<template>
  <div class="row container-fluid">
    <div class="col-12">
      <PageHeader
        :title="$t('account Operations')"
        :description="$t('From here you can control your account Operations reports')"
      />
    </div>
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('accounts')"
      :icon="'fa-coins'"
      v-if="
        ($user.role.available_reports.find(
          (el) => el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37
        ) ||
          $user.admin) &&
        $site.accounts_allow
      "
      :reports="[
        {
          name: $t('total Accounts'),
          link: '/totalAccounts',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        },
        {
          name: $t('account Details'),
          link: '/accountDetails',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        },
        {
          name: $t('account Balances'),
          link: '/accountBalances',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        },
        {
          name: $t('accounts Guide'),
          link: '/accountsGuide',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        },
        {
          name: $t('movement Accounts'),
          link: '/movementAccounts',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        }
      ]"
    />

    <!-- تقارير  عمليات الحسابات  -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('account Operations')"
      :icon="'fa-hand-holding-usd'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || $user.admin) &&
        ($site.accountsDeposits_allow || $site.accountsWithdrawals_allow)
      "
      :reports="[
        {
          name: $t('account Transaction Payments Record'),
          link: './accountTransactionPaymentsRecord',
          role: $user.role.available_reports.find((el) => el.id == 31) || $user.admin
        },
        {
          name: $t('record Withdrawals Accounts Operations'),
          link: '/recordWithdrawalsAccountsOperations',
          role: $user.role.available_reports.find((el) => el.id == 32) || $user.admin
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
