var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Suppliers remaining balance')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t('Duration')))]),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('Choose duration'),"button-now-translation":_vm.$t('now'),"format":"YYYY-MM-DD","formated":"YYYY-MM-DD","color":"#631263","button-color":"#631263","onlyDate":true},model:{value:(_vm.params.date),callback:function ($$v) {_vm.$set(_vm.params, "date", $$v)},expression:"params.date"}})],1)]),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Supplier'),"plaseholder":_vm.$t('Choose supplier'),"show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('section supplier'),"plaseholder":_vm.$t('Choose section'),"show":'name',"refrance":'id',"value":_vm.params.account_id,"values":_vm.$database.supplierSections},model:{value:(_vm.params.account_id),callback:function ($$v) {_vm.$set(_vm.params, "account_id", $$v)},expression:"params.account_id"}}),_c('selectInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Status'),"plaseholder":_vm.$t('Choose status'),"values":[
                { name: _vm.$t('Debtor'), value: 0 },
                { name: _vm.$t('Creditor'), value: 1 },
                { name: _vm.$t('Suspended'), value: 2 }
              ]},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Suppliers remaining balance'),"details":[
            { title: 'اسم المورد', value: _vm.supplierName },
            { title: 'الحالة', value: _vm.statusName },
            {
              title: _vm.$t('sectionsupplier'),
              value: _vm.sectionSupplierName
            }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'name',
              link: '/suppliers',
              title: _vm.$t('Supplier'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              type: 'text',
              sort: 'true',
              title: _vm.$t('balance')
            }
          ],"footer":[
            { colspan: 1, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }