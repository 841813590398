<template>
  <div class="col-12">
    <div class="d-xl-block d-none">
      <div class="row invoice-table-header">
        <div class="col-xl-4">
          {{ $t('ProductItem') }}
        </div>
        <div class="col-xl-4">
          {{ $t('Unit') }}
        </div>
        <div class="col-xl-4">
          {{ $t('Quantity') }}
        </div>
      </div>
    </div>
    <quotationItem
      v-for="(rowItem, index) in storeSettlementsItems"
      :key="index"
      :index="index"
      :item="rowItem"
      @changePrice="changePrice"
    />
    <!-- <button class="btn btn-success w-100 mb-4" @click="$parent.addQuotationItem()">
      <i class="fas fa-plus"></i> {{ $t('Addition') }}
    </button> -->
  </div>
</template>
<script setup>
import quotationItem from './quotationItem.vue'
import Vue, { onMounted, ref } from 'vue'
const items = ref([])
const filteredItems = ref([])
const updateQuantities = () => {
  filteredItems.value = []
  items.value.forEach((item) => {
    if (item.track_quantity == 1) filteredItems.value.push(item)
  })
}
onMounted(() => {
  items.value = Vue.prototype.$database.products.concat(Vue.prototype.$database.materials)
  updateQuantities()
})
const changePrice = () => {
  emit('changePrice')
}
const emit = defineEmits(['changePrice'])
defineProps(['storeSettlementsItems'])
</script>
