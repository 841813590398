<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('expenses by category')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i> {{ $t('Reports Options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Main Category')"
                :plaseholder="$t('Choose Category')"
                v-model="params.expense_section_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.expense_section_id"
                :values="$database.expenseSections.filter((el) => el.expense_section_id == 0)"
              />
              <searchInput
                v-if="params.expense_section_id"
                class="col-md-3"
                :title="$t('Subcategory')"
                :plaseholder="$t('Choose SubCategory')"
                v-model="params.sup_expense_section_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.sup_expense_section_id"
                :values="
                  $database.expenseSections.filter(
                    (el) => el.expense_section_id == params.expense_section_id
                  )
                "
              />

              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('user')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <!-- <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div> -->
              <searchInput
                class="col-md-3"
                :title="$t('Cost center')"
                :plaseholder="$t('Choose a cost center')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.cost_center_id"
                :value="params.cost_center_id"
                :values="$database.costCenters"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Safe')"
                :plaseholder="$t('Choose Safe')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.safe_id"
                :value="params.safe_id"
                :values="[...$database.safes, ...$database.bankAccounts]"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose Customer')"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Supplier')"
                :plaseholder="$t('Choose Supplier')"
                v-model="params.supplier_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.supplier_id"
                :values="$database.suppliers"
              />

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Expenses by category')"
            :details="[
              { title: $t('MainCategory'), value: sectionName },
              { title: $t('SubCategory'), value: supSectionName },
              { title: $t('UserName'), value: userName },
              { title: $t('Safe'), value: safeName },
              { title: $t('Customer'), value: customerName },
              { title: $t('Supplier'), value: supplierName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('There are no data')"
            :emptyTableSubText="$t('Try adding some and Try again')"
            :cloumns="[
              { column: 'name', title: $t('Category'), type: 'text', sort: true },
              {
                column: 'expensesCount',
                title: $t('Expenses Count'),
                type: 'text',
                sort: true
              },
              {
                column: 'cost',
                title: $t('Cost'),
                type: 'text',
                sort: true
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('Total') },
              { colspan: 1, value: expensesCount },
              { colspan: 1, value: cost }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/expenses/expenseByCategory',
      items: [],
      loading: 1,
      branchArray: [],

      expensesCount: 0,
      cost: 0,

      params: {
        expense_section_id: '',
        customer_id: '',
        supplier_id: '',
        branch_id: '',
        safe_id: '',
        user_id: '',
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'expenses by category',
        reportData: '[]'
      },

      sectionName: '',
      supSectionName: '',
      userName: '',
      safeName: '',
      customerName: '',
      supplierName: '',

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.totalPages = 0
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        this.expensesCount = 0
        this.cost = 0

        this.items.forEach((item) => {
          item.expensesCount = item.expenses.length
          item.cost = 0

          item.expenses.forEach((expense) => {
            item.cost += expense.cost
          })
          this.cost += item.cost
          this.expensesCount += item.expensesCount

          item.cost = this.$moneyFormat(item.cost)
          item.expensesCount = item.expensesCount
        })

        this.cost = this.$moneyFormat(this.cost)
        this.expensesCount = this.expensesCount

        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].cost == 0) {
            this.items.splice(i, 1)
            i--
          }
        }
        this.loading = 0
      })

      var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
      if (customer) {
        this.customerName = customer.name
      } else {
        this.customerName = ''
      }

      var safe = this.$database.safes.find((el) => el.id == this.params.safe_id)
      if (safe) {
        this.safeName = safe.name
      } else {
        this.safeName = ''
      }

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      var supplier = this.$database.suppliers.find((el) => el.id == this.params.supplier_id)
      if (supplier) {
        this.supplierName = supplier.name
      } else {
        this.supplierName = ''
      }

      var section = this.$database.expenseSections.find(
        (el) => el.id == this.params.expense_section_id
      )
      if (section) {
        this.sectionName = section.name
      } else {
        this.sectionName = ''
      }

      var supSection = this.$database.expenseSections.find(
        (el) => el.id == this.params.sup_expense_section_id
      )
      if (section) {
        this.supSectionName = supSection.name
      } else {
        this.supSectionName = ''
      }
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.$updateDatabase(['expenseSections', 'users'])
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
