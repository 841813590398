<template>
  <div class="row">
    <div class="col-md-7">
      <div class="login-background">
        <div class="login-overlay"></div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="justify-content-center">
        <div class="login-form">
          <img src="accounting/img/logo.png" class="login-logo" />
          <h1 class="display-4 text-center mb-3">{{ $t('Login') }}</h1>
          <p class="text-muted text-center mb-5">{{ $t('Login to the erp system') }}</p>
          <div class="old-users" v-if="$users.length > 0">
            <div class="users-container">
              <div class="old-user-container" v-for="(user, index) in $users" :key="index">
                <div class="old-user-remove" @click="$removeUser(user.id)">
                  <i class="fas fa-times"></i>
                </div>
                <div class="old-user-img" @click="softLogin(user.id)">
                  <div class="dummy"></div>
                  <div class="image"></div>
                </div>
                <div class="old-user-name" @click="softLogin(user.id)">
                  {{ user?.name }}
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div class="alert-container" v-if="error">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>×</strong>
              <strong>{{ errorMessage }}</strong>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('User Name') }}</label>
            <input
              type="email"
              class="form-control"
              v-model="user.email"
              :placeholder="$t('Enter User Name')"
            />
          </div>
          <div class="form-group">
            <label>{{ $t('Password') }}</label>
            <input
              type="password"
              v-model="user.password"
              class="form-control"
              :placeholder="$t('Enter Password')"
            />
          </div>
          <button class="btn btn-lg btn-block btn-primary mb-3" @click="login">
            {{ $t('Login') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import Vue, { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
const router = proxy.$router
const user = ref({ email: '', password: '' })
const link = 'http://system.salamicollection.com'

const error = ref(false)
const errorMessage = ref('')
const login = () => {
  axios
    .post(link + '/api/login', {
      email: user.value.email.toLowerCase(),
      password: user.value.password
    })
    .then((response) => {
      if (response.data.status == 'success') {
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('company', response.data.company)
        axios.defaults.headers.common['Authorization'] = `${response.data.token}`
        axios.defaults.headers.common['company'] = `${response.data.company}`
        const url = new URL(link)
        const hostnameParts = url.hostname.split('.')
        const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : null
        localStorage.setItem('subdomain', subdomain)
        localStorage.setItem(
          'auth',
          '?token=' + response.data.token + '&company=' + response.data.company
        )
        localStorage.setItem('site', link)
        router.push('/')
      } else {
        error.value = true
        errorMessage.value = response.data.error
        setTimeout(() => {
          error.value = false
          errorMessage.value = ''
        }, 4000)
      }
    })
    .catch((response) => {
      error.value = true
      errorMessage.value = 'مشكلة في الأتصال بالأنترنت او رابط غير صحيح'
      setTimeout(() => {
        error.value = false
        errorMessage.value = ''
      }, 4000)
      return response
    })
}

onMounted(() => {
  var token = localStorage.getItem('token')
  if (token) {
    router.push('/')
  }
})
</script>
