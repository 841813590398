var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c(_setup.showHeader,{staticClass:"col-12 mt-3",attrs:{"image":_setup.item.image,"icon":'box-open',"title":_setup.item.name,"subData":[
        {
          title: _vm.$t('Selling price'),
          value: _vm.$moneyFormat(_setup.item.price)
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: `/products/${_setup.route.params.id}/edit`,
          role: _vm.$checkRole('products_edit')
        }
      ]}}),_c(_setup.showCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Background'),"columns":{
        firstColumn: [
          {
            icon: 'box-open',
            title: _vm.$t('Product name'),
            value: _setup.item.name
          },
          {
            icon: 'layer-group',
            title: _vm.$t('Product rating'),
            value: _setup.item.section,
            type: 'link',
            to: 'section',
            link: false
          },
          {
            icon: 'terminal',
            title: _vm.$t('Product code'),
            value: _setup.item.code
          }
        ],
        secondColumn: [
          {
            icon: 'gift',
            title: _vm.$t('Product type'),
            value: _setup.item.type == 0 ? _vm.$t('product') : _vm.$t('service')
          },
          {
            icon: 'user-tie',
            title: _vm.$t('Supplier'),
            value: _vm.$database.suppliers.find((el) => el.id == _setup.item.supplier_id)
              ? _vm.$database.suppliers.find((el) => el.id == _setup.item.supplier_id)
              : '--',
            type: 'link',
            to: 'supplier',
            link: true
          },
          {
            icon: 'barcode',
            title: _vm.$t('Barcode'),
            value: _setup.getBarcode(_setup.item.barcode)
          }
        ]
      }}}),_c(_setup.showCard,{staticClass:"col-12",attrs:{"title":_vm.$t('Accounts information'),"columns":{
        firstColumn: [
          {
            icon: 'user-tie',
            title: _vm.$t('Supplier'),
            value: _setup.item.supplier,
            type: 'link',
            to: 'supplier',
            link: true
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Cost'),
            value: _vm.$moneyFormat(_setup.item.cost)
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Average cost'),
            value: _vm.$moneyFormat(_setup.item.average_cost)
          }
        ],
        secondColumn: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('sellingPrice'),
            value: _vm.$moneyFormat(_setup.item.price)
          },

          {
            icon: 'dollar-sign',
            title: _vm.$t('Profit'),
            value: _vm.$moneyFormat(_setup.item.price - _setup.item.cost)
          }
        ]
      }}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c(_setup.HistoryCard,{attrs:{"items":_setup.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }