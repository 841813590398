<template>
  <div>
    <div>
      <div
        class="inv-design"
        :class="$option.print_type == 3 ? 'inv-design-5cm' : ''"
        v-if="$option.print_type == 1 || $option.print_type == 3"
      >
        <img :src="logo" class="img-fluid mb-4 sm-inv-img" v-if="$option.image != null" />
        <div class="text-center">
          <p class="f-14">{{ $option.name }}</p>
          <p class="f-12">{{ $option.address1 }}</p>
          <p class="f-14">{{ item.code }}</p>
          <div style="display: flex">
            <div style="text-align: center">
              <p class="f-12">
                تاريخ التسجيل : <strong>{{ $dateFormat(item.created_date) }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table my-4">
            <tfoot>
              <tr>
                <th class="px-0">
                  <strong>المبلغ</strong>
                </th>
                <td class="px-0">
                  <span class="h3">
                    {{ $moneyFormat(item.cost) }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>المسئول</th>
                <td>{{ item.user != null ? item.user.name : '--' }}</td>
              </tr>
              <tr>
                <th>الرقم المرجعي</th>
                <td>{{ item.refrance != null ? item.refrance : '--' }}</td>
              </tr>
              <tr>
                <th>تلقي من</th>
                <td>
                  {{
                    item.client_type == 0
                      ? $database.customers.find((el) => el.id == item.customer_id)?.name
                      : item.client_type == 1
                        ? $database.suppliers.find((el) => el.id == item.supplier_id)?.name
                        : $database.partners.find((el) => el.id == item.partner_id)?.name
                  }}
                </td>
              </tr>
              <tr>
                <th>طريقة الدفع</th>
                <td>
                  {{ $database.paymentMethods.find((el) => el.id == payment_method_id)?.name }}
                </td>
              </tr>
              <tr>
                <th>الحالة</th>
                <td>
                  {{ item?.stat ? $t('Paid') : $t('Waiting') }}
                </td>
              </tr>
              <tr>
                <th>تارخ القبض</th>
                <td>
                  {{ $dateFormat(item?.date) }}
                </td>
              </tr>
              <tr>
                <th>الخزينة</th>
                <td>{{ $database.safes.find((el) => el.id == item?.safe_id)?.name }}</td>
              </tr>
              <tr>
                <th>الرصيد السابق</th>
                <td>{{ $moneyFormat(item?.old_balance) }}</td>
              </tr>
              <tr>
                <th>الرصيد الكلي</th>
                <td>{{ $moneyFormat(item?.new_balance) }}</td>
              </tr>
            </tfoot>
          </table>
          <p
            class="text-muted mb-0"
            v-html="
              $option.sales_cheques_print_footer != null ? $option.sales_cheques_print_footer : ''
            "
          ></p>
        </div>
      </div>
      <div class="col text-center" v-if="item.print_type == 0">
        <div class="row">
          <img
            :src="logo"
            class="img-fluid mb-4"
            style="max-width: 4.5rem"
            v-if="$option.image != null"
          />
          <h2 class="mb-2">شيك بيع ل {{ $option.name }}</h2>
          <p class="text-muted mb-6">شيك بيع #{{ item.code }}</p>
        </div>
      </div>
      <div class="row" v-if="item.print_type == 0">
        <table class="table table-sm table-nowrap customed-table-small">
          <tbody>
            <tr>
              <th scope="row">رقم السند#</th>
              <td>{{ item.code != null ? item.code : '--' }}</td>
              <td rowspan="7" style="background: #eee; text-align: center; padding-top: 120px">
                <div class="price-show" style="margin-top: 20px; padding-top: 30px">
                  <div class="price-content">
                    <p>{{ $t('Cost') }}</p>
                    <h1>{{ $moneyFormat(item?.cost) }}</h1>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">تاريخ التسجيل</th>
              <td>{{ $dateFormat(item?.created_date) }}</td>
            </tr>
            <tr>
              <th scope="row">تاريخ الدفع</th>
              <td>
                {{ item?.date ? $dateFormat(item?.date) : $dateFormat(Date.now() / 1000) }}
              </td>
            </tr>
            <tr>
              <th scope="row">المسئول</th>
              <td>{{ item?.user != null ? item?.user?.name : '--' }}</td>
            </tr>
            <tr>
              <th scope="row">الرقم المرجعي</th>
              <td>{{ item?.refrance != null ? item?.refrance : '--' }}</td>
            </tr>
            <tr>
              <th scope="row">تلقي من</th>
              <td>
                {{
                  item.client_type == 0
                    ? $database.customers.find((el) => el.id == item.customer_id)?.name
                    : item.client_type == 1
                      ? $database.suppliers.find((el) => el.id == item.supplier_id)?.name
                      : $database.partners.find((el) => el.id == item.partner_id)?.name
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">طريقة الدفع</th>
              <td>
                {{ $database.paymentMethods.find((el) => el.id == payment_method_id)?.name }}
              </td>
            </tr>
            <tr>
              <th scope="row">الحالة</th>
              <td>
                {{ item?.stat ? $t('Paid') : $t('Waiting') }}
              </td>
            </tr>
            <tr>
              <th scope="row">الخزينة</th>
              <td>{{ $database.safes.find((el) => el.id == item?.safe_id)?.name }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-sm table-nowrap">
          <thead>
            <tr>
              <th scope="col">اسم العميل</th>
              <th scope="col">النقد</th>
              <th scope="col">رسوم بنكية</th>
              <th scope="col">ضريبة البنك</th>
              <th scope="col">الرصيد السابق</th>
              <th scope="col">الرصيد الكلي</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                {{
                  item.client_type == 0
                    ? $database.customers.find((el) => el.id == item.customer_id)?.name
                    : item.client_type == 1
                      ? $database.suppliers.find((el) => el.id == item.supplier_id)?.name
                      : $database.partners.find((el) => el.id == item.partner_id)?.name
                }}
              </th>
              <th>{{ $moneyFormat(item?.cost) }}</th>
              <th>{{ $moneyFormat(item?.bank_fees) }}</th>
              <th>{{ $moneyFormat(item?.bank_tax_amount) }}</th>
              <th>{{ $moneyFormat(item?.old_balance) }}</th>
              <td>{{ $moneyFormat(item?.new_balance) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $option.sales_cheque_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
      <p class="pioneer-data">تم الإنشاء بواسطة Pioneer للبرمجيات 01019654321</p>
    </div>
  </div>
</template>
<script>
import CustomersBalance from '@/pages/main/components/customersBalance.vue'

export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },
  props: {
    item: {}
  }
}
</script>
