<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="'طلبات الشراء حسب المنتج.'" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Supplier')"
                :plaseholder="$t('Choose supplier')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.supplier_id"
                :value="params.supplier_id"
                :values="$database.suppliers"
              />
              <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('user')"
                :plaseholder="$t('Choose user')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.user_id"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Store')"
                :plaseholder="$t('Choose store')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            title="طلبات الشراء حسب المنتج"
            :details="[
              { title: 'اسم المورد', value: supplierName },
              { title: $t('Username'), value: userName },
              { title: $t('Store'), value: storeName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'product_name',
                link: '/products',
                title: $t('Product name'),
                type: 'reportMainLink',
                sort: true
              },

              {
                column: 'quantity',
                title: 'الكمية المباعة',
                type: 'text',
                sort: true
              },
              {
                column: 'average',
                title: 'متوسط السعر',
                type: 'text',
                sort: true
              },
              {
                column: 'price',
                title: 'المبلغ',
                type: 'text',
                sort: true
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('Total') },
              { colspan: 1, value: quantity },
              { colspan: 1, value: average },
              { colspan: 1, value: price }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/purchasesOrders/products',
      items: [],
      branchArray: [],

      loading: 0,

      quantity: 0,
      price: 0,
      average: 0,

      params: {
        supplier_id: '',
        store_id: '',
        user_id: '',
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'purchase order by item',
        reportData: '[]'
      },

      supplierName: '',
      userName: '',
      storeName: '',

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        this.quantity = 0
        this.price = 0
        this.profit = 0

        this.items = []
        response.data.forEach((item) => {
          var mitem = this.items.find((el) => el.product_id == item.product_id)
          if (typeof mitem === 'undefined') {
            this.items.push(item)
            var mitem = this.items.find((el) => el.product_id == item.product_id)
            mitem.average = mitem.price
          } else {
            mitem.quantity += item.quantity
            mitem.price += item.price
            mitem.average = mitem.price != 0 ? Math.ceil(mitem.price / mitem.quantity) : 0
          }
          this.average += mitem.average
          this.quantity += item.quantity
          this.price += item.price
        })
        response.data.forEach((item) => {
          item.average = this.$moneyFormat(item.average)
          item.price = this.$moneyFormat(item.price)
        })
        this.price = this.$moneyFormat(this.price)
        this.average = this.$moneyFormat(this.average)
        this.loading = 0
      })

      var supplier = this.$database.suppliers.find((el) => el.id == this.params.supplier_id)
      if (supplier) {
        this.supplierName = supplier.name
      } else {
        this.supplierName = ''
      }

      var store = this.$database.stores.find((el) => el.id == this.params.store_id)
      if (store) {
        this.storeName = store.name
      } else {
        this.storeName = ''
      }

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }
    },
    getItems() {
      this.$setParams(params)
      if (params.orderBy) {
        this.items.sort(this.$dynamicSort(params))
      }
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
    this.$updateDatabase(['users'])
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
