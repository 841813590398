<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create a Branch')"
          :description="$t('from here you can create a new branch')"
          v-if="$route.name == 'branchesCreate'"
        />
        <addHeader
          :title="$t('Branch adjustment')"
          :description="$t('From here you can Modify the branch')"
          v-if="$route.name == 'branchesEdit'"
        />
        <div class="mb-4 row">
          <formInput
            :title="$t('Name')"
            :checkError="'nameErorr'"
            v-model="item.name"
            :hasErorr="errors.name"
            maxlength="255"
            :error="$t('This field is required')"
          />
          <!-- multiselect -->
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Available Point of Sales') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="point_of_sale_id"
                :options="$database.pointOfSales"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose the sections')"
                label="name"
                track-by="name"
                :hide-selected="true"
              />
            </div>
          </div>
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Available Safes') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="safe_id"
                :options="[...$database.safes, ...$database.bankAccounts]"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose the sections')"
                label="name"
                track-by="name"
                :hide-selected="true"
              />
            </div>
          </div>
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Available Stores') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="store_id"
                :options="$database.stores"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose the sections')"
                label="name"
                track-by="name"
                :hide-selected="true"
              />
            </div>
          </div>

          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'branchesCreate' && !$parent.stopEdit"
          >
            {{ $t('Create A Branch') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'branchesEdit' && !$parent.stopEdit"
          >
            {{ $t('Branch adjustment') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import addHeader from '@/elements/add/header.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'

import dvider from '@/elements/add/dvider.vue'
import loading from '@/elements/add/loading.vue'

export default {
  data() {
    return {
      path: '/branches',
      safe_id: [],
      store_id: [],
      point_of_sale_id: [],
      upload_key: new Date().getTime().toString() + Math.floor(Math.random() * 999),
      item: {
        name: null,
        safe_id: [],
        store_id: [],
        point_of_sale_id: [],
        id: null,
        company_id: null
      },
      errors: {
        name: false,
        safe_id: false,
        store_id: false,
        customer_id: false,
        invoice_group_id: false
      }
    }
  },
  mounted() {
    if (this.$route.name == 'branchesEdit') {
      this.getItem()
    }
    this.$updateDatabase([
      'stores',
      'sections',
      'discounts',
      'priceLists',
      'safes',
      'customers',
      'invoiceGroups',
      'deliveries',
      'pickupLocations'
    ])
  },
  methods: {
    gettingIds(item1, item2) {
      if (item1.length > 0) {
        item1.map((item, index) => {
          item2.push(item.id)
        })
      }
    },
    gettingObjects(item1, item2, collection) {
      if (item1.length > 0) {
        item1.map((item, index) => {
          const itemObject = this.$database[collection].find((el) => item == el.id)
          item2.push(itemObject)
        })
      }
    },
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        // this.item = response.data
        this.item.name = response.data.name
        this.gettingObjects(response.data.store_id, this.store_id, 'stores')
        this.gettingObjects(response.data.safe_id, this.safe_id, 'safes')
        this.gettingObjects(response.data.point_of_sale_id, this.point_of_sale_id, 'pointOfSales')
        this.item.id = response.data.id
        this.upload_key = response.data.upload_key
        this.company_id = response.data.company_id
      })
    },
    saveItem() {
      this.gettingIds(this.point_of_sale_id, this.item.point_of_sale_id)
      this.gettingIds(this.safe_id, this.item.safe_id)
      this.gettingIds(this.store_id, this.item.store_id)

      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (typeof this.item.safe_id === 'undefined' || this.item.safe_id == '') {
        error = 1
        this.errors.safe_id = true
      }
      if (typeof this.item.store_id === 'undefined' || this.item.store_id == '') {
        error = 1
        this.errors.store_id = true
      }
      if (typeof this.item.point_of_sale_id === 'undefined' || this.item.point_of_sale_id == '') {
        error = 1
        this.errors.point_of_sale_id = true
      }

      if (error == 0) {
        if (this.$route.name == 'branchesCreate') {
          axios
            .post(this.$linkGenerator(this.path), {
              data: [
                {
                  name: this.item.name,
                  safe_id: this.item.safe_id,
                  store_id: this.item.store_id,
                  point_of_sale_id: this.item.point_of_sale_id,
                  upload_key: this.upload_key
                }
              ]
            })
            .then((response) => {
              this.$router.go(-1)
              return response
            })
        } else if (this.$route.name == 'branchesEdit') {
          axios
            .put(this.$linkGenerator(this.path + '/' + this.item.id), {
              name: this.item.name,
              safe_id: this.item.safe_id,
              store_id: this.item.store_id,
              point_of_sale_id: this.item.point_of_sale_id,
              upload_key: this.upload_key,
              company_id: this.item.company_id,
              id: this.item.id
            })
            .then((response) => {
              this.$toast.success(this.$t('Point Of Sale has Been Modified Successfully'))
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$toast.error(this.$t('Please make sure of the input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },

  components: {
    formInput,
    formTextarea,
    addHeader,
    connectionInput,
    checkInput,
    loading,
    dvider,
    searchGroupInput
  }
}
</script>
