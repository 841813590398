var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('sales'),"description":_vm.$t('From here you can control your sales reports')}})],1),_c('div',{staticClass:"col-md-6"},[(
          (_vm.$user.role.available_reports.find(
            (el) => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4
          ) ||
            _vm.$user.admin) &&
          _vm.$site.customers_allow
        )?_c('reportsCard',{staticClass:"title-form",attrs:{"cardTitle":_vm.$t('customers'),"icon":'fa-user-alt',"reports":[
          {
            name: _vm.$t('Total CustomerAccount'),
            link: '/customersTotalAccounts',
            role: _vm.$user.role.available_reports.find((el) => el.id == 1) || _vm.$user.admin
          },
          // {
          //   name: $t('Customer Account Details'),
          //   link: '/customersDetailsAccounts',
          //   role: $user.role.available_reports.find((el) => el.id == 2) || $user.admin
          // },
          {
            name: _vm.$t('Customer Assets'),
            link: '/customersBalance',
            role: _vm.$user.role.available_reports.find((el) => el.id == 3) || _vm.$user.admin
          },
          {
            name: _vm.$t('Customer Guide'),
            link: '/customersGuide',
            role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
          },

          {
            name: _vm.$t('inactive Clients'),
            link: '/customersInactive',
            role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
          },
          {
            name: _vm.$t('active Customers'),
            link: '/customersActive',
            role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
          },
          {
            name: _vm.$t('customer Traffic'),
            link: '/customerTraffic',
            role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
          },
          {
            name: _vm.$t('sales Collection'),
            link: '/salesCollection',
            role: _vm.$user.role.available_reports.find((el) => el.id == 4) || _vm.$user.admin
          }
        ]}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[(
          (_vm.$user.role.available_reports.find((el) => el.id == 13 || el.id == 14) ||
            _vm.$user.admin) &&
          _vm.$site.invoices_allow
        )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Sales'),"icon":'fa-file-invoice-dollar',"reports":[
          {
            name: _vm.$t('Sales By Invoice'),
            link: './salesByInvoice',
            role: _vm.$user.role.available_reports.find((el) => el.id == 13) || _vm.$user.admin
          },
          {
            name: _vm.$t('Sales By Product'),
            link: '/salesByItem',
            role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
          },
          {
            name: _vm.$t('Sales By Branch'),
            link: '/salesByBranch',
            role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
          },
          {
            name: _vm.$t('Sales By Section'),
            link: '/salesBySection',
            role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
          },
          {
            name: _vm.$t('Sales By User'),
            link: '/salesByUser',
            role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
          },
          {
            name: _vm.$t('customer Sales Prices'),
            link: '/customerSalesPrices',
            role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
          },
          {
            name: _vm.$t('sales By Customer'),
            link: '/salesByCustomer',
            role: _vm.$user.role.available_reports.find((el) => el.id == 14) || _vm.$user.admin
          }
        ]}}):_vm._e(),(
          (_vm.$user.role.available_reports.find((el) => el.id == 21 || el.id == 22) ||
            _vm.$user.admin) &&
          _vm.$site.orders_allow
        )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('Sales Orders'),"icon":'fa-briefcase',"reports":[
          {
            name: _vm.$t('Orders By Invoice'),
            link: './salesOrdersByInvoice',
            role: _vm.$user.role.available_reports.find((el) => el.id == 21) || _vm.$user.admin
          },
          {
            name: _vm.$t('Orders By Product'),
            link: '/salesOrdersByItem',
            role: _vm.$user.role.available_reports.find((el) => el.id == 22) || _vm.$user.admin
          }
        ]}}):_vm._e(),(
          (_vm.$user.role.available_reports.find((el) => el.id == 17 || el.id == 18) ||
            _vm.$user.admin) &&
          _vm.$site.invoices_allow
        )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('invoices'),"icon":'fa-exchange',"reports":[
          {
            name: _vm.$t('Returned By Invoice'),
            link: './salesReturnsByInvoice',
            role: _vm.$user.role.available_reports.find((el) => el.id == 17) || _vm.$user.admin
          },
          {
            name: _vm.$t('Returned By Product'),
            link: '/salesReturnsByItem',
            role: _vm.$user.role.available_reports.find((el) => el.id == 18) || _vm.$user.admin
          }
        ]}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }