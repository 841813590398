<template>
  <vue-custom-scrollbar
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
    :settings="settings"
  >
    <div ref="elementRef" class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMobile"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="
          toggleMobileNave
          showMobileMenu = false
        "
      >
        <router-link
          :to="'/profile'"
          class="navbar-user-link nav-icon dropdown-toggle w-100"
          v-if="$checkRole('profile_edit')"
        >
          <span class="icon"> <i class="far fa-user"></i> {{ $user.name }} </span>
        </router-link>
        <div
          class="navbar-user-link nav-icon dropdown-toggle w-100"
          v-if="!$checkRole('profile_edit')"
        >
          <span class="icon"> <i class="far fa-user"></i> {{ $user.name }} </span>
        </div>
      </button>
      <SearchBar />
      <vue-custom-scrollbar
        :settings="settings2"
        class="collapse navbar-collapse"
        :class="{ show: showMobileMenu }"
        id="sidebarCollapse"
      >
        <ul v-if="selectedLinks.length != 0" class="navbar-nav">
          <singleItem
            v-for="(link, index) in selectedLinks"
            :to="link.link.substring(1)"
            :icon="'far fa-file'"
            :headName="link.name"
            :key="index"
          />
        </ul>
        <ul class="navbar-nav" v-if="selectedLinks.length == 0">
          <singleItem
            :to="''"
            :icon="'far fa-tachometer-alt-fastest'"
            :headName="$t('dashboard')"
          ></singleItem>
        </ul>
        <hr class="navbar-divider my-3" v-if="selectedLinks.length == 0" />

        <ul class="navbar-nav" v-if="selectedLinks.length == 0">
          <!-- sales -->
          <multiItem
            :icon="'far fa-chart-line'"
            :headName="$t('sales')"
            v-if="
              $checkMultiRoles([
                'customers_show',
                'quotations_show',
                'invoices_show',
                'sale_returns_show',
                'orders_show'
              ])
            "
            :items="[
              {
                name: $t('customers'),
                link: '/customers',
                role: $checkRole('customers_show')
              },
              {
                name: $t('salesInvoices'),
                link: '/invoices',
                role: $checkRole('invoices_show')
              },
              {
                name: $t('salesReturns'),
                link: '/saleReturns',
                role: $checkRole('sale_returns_show')
              }
            ]"
          />
          <!-- purchase -->
          <multiItem
            :icon="'far fa-shopping-cart'"
            :headName="$t('purchases')"
            v-if="$checkMultiRoles(['purchase_returns_show', 'bills_show', 'suppliers_show'])"
            :items="[
              {
                name: $t('Suppliers'),
                link: '/suppliers',
                role: $checkRole('suppliers_show')
              },
              {
                name: $t('Bills'),
                link: '/bills',
                role: $checkRole('bills_show')
              },
              {
                name: $t('purchaseReturns'),
                link: '/purchaseReturns',
                role: $checkRole('purchase_returns_show')
              }
            ]"
          />
          <!-- safes -->
          <multiItem
            :icon="'far fa-treasure-chest'"
            :headName="$t('Safes')"
            v-if="
              $checkMultiRoles([
                'safes_show',
                'safes_deposits_show',
                'safes_withdrawals_show',
                'safes_transfers_show',
                'bank_accounts_show',
                'sales_payments_show',
                'purchase_payments_show',
                'expenses_show',
                'sales_discounts_show',
                'purchase_discounts_show'
              ])
            "
            :items="[
              {
                name: $t('lockersList'),
                link: '/safes',
                role: $checkRole('safes_show')
              },
              {
                name: $t('bankAccounts'),
                link: '/bankAccounts',
                role: $checkRole('bank_accounts_show')
              },
              {
                name: $t('salePayments'),
                link: '/salesPayments',
                role: $checkRole('sales_payments_show')
              },
              {
                name: $t('purchasePayments'),
                link: '/purchasePayments',
                role: $checkRole('purchase_payments_show')
              },
              {
                name: $t('Expenses'),
                link: '/expenses',
                role: $checkRole('expenses_show')
              },
              {
                name: $t('Regular Expenses'),
                link: '/regularExpenses',
                role: $checkRole('expenses_show')
              },
              {
                name: $t('Incomes'),
                link: '/incomes',
                role: $checkRole('expenses_show')
              },
              // {
              //   name: $t('Tax payment'),
              //   link: '/taxPayments/',
              //   role: $checkRole('tax_payments_show')
              // },

              {
                name: $t('Deposits'),
                link: '/safesDeposits',
                role: $checkRole('safes_deposits_show')
              },

              {
                name: $t('Withdrawals'),
                link: '/safesWithdrawals',
                role: $checkRole('safes_withdrawals_show')
              },
              {
                name: $t('Transfers'),
                link: '/safesTransfers',
                role: $checkRole('safes_transfers_show')
              }
            ]"
          />
          <!-- stores -->
          <multiItem
            :icon="'far fa-store-alt'"
            :headName="$t('stores')"
            v-if="
              $checkMultiRoles([
                'stores_show',
                'products_show',
                'stores_deposits_show',
                'stores_withdrawals_show',
                'stores_transfers_show'
              ])
            "
            :items="[
              {
                name: $t('storesList'),
                link: '/stores',
                role: $checkRole('stores_show')
              },
              {
                name: $t('Products and services'),
                link: '/products',
                role: $checkRole('products_show')
              },
              {
                name: $t('Materials'),
                link: '/materials',
                role: $checkRole('materials_show')
              },
              {
                name: $t('Deposits'),
                link: '/storesDeposits',
                role: $checkRole('stores_deposits_show')
              },
              {
                name: $t('Withdrawals'),
                link: '/storesWithdrawals',
                role: $checkRole('stores_withdrawals_show')
              },
              {
                name: $t('Transfers'),
                link: '/storesTransfers',
                role: $checkRole('stores_transfers_show')
              },

              {
                name: $t('Stores loading'),
                link: '/storesOrders',
                role: $checkRole('stores_orders_show')
              },
              {
                name: $t('Stores Settlements'),
                link: '/storesSettlements',
                role: true
              }
            ]"
          />

          <!-- cheques -->
          <multiItem
            :icon="'far fa-money-check-alt'"
            :headName="$t('Cheques')"
            v-if="$checkMultiRoles(['sales_cheques_show', 'purchase_cheques_show'])"
            :items="[
              {
                name: $t('Sales cheques'),
                link: '/salesCheques',
                role: $checkRole('sales_cheques_show')
              },
              {
                name: $t('purchaseCheques'),
                link: '/purchaseCheques',
                role: $checkRole('purchase_cheques_show')
              }
            ]"
          />
          <!-- discounts -->
          <multiItem
            :icon="'far fa-badge-percent'"
            :headName="$t('Discounts')"
            v-if="$checkMultiRoles(['sales_discounts_show', 'purchase_discounts_show'])"
            :items="[
              {
                name: $t('Sales Discounts'),
                link: '/salesDiscounts',
                role: $checkRole('sales_discounts_show')
              },
              {
                name: $t('Purchase discounts'),
                link: '/purchaseDiscounts',
                role: $checkRole('purchase_discounts_show')
              }
            ]"
          />

          <multiItem
            :icon="'far fa-user-chart'"
            :headName="$t('Employees')"
            v-if="
              $checkMultiRoles([
                'employees_show',
                'employees_Salaries_show',
                'employees_Salaries_show',
                'employees_incentives_show',
                'employees_deductions_show',
                'employees_withdrawals_show'
              ])
            "
            :items="[
              {
                name: $t('employeesList'),
                link: '/employees',
                role: $checkRole('employees_show')
              },
              {
                name: $t('employeeSalaries'),
                link: '/employeesSalaries',
                role: $checkRole('employees_Salaries_show')
              },
              {
                name: $t('EmployeeIncentives'),
                link: '/employeesIncentives',
                role: $checkRole('employees_incentives_show')
              },
              {
                name: $t('EmployeeDiscounts'),
                link: '/employeesDeductions',
                role: $checkRole('employees_deductions_show')
              },
              {
                name: $t('Employees withdrawals'),
                link: '/employeesWithdrawals',
                role: $checkRole('employees_withdrawals_show')
              }
            ]"
          />

          <multiItem
            :icon="'far fa-handshake'"
            :headName="$t('partners')"
            v-if="
              $checkMultiRoles([
                'partners_show',
                'partners_deposits_show',
                'partners_withdrawals_show',
                'partners_settlements_show',
                'partners_profits_show'
              ])
            "
            :items="[
              {
                name: $t('partners'),
                link: '/partners',
                role: $checkRole('partners_show')
              },
              {
                name: $t('Partners deposits'),
                link: '/partnersDeposits',
                role: $checkRole('partners_deposits_show')
              },
              {
                name: $t('Withdrawal of partners'),
                link: '/partnersWithdrawals',
                role: $checkRole('partners_withdrawals_show')
              }
              // {
              //   name: $t('Partners settlement'),
              //   link: '/partnersSettlements',
              //   role: $checkRole('partners_settlements_show')
              // },
              // {
              //   name: $t('Divide the profits'),
              //   link: '/partnersProfits',
              //   role: $checkRole('partners_profits_show')
              // }
            ]"
          />

          <singleItem
            :to="'capitals'"
            :icon="'far fa-building'"
            :headName="$t('Fixed assets')"
            v-if="$checkRole('capitals_show')"
          />

          <multiItem
            :icon="'far fa-hammer'"
            :headName="$t('Manufacturing processes')"
            v-if="$checkMultiRoles(['manufacturing_models_show', 'manufacturing_operations_show'])"
            :items="[
              {
                name: $t('ManufacturingModels'),
                link: '/manufacturingModels',
                role: $checkRole('manufacturing_models_show')
              },
              {
                name: $t('OperationsList'),
                link: '/manufacturingOperations',
                role: $checkRole('manufacturing_operations_show')
              }
              // {
              //   name: $t('materialDeposit'),
              //   link: '/manufacturingDeposits',
              //   role: $checkRole('manufacturing_operations_show')
              // },
              // {
              //   name: $t('productsWithdrawal'),
              //   link: '/manufacturingWithdrawals',
              //   role: $checkRole('manufacturing_operations_show')
              // }
            ]"
          />

          <multiItem
            :icon="'far fa-shipping-fast'"
            :headName="$t('Shipping')"
            v-if="$checkMultiRoles(['cities_show'])"
            :items="[
              {
                name: $t('freightForwarders'),
                link: '/deliveries',
                role: true
              },
              {
                name: $t('Cities'),
                link: '/cities',
                role: $checkRole('cities_show')
              },
              {
                name: $t('pickupLocations'),
                link: '/pickupLocations',
                role: $checkRole('cities_show')
              },
              {
                name: $t('shippingOperations'),
                link: '/shippingOperations',
                role: $checkRole('cities_show')
              },
              {
                name: $t('shippingAddresses'),
                link: '/shippingAddresses',
                role: $checkRole('cities_show')
              }
            ]"
          />
          <multiItem
            :icon="'far fa-cash-register'"
            :headName="$t('pointOfSales')"
            v-if="$checkMultiRoles(['point_of_sales_show'])"
            :items="[
              {
                name: $t('pointsOfSale'),
                link: '/pointOfSales',
                role: true
              },
              {
                name: $t('points Of Sales Sessions'),
                link: '/pointOfSalesSessions',
                role: $checkRole('point_of_sales_sessions_show')
              }
            ]"
          />
          <singleItem
            :to="'branches'"
            :icon="'far Example of th-large fa-th-large'"
            :headName="$t('Branches')"
            v-if="$checkRole('branches_show')"
          />
        </ul>
        <ul class="navbar-nav" v-if="$checkRole('reports_show') && selectedLinks.length == 0">
          <singleItem
            :to="'mainReport'"
            :icon="'far fa-chart-bar'"
            :headName="$t('Reports')"
            v-if="$checkRole('reports_show')"
          />
          <multiItem
            :icon="'far fa-balance-scale'"
            :headName="$t('Accounting')"
            v-if="$checkRole('accounting_show')"
            :items="[
              // {
              //   name: $t('Daily restrictions'),
              //   link: '/dailyRestrictions',
              //   role: true
              // },
              {
                name: $t('Accounting restrictions'),
                link: '/accountingRestrictions',
                role: $checkRole('accounting_restrictions_show')
              },
              // {
              //   name: $t('Auto restrictions'),
              //   link: '/autoRestrictions',
              //   role: $checkRole('accounting_restrictions_show')
              // },
              {
                name: $t('Accounting tree'),
                link: '/accountingTree',
                role: true
              },
              {
                name: $t('Profits and losses'),
                link: '/profitsAndLosses',
                role: true
              },
              {
                name: $t('Balance sheet'),
                link: '/totalReport',
                role: true
              },
              {
                name: $t('Profits by invoices'),
                link: '/profitsByInvoices',
                role: true
              },
              // {
              //   name: $t('Daily restrictions'),
              //   link: '/dailyRestrictions',
              //   role: true
              // },
              {
                name: $t('Cost Centers'),
                link: '/costCenters',
                role: true
              },
              {
                name: $t('Account Statements'),
                link: '/totalAccounts',
                role: true
              }
            ]"
          />
        </ul>
        <div class="mt-auto" v-if="selectedLinks.length == 0"></div>
        <ul class="navbar-nav" v-if="selectedLinks.length == 0">
          <singleItem
            :to="'plugins'"
            :icon="'far fa-plug'"
            :headName="$t('Plugins')"
            v-if="$checkRole('options_show')"
          />
          <singleItem
            :to="'options'"
            :icon="'far fa-cog'"
            :headName="$t('Settings')"
            v-if="$checkRole('options_show')"
          />

          <li class="nav-item">
            <a class="nav-link c-pointer" @click="$logout">
              <i class="far fa-sign-out"></i> {{ $t('logout') }}
            </a>
          </li>
        </ul>
      </vue-custom-scrollbar>
    </div>
    <div class="sidebar-user">
      <router-link :to="'/profile'">
        <div class="sidebar-user-flex">
          <div
            class="sidebar-user-img"
            :style="shownImage ? 'background-image: ' + shownImage + ';' : ''"
          ></div>
          <div class="sidebar-user-info">
            <h3>{{ $user.name }}</h3>
            <p>{{ $user.email }}</p>
          </div>
        </div>
      </router-link>
    </div>
  </vue-custom-scrollbar>
</template>
<script setup>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import singleItem from '@/components/sidebar/singleItem.vue'
import multiItem from '@/components/sidebar/multiItem.vue'
import Vue, { ref, onMounted, watch, getCurrentInstance } from 'vue'
import { useClickOutside } from '@/helpers/useClickOutside'
import SearchBar from '@/includes/sideBarComponents/searchBar.vue'
const shownImage = ref('')
const showMobileMenu = ref(false)
const settings = ref({
  suppressScrollY: false,
  suppressScrollX: true,
  wheelPropagation: false
})
const settings2 = ref({
  suppressScrollY: false,
  suppressScrollX: true
})
const selectedLinks = ref([])

const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value) {
    if (value && oldValue != value) removeMobileMenu()
  }
})
const toggleMobile = () => {
  if (showMobileMenu.value) {
    showMobileMenu.value = false

    emit('closeModel')
    document.documentElement.classList.remove('overflow-hidden')
  } else {
    showMobileMenu.value = true
    emit('openModel')
    document.documentElement.classList.add('overflow-hidden')
  }
}
const removeMobileMenu = () => {
  showMobileMenu.value = false
  emit('closeModel')
  document.documentElement.classList.remove('overflow-hidden')
}

onMounted(() => {
  if (Vue.prototype.$user.image) {
    shownImage.value =
      'url("' +
      Vue.prototype.$linkGenerator(
        '/companiesUploads/' +
          localStorage.getItem('subdomain') +
          '/users/' +
          Vue.prototype.$user.image,
        true
      ) +
      '")'
  }
})

watch(
  () => Vue.prototype.$user,
  (newImage) => {
    if (newImage) {
      shownImage.value = `url("${Vue.prototype.$linkGenerator(
        `/companiesUploads/${localStorage.getItem('subdomain')}/users/${newImage}`,
        true
      )}")`
    }
  }
)
const internalInstance = getCurrentInstance()

// Watch for route changes
watch(
  () => internalInstance.proxy.$route.fullPath,
  () => {
    removeMobileMenu()
  }
)

const emit = defineEmits(['openModel', 'closeModel'])
</script>
