<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Sales by customer')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Report options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('Customer name')"
                :plaseholder="$t('Choose customer')"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />
              <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
              <searchInput
                class="col-md-3"
                :title="$t('Section customer')"
                :plaseholder="$t('Choose section')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.account_id"
                :value="params.account_id"
                :values="$database.customerSections"
              />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Sales by customer')"
            :details="[
              {
                title: $t('Customer name'),
                value: customerName
              },
              {
                title: $t('Section customer'),
                value: sectionCustomerName
              }
            ]"
          />
          <reportTable
            :emptyTableText="$t('There are no data at  the moment')"
            :emptyTableSubText="$t('Try adding some and try again')"
            :cloumns="[
              {
                column: 'name',
                link: '/customers',
                title: $t('Customer name'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'total_sales',
                title: $t('Total sales'),
                type: 'text',
                sort: 'true'
              },
              {
                column: 'sales_returns',
                title: $t('Returns'),
                type: 'text',
                sort: 'true'
              },
              {
                column: 'net_sales',
                type: 'text',
                sort: 'true',
                title: $t('Net sales')
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('total') },
              { colspan: 1, value: total_sales },
              { colspan: 1, value: sales_returns },
              { colspan: 1, value: net_sales }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import searchInput from '@/elements/reports/searchInput.vue'
import selectInput from '@/elements/reports/selectInput.vue'
import dateFilter from '@/elements/reports/dateFilter.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
export default {
  data() {
    return {
      path: '/reports/sales/customer',
      items: [],
      branchArray: [],
      loading: 0,

      total_sales: 0,
      sales_returns: 0,
      net_sales: 0,

      customers: [],
      customerName: '',
      sectionCustomerName: '',
      searchStartDate: '',
      searchEndDate: '',
      params: {
        customer_id: '',
        branch_id: '',
        account_id: '',
        status: ''
      },
      excel: {
        fileName: 'customers sales',
        reportData: '[]'
      }
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.totalPages = 0
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.loading = 0
        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate
        this.total_sales = this.$moneyFormat(
          response.data.reduce((sum, el) => sum + el.total_sales, 0)
        )
        this.sales_returns = this.$moneyFormat(
          response.data.reduce((sum, el) => sum + el.sales_returns, 0)
        )
        this.net_sales = this.$moneyFormat(response.data.reduce((sum, el) => sum + el.net_sales, 0))
        this.items = response.data.map((el) => {
          el.total_sales = this.$moneyFormat(el.total_sales)
          el.sales_returns = this.$moneyFormat(el.sales_returns)
          el.net_sales = this.$moneyFormat(el.net_sales)
          return el
        })

        var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
        if (customer) {
          this.customerName = customer.name
        } else {
          this.customerName = ''
        }

        var sectionCustomer = this.$database.customerSections.find(
          (el) => el.id == this.params.account_id
        )
        if (sectionCustomer) {
          this.sectionCustomerName = sectionCustomer.name
        } else {
          this.sectionCustomerName = ''
        }

        this.amount = this.$moneyFormat(this.amount)
      })
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    this.$updateDatabase(['customers'])
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    searchInput,
    actionButtons,
    selectInput,
    dateFilter
  }
}
</script>
