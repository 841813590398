<template>
  <div class="modal fade show cash-form">
    <div class="modal-dialog modal-dialog-centered big-model">
      <div class="modal-content">
        <div class="modal-card card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t('Order details') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row" v-if="options.change_customer && item.order_type != 2">
              <customerSearchInput class="col-6" :item="item" @updateItem="updateItem" />
              <customerPhoneInput class="col-6" :item="item" @updateItem="updateItem" />
            </div>
            <hr
              v-if="
                (options.pay_methods_allow || options.show_rest || options.rest_allow) &&
                options.change_customer &&
                item.order_type != 2
              "
            />
            <div
              class="row"
              v-if="options.pay_methods_allow || options.show_rest || options.rest_allow"
            >
              <div class="col-md-12 row m-2 mb-4">
                <button
                  :class="
                    'btn border ' +
                    (item.payment_type == 'cash' ? 'btn-primary' : 'btn-light') +
                    ' col-2 m-2'
                  "
                  @click="() => changePaymentType('cash')"
                >
                  {{ $t('Cash') }}
                </button>

                <button
                  v-if="options.pay_methods_allow"
                  v-for="(paymentMethod, index) in options.payment_methods"
                  :class="
                    'btn border ' +
                    (item.payment_type == 'method' && item.payment_method == paymentMethod.safe_id
                      ? 'btn-primary'
                      : 'btn-light') +
                    ' col-2 m-2'
                  "
                  @click="() => changePaymentType('method', paymentMethod.safe_id)"
                >
                  {{ paymentMethod.name }}
                </button>
                <button
                  v-if="options.pay_methods_allow"
                  @click="() => changePaymentType('multi')"
                  :class="
                    'btn border ' +
                    (item.payment_type == 'multi' ? 'btn-primary' : 'btn-light') +
                    ' col-2 m-2'
                  "
                  value="multi"
                >
                  {{ $t('Multi Payment methods') }}
                </button>
                <button
                  v-if="options.rest_allow"
                  @click="() => changePaymentType('deferred')"
                  :class="
                    'btn border ' +
                    (item.payment_type == 'deferred' ? 'btn-primary' : 'btn-light') +
                    ' col-2 m-2'
                  "
                  value="deferred"
                >
                  {{ $t('Deferred') }}
                </button>
              </div>
              <hr v-if="options.pay_methods_allow || options.show_rest" />
              <template v-if="item.payment_type == 'cash'">
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-3"> {{ $t('Total') }}</label>
                    <div class="col-md-7 add-input">
                      <input type="number" disabled class="form-control" :value="item.total" />
                      <span>{{ $option.currency }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-3"> {{ $t('cash') }}</label>
                    <div class="col-md-7 add-input">
                      <input type="number" class="form-control" v-model="cash" id="cashInput" />
                      <span>{{ $option.currency }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-3"> {{ $t('Rest') }}</label>
                    <div class="col-md-7 add-input">
                      <input
                        type="number"
                        disabled
                        class="form-control"
                        :value="item.total - item.cash"
                      />
                      <span>{{ $option.currency }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <div class="col-12" v-if="item.payment_type == 'multi'">
                <div class="row invoice-table-header">
                  <div class="col-xl-6">
                    {{ $t('payment Method') }}
                  </div>
                  <div class="col-xl-6">
                    {{ $t('Amount') }}
                  </div>
                </div>
                <div class="row pt-2 pb-2 border-top" v-for="payment in payments">
                  <div class="col-6">
                    <div class="add-input">
                      <input v-model="payment.name" disabled class="form-control" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="add-input">
                      <input
                        type="number"
                        class="form-control w-100"
                        :placeholder="$t('Amount')"
                        v-model="payment.amount"
                      />
                      <span>{{ $option.currency }}</span>
                    </div>
                  </div>
                </div>
                <div class="row p-3 border-top">
                  <div class="col-6 pr-3">
                    <strong>{{ $t('Total') }}</strong>
                  </div>
                  <div class="col-6">
                    <strong>{{ $moneyFormat(item.total) }}</strong>
                  </div>
                </div>
                <div class="row p-3 border-top">
                  <div class="col-6 pr-3">
                    <strong>{{ $t('Paid') }}</strong>
                  </div>
                  <div class="col-6">
                    <strong>{{ $moneyFormat(paidCalculation()) }}</strong>
                  </div>
                </div>
                <div class="row p-3 border-top">
                  <div class="col-6 pr-3">
                    <strong>{{ $t('Rest') }}</strong>
                  </div>
                  <div class="col-6 p-2">
                    <strong>{{ $moneyFormat(item.total - paidCalculation()) }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <button class="btn btn-outline-danger" @click="$emit('closeCash')">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
                <button @click="checkOut()" class="btn btn-dark">
                  <i class="far fa-save"></i>
                  {{ $t('Pay') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import customerSearchInput from '../inputs/customerSearchInput'
import customerPhoneInput from '../inputs/customerPhoneInput'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'

// Use toast
const toast = createToastInterface()

// Focus on cash input
onMounted(() => document.getElementById('cashInput')?.focus())

// Checkout logic
const checkOut = () => {
  let fail = false
  if (props.item.payment_type.value == 'multi') {
    const rest = props.item.total - paidCalculation()
    if (rest > 0.1 || rest < -0.1) {
      fail = true
      toast.error($t('There is amount rest from payments'), {
        position: 'top-center'
      })
    }
  }
  if (!fail) {
    emits('checkOut')
    emits('closeCash')
  }
}

const updateItem = (newItem) => emits('updateItem', newItem)

const cash = computed({
  get: () => props.item.cash,
  set: (value) => emits('updateItem', { ...props.item, cash: value })
})

const payments = computed({
  get: () => props.item.payments,
  set: (value) => emits('updateItem', { ...props.item, payments: value })
})

const paidCalculation = () =>
  payments.value.reduce(
    (total, method) => (isNaN(parseFloat(method.amount)) ? 0 : parseFloat(method.amount)) + total,
    0
  )
const changePaymentType = (paymentType, paymentMethod = null) => {
  emits('updateItem', { ...props.item, payment_type: paymentType, payment_method: paymentMethod })
}

// Define props
const props = defineProps(['options', 'item'])
const emits = defineEmits(['checkOut', 'closeCash', 'updateItem'])
</script>
