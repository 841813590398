<template>
  <div class="col-12">
    <div class="d-xl-block d-none">
      <div class="row invoice-table-header">
        <div class="col-xl-3">
          <div class="row">
            <div class="col-xl-9">
              {{ $t('ProductItem') }}
            </div>
            <div class="col-xl-3">
              {{ $t('Unit') }}
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="row">
            <div :class="$option.count_allow ? 'col-xl-3' : 'col-xl-6'">
              {{ $t('Quantity') }}
            </div>
            <div class="col-xl-3" v-if="$option.count_allow">
              {{ $t('The number') }}
            </div>
            <div class="col-xl-6">
              {{ $t('Price') }}
            </div>
          </div>
        </div>
        <div
          class="col-xl-2"
          v-if="$option.purchase_returns_required_data.find((el) => el.id == 4)"
        >
          {{ $t('Tax and discount') }}
        </div>
        <div class="col-xl-3">
          <div class="row">
            <div class="col-xl-3" v-html="$t('Subtotal')"></div>
            <div class="col-xl-3">
              {{ $t('The average') }}
            </div>
            <div class="col-xl-3">
              {{ $t('Total') }}
            </div>
            <div class="col-xl-3"></div>
          </div>
        </div>
      </div>
    </div>
    <quotationItem
      v-for="(rowItem, index) in $parent.item.quotationItems"
      :key="index"
      :index="index"
      :item="rowItem"
    ></quotationItem>
    <button class="btn btn-success w-100 mb-4" @click="$parent.addQuotationItem()">
      <i class="fas fa-plus"></i> {{ $t('Addition') }}
    </button>
  </div>
</template>
<script>
import quotationItem from './quotationItem.vue'
export default {
  data() {
    return {
      items: [],
      filteredItems: []
    }
  },
  methods: {
    updateQuantities() {
      this.filteredItems = []
      this.items.forEach((item) => {
        if (item.track_quantity == 0) {
          this.filteredItems.push(item)
        } else {
          var productQuantity = this.$database.productQuantities.find(
            (el) => el.product_id == item.id && el.store_id == this.$parent.item.store_id
          )
          if (productQuantity) {
            if (productQuantity.quantity > 0) {
              item.quantity = productQuantity.quantity
              this.filteredItems.push(item)
            }
          } else if (this.$option.allow_minus_stores) {
            item.quantity = 0
            this.filteredItems.push(item)
          }
        }
      })
    }
  },
  mounted() {
    this.items = this.$database.products.concat(this.$database.materials)
  },
  watch: {
    '$parent.item.store_id': function () {
      this.updateQuantities()
    },
    '$database.productQuantities': function () {
      this.updateQuantities()
    }
  },
  components: {
    quotationItem
  }
}
</script>
