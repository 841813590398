<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('reports')" :description="$t('customer Account Details')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Report options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('customer')"
                :plaseholder="$t('choose Customer')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.customer_id"
                :value="params.customer_id"
                :values="customers"
              />
              <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div>
              <dateFilter class="col-md-3" />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('customer Account Details')"
            :details="[
              {
                title: $t('customer Name'),
                value: customerName
              }
            ]"
          />
          <reportTable
            :header="[
              { colspan: 8, value: $t('opening Balance') },
              {
                colspan: 1,
                value: startBalance
              }
            ]"
            :emptyTableText="$t('there No Data The Moment')"
            :emptyTableSubText="$t('try Adding Some And Try Again')"
            :cloumns="[
              {
                column: 'code',
                title: $t('code'),
                type: 'reportCode'
              },

              {
                column: 'date',
                title: $t('Date'),
                type: 'text'
              },
              {
                column: 'type',
                title: $t('type'),
                type: 'reportType'
              },
              {
                column: 'statement',
                title: $t('statement'),
                type: 'statement'
              },
              {
                column: 'quantity',
                title: $t('Quantity'),
                type: 'textOrEmpty'
              },
              {
                column: 'price',
                title: $t('Price'),
                type: 'textOrEmpty'
              },
              {
                column: 'debit',
                title: $t('debtor'),
                type: 'textOrEmpty'
              },
              {
                column: 'creditor',
                title: $t('Creditor'),
                type: 'textOrEmpty'
              },
              {
                column: 'new_balance',
                type: 'text',
                title: $t('Balance')
              }
            ]"
            :footer="[
              { colspan: 6, value: $t('the Final Balance') },
              { colspan: 1, value: debit },
              { colspan: 1, value: creditor },
              {
                colspan: 1,
                value: endBalance
              }
            ]"
            :tafqeet="tafqeet"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/customers/detailsAccounts',
      items: [],
      branchArray: [],
      loading: 0,

      startBalance: 0,
      endBalance: 0,

      debit: 0,
      creditor: 0,

      tafqeet: '',

      customers: [],

      params: {
        customer_id: '',
        store_id: '',
        user_id: '',
        point_of_sale_id: '',
        dateFilter: '',
        startDate: '',
        endDate: '',
        branch_id: ''
      },
      excel: {
        fileName: 'customer details acount',
        reportData: '[]'
      },

      customerName: '',
      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
        customer.opening_balance = parseFloat(customer.opening_balance)
        var startBalance = customer.opening_balance
        this.debit = 0
        this.creditor = 0

        if (customer.opening_balance < 0) {
          this.creditor += customer.opening_balance * -1
        } else if (customer.opening_balance > 0) {
          this.debit += customer.opening_balance
        }

        this.items.forEach((item) => {
          item.old_balance = this.$moneyFormat(startBalance)
          if (item.debit) {
            startBalance = startBalance + item.debit
            this.debit += item.debit
            item.debit = this.$moneyFormat(item.debit)
          }
          if (item.creditor) {
            startBalance = startBalance - item.creditor
            this.creditor += item.creditor
            item.creditor = this.$moneyFormat(item.creditor)
          }
          item.new_balance = this.$moneyFormat(startBalance)
        })

        const beforeItem = this.items
          .filter((el) => el.date < Date.parse(this.params.startDate) / 1000 - 7200)
          .slice(-1)[0]
        if (this.params.startDate != null) {
          var startDate = Date.parse(this.params.startDate) / 1000 - 7200
          this.items = this.items.filter((el) => el.date >= startDate)
        }
        if (this.params.endDate != null) {
          var endDate = Date.parse(this.params.endDate) / 1000 + 79200
          this.items = this.items.filter((el) => el.date <= endDate)
        }

        this.items.forEach((item) => {
          item.date = this.$dateFormat(item.date)
        })

        this.startBalance = this.items[0]
          ? this.items[0].old_balance
          : beforeItem
            ? beforeItem.new_balance
            : startBalance
        this.endBalance = this.items.length
          ? this.items[this.items.length - 1].new_balance
          : this.startBalance
        this.debit = this.$moneyFormat(this.debit)
        this.creditor = this.$moneyFormat(this.creditor)

        let endBalance = this.endBalance.toString().replace(/,/g, '')
        this.tafqeet =
          ' الحساب ' +
          (endBalance < 0 ? 'مدين ب' : 'دائن ب') +
          this.$tafqeet(this.endBalance.toString().replace(/,/g, '').replace(/-/g, ''))

        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        var customer = this.customers.find((el) => el.id == this.params.customer_id)
        if (customer) {
          this.customerName = customer.name
        } else {
          this.customerName = ''
        }

        var supplier = this.$database.suppliers.find((el) => el.id == this.params.supplier_id)
        if (supplier) {
          this.supplierName = supplier.name
        } else {
          this.supplierName = ''
        }

        this.loading = 0
      })
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.$updateDatabase(['customers'])
    this.customers = this.$database.customers.filter((el) => el.status == 1)
    if (this.params.customer_id > 0) {
      this.getData()
    }
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
