import { ref, watch } from 'vue'
import Vue from 'vue'
import axios from 'axios'
import { createToastInterface } from 'vue-toastification'
import { getParams, setParams } from '@/helpers/params'
import linkGenerator from './linkGenerator'
import initItems from '@/helpers/initItems'
import { $t } from './translate'

const toast = createToastInterface()

const initParams = {
  perPage: 15,
  search: '',
  page: 1,
  orderBy: 'id',
  orderType: 'desc'
}

const methods = (data) => {
  const { path, localData, cache = data.cache ?? true, filter = data.filter ?? {} } = data

  const items = ref([])
  const deleteId = ref(null)
  const totalPages = ref(0)
  const loading = ref(false)
  const error = ref(false)
  const itemsCount = ref(0)
  const firstRender = ref(true)

  const referenceId = (Math.random() + 1).toString(36).substring(7)

  const params = ref(initParams)

  const getItems = async () => {
    loading.value = true
    totalPages.value = 0
    items.value = []

    if (cache) setParams(params.value)
    // try {
    if (localData) {
      // get items logic
      let databaseItems = JSON.parse(JSON.stringify(Vue.prototype.$database[path]))
      let localItems = JSON.parse(JSON.stringify(Vue.prototype.$localDatabase[path]))
      databaseItems = localItems.concat(databaseItems)

      // sort logic
      if (params.value.orderBy) {
        // To do
        const sentData = {
          orderBy: params.value.orderBy,
          orderType: params.value.orderType == 'desc' ? 'asc' : 'desc'
        }
        databaseItems = databaseItems.sort(Vue.prototype.$dynamicSort(sentData))
      }
      // search logic
      let searchReg = new RegExp(params.value.search, 'i')
      databaseItems = databaseItems.filter(
        (el) =>
          searchReg.test(el.name) ||
          searchReg.test(el.mobile) ||
          searchReg.test(el.balance) ||
          searchReg.test(el.price) ||
          searchReg.test(el.barcode) ||
          searchReg.test(el.code) ||
          searchReg.test(el.cost) ||
          searchReg.test(el.tax_number)
      )
      //filter logic
      if (filter && typeof filter === 'object') {
        Object.entries(filter).forEach(([key, value]) => {
          databaseItems = databaseItems.filter((el) => el[key] == value)
        })
      }

      // const accounts = ['customers', 'suppliers', 'accounts', 'partners']
      // if (this.$option.balance_type == 1 && accounts.includes(table)) {
      //   this.items.forEach((el) => {
      //     el.balance = el.balance * -1
      //   })
      // }

      let count = params.value.perPage ?? 15
      let page = params.value.page ?? 1

      totalPages.value = Math.ceil(databaseItems.length / count)
      databaseItems = databaseItems.slice(page * count - count, page * count)
      items.value = initItems(databaseItems)
    } else {
      if (localStorage.getItem(path + 'Cache') !== null && cache && firstRender.value) {
        firstRender.value = false
        loading.value = 0
        const data = JSON.parse(localStorage.getItem(path + 'Cache'))
        // Prepare items
        let cachedItems = data.data
        let localItems = JSON.parse(JSON.stringify(Vue.prototype.$localDatabase[path]))
        cachedItems = localItems.concat(cachedItems)
        // Add values
        totalPages.value = data.last_page
        params.value.page = data.current_page
        items.value = initItems(cachedItems)
      }
      // Get data
      const response = await axios.get(linkGenerator('/' + path), { params: params.value })
      // Prepare items
      let ServerItems = response.data.data
      if (cache) {
        let localItems = JSON.parse(JSON.stringify(Vue.prototype.$localDatabase[path]))
        ServerItems = localItems.concat(ServerItems)
      }
      // Add values
      totalPages.value = response.data.last_page
      params.value.page = response.data.current_page
      items.value = initItems(ServerItems)
      // Cache result
      localStorage[path + 'Cache'] = JSON.stringify(response.data)
    }
    // } catch (er) {
    //   error.value = true
    // } finally {
    loading.value = false
    // }
  }

  const toggleOptions = (id, event) => {
    const element = document.getElementById(referenceId)
    const mirrorItems = items.value
    let item = mirrorItems[id]
    item.showOptions = !item.showOptions
    item.top = event.target.getBoundingClientRect().top - element.getBoundingClientRect().top + 20
    item.left = event.target.left
    mirrorItems.map((el, index) => {
      if (index != id) el.showOptions = 0
    })
    mirrorItems[id] = item
    items.value = []
    items.value = mirrorItems
  }

  const toggleSetting = (id) => {
    const mirrorItems = items.value
    let item = mirrorItems[id]
    item.ShowSitting = !item.ShowSitting
    mirrorItems.map((el, index) => {
      if (index != id) el.ShowSitting = 0
    })
    mirrorItems[id] = item
    items.value = []
    items.value = mirrorItems
  }

  const sureDelete = (id) => (deleteId.value = id)

  const deleteItem = async () => {
    if (!items.value[deleteId.value].ofline) {
      try {
        const id = items.value[deleteId.value].id
        await axios.post(linkGenerator(`/${path}/${id}`), { _method: 'DELETE' })
        toast.success($t('Item deleted successfully'), {
          position: 'top-center'
        })
      } catch (error) {
        toast.error($t('Error in delete item'), {
          position: 'top-center'
        })
      }
      deleteId.value = null
      if (localData) Vue.prototype.$updateDatabase([path])
      else getItems()
    } else {
      Vue.prototype.$removeFromLocalDatabase(items.value[deleteId.value], path)
      deleteId.value = null
    }
  }

  const closeDelete = () => {
    deleteId.value = null
  }

  const changePage = (page) => {
    params.value.page = page
    getItems()
  }

  const changePerPage = (items) => {
    if (itemsCount.value < items) {
      params.value.page = 1
      params.value.perPage = items
    } else {
      params.value.perPage = items
    }
    getItems()
  }

  const changeSearch = (search) => {
    params.value.search = search
    params.value.page = 1
    getItems()
  }

  const sortItems = (name) => {
    // get first page
    params.value.page = 1
    // if order name is same
    params.value.orderType =
      params.value.orderType == 'asc' && params.value.orderBy == name ? 'desc' : 'asc'
    // set order by
    params.value.orderBy = name
    // get items again
    getItems()
  }

  const filterItems = (column, value) => {
    if (value !== undefined) {
      params.value[column] = value
    } else {
      delete params.value[column]
    }
    getItems()
  }

  const resetFilter = () => {
    params.value = initParams
    console.log(params.value)
    getItems()
  }

  params.value = { ...params.value, ...filter }
  getItems()
  if (localData) Vue.prototype.$updateDatabase([path])
  //if (cache) params.value = getParams()

  watch(
    () => Vue.prototype.$database[path],
    () => {
      if (localData) getItems()
    },
    { deep: true }
  )

  watch(
    () => Vue.prototype.$localDatabase[path],
    () => {
      if (localData) Vue.prototype.$updateDatabase([path])
      getItems()
    },
    { deep: true }
  )

  return {
    getItems,
    sureDelete,
    deleteItem,
    closeDelete,
    changePage,
    changePerPage,
    changeSearch,
    toggleOptions,
    toggleSetting,
    sortItems,
    filterItems,
    resetFilter,
    referenceId,
    params,
    items,
    deleteId,
    totalPages,
    loading,
    error
  }
}

export default methods
