<template>
  <nav class="navbar navbar-expand-md nav-padding navbar-light d-md-flex">
    <div class="container-fluid">
      <div class="w-50 d-none d-md-block"></div>
      <span class="v-dvider ml25 d-none d-md-block"></span>
      <div class="w-50 d-none d-md-block"></div>
      <!-- User -->
      <div class="dropdown">
        <a
          class="navbar-user-link nav-icon dropdown-toggle w-100 pointer-cursor"
          @click="toggleCompaniesMenu"
        >
          <span class="icon"> <i class="far fa-city"></i> {{ $option.name }} </span>
        </a>
        <ul
          class="dropdown-menu"
          :class="showCompanies ? 'show' : ''"
          v-if="$companies.length != 1"
        >
          <li v-for="item in $companies" :key="item.id">
            <a class="dropdown-item" @click="$changeCompany(item.id)">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showCompanies: false
    }
  },
  methods: {
    toggleCompaniesMenu() {
      this.showCompanies = !this.showCompanies
    }
  }
}
</script>
