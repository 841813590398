const linkGenerator = (link, file, params) => {
  var pramsText = ''
  if (typeof params === 'object') {
    var str = []
    for (var p in params) {
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
      }
    }
    pramsText = str.join('&')
  }

  return file
    ? localStorage.getItem('site') + link + '?' + pramsText
    : localStorage.getItem('site') + '/api' + link + '?' + pramsText
}

export default linkGenerator
