<template>
  <Layout>
    <!-- page header component -->
    <PageHeader
      :title="$t('Create product')"
      :description="$t('From here you can create a new product')"
      v-if="$route.name == 'productsCreate'"
    />
    <PageHeader
      :title="$t('Edit product')"
      :description="$t('From here you can modify the product data')"
      v-if="$route.name == 'productsEdit'"
    />
    <!-- Form component-->
    <Form @submit="onSubmit" :loading="loading" :params="params">
      <div class="row">
        <div class="col-12">
          <Divider
            :title="$t('Basic information')"
            :description="$t('Safes basic information')"
            :noLine="true"
          />
        </div>
        <div class="col-md-6">
          <Input
            type="text"
            :title="$t('Product name')"
            v-model:value="item.name"
            :errors="getInputError('name')"
          />
        </div>
        <div class="col-md-6">
          <SearchInput
            :title="$t('Product classification')"
            :options="$database.sections"
            :disabled="!$checkRole('sales_payments_edit_item')"
            v-model:value="item.section_id"
            :errors="getInputError('invoice_group')"
          />
        </div>
        <div class="col-md-6">
          <!-- Input -->
          <Input
            type="text"
            :title="$t('Code')"
            v-model:value="item.code"
            :errors="getInputError('code')"
          />
        </div>
        <div class="form-group col-md-6">
          <!-- Input -->
          <Input
            type="text"
            :title="$t('Barcode')"
            v-model:value="item.barcode"
            :errors="getInputError('barcode')"
          />
        </div>
        <div class="col-md-6">
          <SearchInput
            :title="$t('Supplier')"
            :options="$database.suppliers"
            :disabled="!$checkRole('sales_payments_edit_item')"
            v-model:value="item.supplier_id"
            :errors="getInputError('supplier_id')"
          />
        </div>
        <div class="form-group col-md-6">
          <!-- Input -->
          <Input
            type="text"
            :title="$t('Barcode description')"
            v-model:value="item.barcode_description"
            :errors="getInputError('barcode_description')"
          />
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-2">{{ $t('Product description') }}</label>
          <div class="col-md-95">
            <ckeditor :editor="editor" v-model="item.description" :config="editorConfig"></ckeditor>
          </div>
        </div>
      </div>
      <Divider
        :title="$t('Accounts information')"
        :description="$t('Product account information')"
      />
      <div class="row">
        <div class="form-group col-md-6">
          <SearchInput
            :title="$t('Tax rate')"
            :options="$database.taxes"
            :disabled="!$checkRole('sales_payments_edit_item')"
            v-model:value="item.tax_id"
            :errors="getInputError('tax_id')"
          />
        </div>
        <div class="form-group col-md-6">
          <SearchInput
            :title="$t('Discount rate')"
            :options="$database.discounts"
            :disabled="!$checkRole('sales_payments_edit_item')"
            v-model:value="item.discount_id"
            :errors="getInputError('discount_id')"
          />
        </div>
        <div class="form-group col-md-6">
          <Input
            :title="$t('sellingPrice')"
            type="number"
            placeholder="0"
            v-model:value="item.price"
            :errors="getInputError('price')"
          />
        </div>
        <div class="form-group col-md-6">
          <Input
            :title="$t('purchasingPrice')"
            type="number"
            placeholder="0"
            v-model:value="item.cost"
            :errors="getInputError('cost')"
          />
        </div>
        <div class="col-md-12 d-lg-block d-none">
          <TableInput
            :columns="[
              { title: $t('PriceLists') },
              { title: $t('Product Details') },

              { title: $t('Unit') },
              { title: $t('sellingPrice') },
              { title: $t('purchasingPrice') }
            ]"
            @addItem="openCreateItem"
          >
            <tr v-if="item.quotationItems.length == 0">
              <td colspan="11">
                <i
                  class="fa fa-archive text-warning mb-2"
                  aria-hidden="true"
                  style="font-size: 50px"
                ></i>
                <h2 class="text-center text-muted mt-4" style="font-size: 70px">
                  ! {{ $t('There are no items yet') }}
                </h2>
              </td>
            </tr>
            <tr v-for="(item, index) in item.quotationItems">
              {{
                item
              }}
              <!-- <td>{{ getProductName(item) }}</td> -->

              <!-- <td>{{ item.quantity }} {{ unit(item) }}</td> -->
              <!-- <td>{{ $moneyFormat(item.price) }}</td> -->

              <!-- total=taxrate/100*(qutantity*price)+(quantity*price)-discount -->

              <td>
                <button class="btn btn-danger" @click="deleteItem(index)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </TableInput>
        </div>
        <div class="d-block d-lg-none w-100">
          <div
            v-if="item.quotationItems.length == 0"
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <div class="bg-dark w-100 p-2 text-white text-center">
              <p>{{ $t('Products') }}</p>
            </div>
            <i
              class="fa fa-archive text-warning mb-2 mt-2"
              aria-hidden="true"
              style="font-size: 50px"
            ></i>
            <p class="text-center text-muted mt-4" style="font-size: 20px">
              ! {{ $t('There are no items yet') }}
            </p>
          </div>
          <!-- <QuotationResponsive
            v-for="(item, index) in item.quotationItems"
            :key="index"
            :item="item"
            :index="index"
            :price="item.price"
            :quantity="item.quantity"
            :discountValue="item.discount_value"
            :productName="productName(item)"
            :unit="unit(item)"
            :taxName="taxName(item)"
            :taxRate="taxRate(item)"
            :discountType="item.discount_type"
            :discountRate="discountRate(item)"
            :discountName="discountName(item)"
            :discountAmount="getDiscountRate(item)"
            :total="getTotal(item, $database)"
            :subtotal="getSubtotal(item, $database)"
            :average="getAverage(item, $database)"
            @deleteItem="deleteItem(index)"
          /> -->

          <button class="btn btn-success w-100 mb-4 mt-4" @click="openCreateItem">
            <i class="fas fa-plus"></i>
            {{ $t('Add') }}
          </button>
        </div>

        <!-- <pricesTable class="col-12" /> -->
      </div>
      <Divider :title="$t('Store settings')" :description="$t('Settings for stores')" />
      <div class="row">
        <div class="form-group col-md-6">
          <SearchInput
            :title="$t('Basic unit')"
            :options="$database.units"
            v-model:value="item.unit_id"
            :errors="getInputError('unit_id')"
          />
        </div>

        <unitsTable class="col-12" />
      </div>

      <Divider
        :title="$t('Warehouse quantities')"
        :description="$t('Quantities Of Products available in Stores')"
      />
      <div class="col-md-6">
        <Checkbox
          :title="$t('Track inventory')"
          v-model:value="item.track_quantity"
          :errors="getInputError('track_quantity')"
        />
      </div>
      <Divider :title="$t('Manufacturing settings')" :description="$t('Manufacturing numbers')" />
      <div class="col-md-6">
        <Checkbox
          :title="$t('Factory product')"
          v-model:value="item.factory_product"
          :errors="getInputError('factory_product')"
        />
      </div>
      <CreateItemForm
        v-if="showCreateItem"
        @close="closeCreateItem"
        @addItem="addItem"
        :filteredItems="filteredItems"
      />
    </Form>
  </Layout>
</template>
<script setup>
import { ref, watch } from 'vue'
// layout component
import Layout from '@/layouts/main.vue'
// page components
import PageHeader from '@/components/pageHeader'
import Divider from '@/components/divider'
// form components
import Form from '@/components/form/index'
import Input from '@/components/form/inputs/input'
import SearchInput from '@/components/form/inputs/searchInput.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import pricesTable from './components/pricesTable.vue'
import unitsTable from './components/unitsTable.vue'

import { VueEditor } from 'vue2-editor'
import Select from '@/components/form/inputs/select.vue'
import Checkbox from '@/components/form/inputs/checkbox'
import FileInput from '@/components/form/inputs/fileInput'
import TableInput from '@/components/form/table/index.vue'
import ShowTable from '@/components/form/table/show.vue'
import TableResponsive from '@/components/form/table/indexres.vue'
import Textarea from '@/components/form/inputs/textarea'
import QuotationResponsive from '@/components/form/table/quotationRes'
import CreateItemForm from './components/createItemForm.vue'
// helpers functions
import formHock from '@/helpers/formHock'
const editor = ClassicEditor

const editorConfig = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'insertTable',
    '|',
    'imageUpload',
    'mediaEmbed',
    '|',
    'undo',
    'redo'
  ],
  table: {
    toolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  extraPlugins: [
    function uploader(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
        return new UploadAdapter(loader)
      }
    }
  ],
  language: 'ar'
}
// form validations
import { required, minLength, maxLength, numeric, helpers } from '@vuelidate/validators'

// Form item
const formSetup = {
  name: '',
  balance_type: 0,
  balance: 0,
  opening_balance: 0,
  opening_debit: null,
  opening_credit: null,
  exchange_rate: '',
  max_balance: 0,
  address1: '',
  address2: '',
  entity: '',
  city: '',
  zip: '',
  status: 1,
  file: '',
  state: '',
  quotationItems: []
}

const filteredItems = ref([])
const gettingFilteredItems = () => {
  filteredItems.value = []
  const productQuantities = Database.productQuantities.filter(
    (el) => el.store_id == item.value.store_id
  )
}
// Validations
const rules = {
  name: {
    required: helpers.withMessage('Name is required', required),
    minLength: helpers.withMessage('Name must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Name must be smaller than or equal 121', maxLength(121))
  },
  address1: {
    minLength: helpers.withMessage('Address1 must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Address1 must be smaller than or equal 121', maxLength(125))
  },
  address2: {
    minLength: helpers.withMessage('Address2 must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Address2 must be smaller than or equal 121', maxLength(125))
  },
  entity: {
    minLength: helpers.withMessage('Entity must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Entity must be smaller than or equal 121', maxLength(125))
  },
  city: {
    minLength: helpers.withMessage('City must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('City must be smaller than or equal 121', maxLength(125))
  },
  zip: {
    minLength: helpers.withMessage('Zip must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('Zip must be smaller than or equal 121', maxLength(125))
  },
  state: {
    minLength: helpers.withMessage('State must be bigger than or equal 3', minLength(3)),
    maxLength: helpers.withMessage('State must be smaller than or equal 121', maxLength(125))
  }
}

// Params to show edit and add route
const params = {
  add: {
    routeName: 'safesCreate'
  },
  edit: {
    routeName: 'safesEdit'
  }
}

// Import from form hock
const { item, saveItem, loading, getInputError } = formHock({
  path: 'safes',
  localData: true,
  formSetup: formSetup,
  rules: rules
})
const showCreateItem = ref(false)
const openCreateItem = () => (showCreateItem.value = true)
const closeCreateItem = () => (showCreateItem.value = false)
const deleteItem = (id) => item.value.quotationItems.splice(id, 1)
const addItem = (newItem) => {
  item.value.quotationItems.push(newItem)
  closeCreateItem()
}

const onSubmit = async () => {
  const response = await saveItem()
  if (response.status) {
    window.history.back()
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

// Watch balance
watch(
  () => item,
  () => {
    if (item.value.balance_type == 1) {
      item.value.opening_balance =
        (item.value.opening_debit ?? 0) - (item.value.opening_credit ?? 0)
    }
  },
  { deep: true }
)
</script>
