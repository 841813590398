import axios from 'axios'
import linkGenerator from '@/helpers/linkGenerator'
const printingFunction = async (id, link) => {
  try {
    if (id) {
      const response = await axios.get(linkGenerator(link + id))
      return response.data
    } else {
      const response = await axios.get(linkGenerator(link))
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}
export default printingFunction
