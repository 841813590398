<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Unloading items to the customer')" />

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Customer name')"
                :plaseholder="$t('Choose customer')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.customer_id"
                :value="params.customer_id"
                :values="$database.customers"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose customer')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.user_id"
                :value="params.user_id"
                :values="$database.users"
              />
              <!-- <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div> -->
              <searchInput
                class="col-md-3"
                :title="$t('Store')"
                :plaseholder="$t('Choose store')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Product')"
                :plaseholder="$t('Choose product')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.product_id"
                :value="params.product_id"
                :values="$database.products"
              />
              <searchInput
                class="col-md-3"
                title=" تصنيف المنتجات "
                plaseholder="اختر تصنيف"
                :show="'name'"
                :refrance="'id'"
                v-model="params.section_id"
                :value="params.section_id"
                :values="$database.sections"
              />
              <dateFilter class="col-md-3" />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Unloading items to the customer')"
            :details="[
              { title: $t('Customer name'), value: customerName },
              { title: $t('User name'), value: userName },
              { title: $t('Store'), value: storeName },
              { title: $t('Product'), value: productName },
              { title: $t('Category'), value: sectionName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'product_name',
                link: '/products',
                title: $t('Product name'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'price',
                title: $t('Price'),
                type: 'text',
                sort: true
              },
              { column: 'quantity', title: $t('Quantity'), type: 'text', sort: true },

              {
                column: 'total',
                title: $t('Total'),
                type: 'text',
                sort: true
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('Total') },
              { colspan: 1, value: '' },
              { colspan: 1, value: quantity },
              { colspan: 1, value: total }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
import reportHeader from '@/elements/reports/reportHeader.vue'

export default {
  data() {
    return {
      path: '/reports/sales/customerSalesPrices',
      items: [],
      branchArray: [],
      loading: 0,

      quantity: 0,
      total: 0,

      params: {
        customer_id: '',
        store_id: '',
        user_id: '',
        branch_id: '',
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'sales by item',
        reportData: '[]'
      },

      storeName: '',
      customerName: '',
      userName: '',
      productName: '',
      sectionName: '',

      searchStartDate: '',
      searchEndDate: '',

      selectedSections: []
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.quantity = 0
        this.total = 0

        this.items.forEach((item) => {
          this.quantity += item.quantity
          this.total += item.total
          item.price = this.$moneyFormat(item.price)
          item.total = this.$moneyFormat(item.total)
        })
        this.total = this.$moneyFormat(this.total)
        this.loading = 0

        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
        if (customer) {
          this.customerName = customer.name
        } else {
          this.customerName = ''
        }

        var store = this.$database.stores.find((el) => el.id == this.params.store_id)
        if (store) {
          this.storeName = store.name
        } else {
          this.storeName = ''
        }

        var user = this.$database.users.find((el) => el.id == this.params.user_id)
        if (user) {
          this.userName = user.name
        } else {
          this.userName = ''
        }
        var product = this.$database.products.find((el) => el.id == this.params.product_id)
        if (product) {
          this.productName = product.name
        } else {
          this.productName = ''
        }

        var sectionsName = []

        var sections = this.params.section_id ? this.params.section_id.split(',') : []
        sections.forEach((el) => {
          var section = this.$database.sections.find((item) => item.id == el)
          if (section) {
            sectionsName.push(section.name)
          }
        })
      })
    },
    getItems() {
      this.$setParams(this.params)
      if (this.params.orderBy) {
        this.items.sort(this.$dynamicSort(this.params))
      }
    }
  },
  mounted() {
    this.params = this.$getParams()
    var sections = this.params.section_id ? this.params.section_id.split(',') : []
    sections.forEach((el) => {
      var item = this.$database.sections.find((item) => item.id == el)
      this.selectedSections.push(item)
    })
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
    this.$updateDatabase(['users'])
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  components: {
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons,
    reportHeader
  }
}
</script>
