<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Safes deposit register')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('user')"
                :plaseholder="$t('Choose user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <!-- <div class="col-md-3">
                <label class="col-md-12">{{ $t('Branches') }}</label>
                <multiselect
                  v-model="branchArray"
                  :options="$database.branches"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('Choose the branch')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                />
              </div> -->
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Status type') }}</label>

                  <select v-model="params.status" type="text" class="form-control">
                    <option value="">{{ $t('situation') }}</option>
                    <option value="">{{ $t('all') }}</option>
                    <option value="0">{{ $t('draft') }}</option>
                    <option value="1">{{ $t('approved') }}</option>
                  </select>
                </div>
              </div>
              <searchInput
                class="col-md-3"
                :title="$t('Safe')"
                :plaseholder="$t('Choose safe')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.safe_id"
                :value="params.safe_id"
                :values="[...$database.safes, ...$database.bankAccounts]"
              />
              <searchInput
                class="col-md-3"
                :title="$t('Cost center')"
                :plaseholder="$t('Choose a cost center')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.cost_center_id"
                :value="params.cost_center_id"
                :values="$database.costCenters"
              />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Safes deposit register')"
            :details="[
              { title: $t('Username'), value: userName },
              { title: ' الخزينة', value: safeName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              {
                column: 'code',
                link: '/safesDeposits',
                title: $t('Code'),
                type: 'reportMainLink',
                sort: true
              },
              { column: 'date', title: $t('Date'), type: 'text', sort: true },
              { column: 'user', title: $t('User'), type: 'text', sort: true },
              {
                column: 'safe_id',
                title: $t('Safe'),
                element: 'safe',
                type: 'link',
                to: 'safe',
                sort: true,
                link: true
              },
              { column: 'cost', title: $t('Price'), type: 'text', sort: true }
            ]"
            :footer="[
              { colspan: 4, value: $t('Total') },
              { colspan: 1, value: cost }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/safesOperations/safeDepositHistory',
      items: [],
      loading: 1,
      branchArray: [],

      cost: 0,

      params: {
        safe_id: '',
        user_id: '',
        startDate: '',
        endDate: '',
        branch_id: ''
      },

      excel: {
        fileName: 'safe deposits',
        reportData: '[]'
      },

      userName: '',
      safeName: '',
      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.totalPages = 0
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        this.cost = 0

        this.items.forEach((item) => {
          this.cost += item.cost
          item.cost = this.$moneyFormat(item.cost)
          item.date = this.$dateFormat(item.date)

          var user = this.$database.users.find((el) => el.id == item.user_id)
          if (user) {
            item.user = user.name
          }

          var safe = this.$database.safes.find((el) => el.id == item.safe_id)
          if (safe) {
            item.safe = safe
          }
        })
        this.cost = this.$moneyFormat(this.cost)
        this.loading = 0

        var safe = this.$database.safes.find((el) => el.id == this.params.safe_id)
        if (safe) {
          this.safeName = safe.name
        } else {
          this.safeName = ''
        }

        var user = this.$database.users.find((el) => el.id == this.params.user_id)
        if (user) {
          this.userName = user.name
        } else {
          this.userName = ''
        }
      })
    }
  },
  watch: {
    branchArray: function (val) {
      this.params.branch_id = val.map((el) => el.id)
    },
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.$updateDatabase(['safes', 'customers', 'users'])
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
