var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Manufacturing deposits by items')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Products'),"plaseholder":_vm.$t('Choose Product'),"show":'name',"refrance":'id',"value":_vm.params.product_id,"values":_vm.$database.products},model:{value:(_vm.params.product_id),callback:function ($$v) {_vm.$set(_vm.params, "product_id", $$v)},expression:"params.product_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Manufacturing deposits by items'),"details":[
            { title: 'اسم العميل', value: _vm.customerName },
            { title: 'اسم المستخدم', value: _vm.userName },
            { title: 'المخزن', value: _vm.storeName },
            { title: 'نقطة البيع', value: _vm.pointOfSaleName },
            { title: 'رقم الجلسة', value: _vm.sessionNumber },
            { title: 'المنتج', value: _vm.productName },
            { title: 'التصنيف', value: _vm.sectionName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'product_name',
              link: '/products',
              title: _vm.$t('Manufacturing Model'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'quantity',
              title: _vm.$t('Quantity'),
              type: 'quantity',
              sort: true
            },
            {
              column: 'price',
              title: _vm.$t('Price'),
              type: 'price',
              sort: true
            },
            {
              column: 'total',
              title: _vm.$t('total'),
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 2, value: 'المجموع' },
            { colspan: 1, value: _vm.quantity },
            { colspan: 1, value: _vm.total }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }