import { helpers } from '@vuelidate/validators'
import Vue from 'vue'

export const requiredIfCreate = helpers.withParams({ type: 'requiredIfCreate' }, (value) => {
  const url = window.location.href
  const segments = url.split('/')
  const route = segments[segments.length - 1]
  if (route !== 'edit') {
    return !!value
  }
  return true
})

export const requiredIfClientType = (type) => {
  return helpers.withParams({ type: 'requiredIfClientType', type }, function (value, parentVm) {
    // Check if the client_type matches the given type
    return parentVm.client_type != type || !!value
  })
}
export const MaxSafeBalance = (flag, column) => {
  return helpers.withParams({ type: 'MaxSafeBalance', flag }, function (value, parentVm) {
    const url = window.location.href
    const segments = url.split('/')
    const route = segments[segments.length - 1]
    // Check flag
    if (flag) return true
    // check edit
    if (route == 'edit') return true
    // Check if the client_type matches the given type
    const safesAndBankAccounts = [
      ...Vue.prototype.$database.safes,
      ...Vue.prototype.$database.bankAccounts
    ]
    const safe = safesAndBankAccounts.find((el) => el.id == parentVm[column])
    return !(parseFloat(safe.balance) < parseFloat(value))
  })
}
