<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <StoresWithdrawalComponent :item="item" />
      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $options.stores_withdrawals_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
      <p class="pioneer-data">تم الإنشاء بواسطة Pioneer للبرمجيات 01019654321</p>
    </div>
  </div>
</template>
<script setup>
import StoresWithdrawalComponent from './components/storesWithdrawal.vue'
const printItem = () => {
  // Prepare content
  const text = document.getElementById('printMe').innerHTML
  document.getElementById('printMe').remove()
  const myFrame = document.getElementById('inviceIframe')
  myFrame.contentDocument.body.innerHTML = text
  setTimeout(function () {
    myFrame.focus()
    myFrame.contentWindow.print()
  }, 100)

  emits('onFinish')
}

// Print item
setTimeout(() => printItem(), 40)

const props = defineProps(['item'])
const emits = defineEmits(['onFinish'])
</script>
