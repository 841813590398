<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 mt-3">
        <addHeader
          :title="$t('Create load operation')"
          :description="$t('From here you can create new load operation')"
          v-if="$route.name == 'storesOrdersCreate'"
        />
        <addHeader
          :title="$t('Edit load Operation')"
          :description="$t('From here you can edit load operation')"
          v-if="$route.name == 'storesOrdersEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('Basic information')"
            :description="$t('Main information')"
            noLine="true"
          />
          <connectionInput
            :title="$t('Operation number')"
            :group="$t('Groups')"
            v-model="item.invoice_group"
            :disabled="!$checkRole('stores_orders_edit_item')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'storesOrdersCreate'"
            :hasErorr="errors.invoice_group"
            :error="$t('Numbering is required')"
          />
          <formInput
            :title="$t('Operation number')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'storesOrdersEdit'"
            maxlength="255"
          />

          <subFileInput
            :title="$t('Invoice file')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('Choose a suitable file')"
          />
          <formInput :title="$t('Reference')" v-model="item.refrance" maxlength="255" />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('Date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('Choose time and date')"
                  :button-now-translation="$t('Now')"
                  v-model="item.date"
                  :disabled="!$checkRole('stores_orders_edit_item')"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <searchGroupInput
            :type="'store'"
            :values="$database.stores"
            :inputs="[{ title: $t('Product store'), show: 'name' }]"
          />

          <selectInput
            :title="$t('Situation')"
            v-model="item.status"
            :values="[
              { name: $t('Pending'), value: 0 },
              { name: $t('Loaded'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('Products information')"
            :description="$t('Bill product information')"
          />
          <productsTable class="col-12" />

          <dvider
            :title="$t('Operation information')"
            :description="$t('Additional operation information')"
          />

          <formTextarea :title="$t('That is about')" v-model="item.description" />
          <formTextarea :title="$t('Note')" v-model="item.notes" />

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'storesOrdersCreate' && !$parent.stopEdit"
          >
            {{ $t('Add operation') }}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'storesOrdersEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit operation') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'

import formInput from '@/elements/add/form/formInput.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import subFileInput from '@/elements/add/form/subFileInput.vue'

import productsTable from './components/storeOrders/productsTable.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'
import loading from '@/elements/add/loading.vue'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  data() {
    return {
      path: '/storesOrders',
      item: {
        date: '',
        invoice_group: this.$option.stores_order_invoice_group_id,
        store_id: 0,
        store: {},
        quotationItems: [],
        ProductsCount: 0,
        status: 0
      },
      errors: {
        store_id: false,
        invoice_group: false
      },
      itemIndex: null,
      print: this.$route.name == 'storesOrdersCreate' ? true : false
    }
  },
  mounted() {
    this.$updateDatabase([
      'productQuantities',
      'productUnits',
      'units',
      'products',
      'invoiceGroups',
      'stores'
    ])
    if (this.$route.name == 'storesOrdersEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      if (this.$route.params.id) {
        this.item.store_id = this.$route.params.id
      }

      this.addQuotationItem()
    }
    if (this.$route.name == 'storesOrdersCreate') {
      var params = this.$getParams()
      if (params.store_id) {
        var store = this.$database.stores.find((el) => el.id == params.store_id)
        this.item.store_id = params.store_id
        this.item.store = store
      }
      this.item.date = this.$nowDate()
    }
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.$parent.stopEdit = false
        this.item = response.data
        this.item.quotationItems.forEach((item) => {
          if (item.track_quantity == 1) {
            var productQuantity = this.$database.productQuantities.find(
              (el) => el.product_id == item.product_id && el.store_id == this.item.store_id
            )
            if (productQuantity) {
              item.maxQuantity = productQuantity.quantity + item.quantity
            }
          }
        })
        this.item.quotationItems.forEach((item) => {
          var product = this.$database.products.find((el) => el.id == item.product_id)
          if (product) {
            var productUnits = this.$database.productUnits.filter(
              (el) => el.product_id == product.id
            )
            var units = []
            var unit = this.$database.units.find((el) => el.id == product.unit_id)
            if (unit) {
              var productUnit = {
                unit: unit,
                quantity: 1
              }
              units.push(productUnit)
            }
            productUnits.forEach((productUnit) => {
              var unit = this.$database.units.find((el) => el.id == productUnit.unit_id)
              if (unit) {
                productUnit.unit = unit
                units.push(productUnit)
              }
            })
            item.units = units
          }
        })

        if (this.item.quotationItems.length == 0) {
          this.addQuotationItem()
        }
        this.item.date = this.$dateFormat(this.item.date, true)
      })
    },
    saveItem() {
      var error = 0
      this.errors = []
      if (
        (typeof this.item.store_id === 'undefined' ||
          this.item.store_id == '' ||
          this.item.store_id == 0) &&
        this.$route.name == 'storesOrdersCreate'
      ) {
        error = 1
        this.errors.store_id = true
      }
      if (
        (typeof this.item.invoice_group === 'undefined' ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == '' ||
          this.item.invoice_group == null) &&
        this.$route.name == 'storesOrdersCreate'
      ) {
        error = 1
        this.errors.invoice_group = true
      }
      this.item.quotationItems.forEach((quotationItem) => {
        if (quotationItem.quantity == '' || quotationItem.quantity <= 0) {
          error = 1
          quotationItem.quantityError = true
        } else {
          quotationItem.quantityError = false
        }
        if (quotationItem.product_name == '') {
          error = 1
          quotationItem.productNameError = true
        } else {
          quotationItem.productNameError = false
        }
      })
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == 'storesOrdersCreate') {
          this.$toast.success(this.$t('The invoice has been successfully added'))
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          localStorage.database = JSON.stringify(this.$database)
          this.$router.go(-1)
          this.$parent.stopEdit = false
        } else if (this.$route.name == 'storesOrdersEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$parent.stopEdit = false
              this.$toast.success(this.$t('Invoice has been modified successfully'))
              this.$router.go(-1)
              return response

              this.$parent.stopEdit = false
              this.loading = false
            })
        }
      } else {
        this.$toast.success(this.$t('Please make sure of the input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    addQuotationItem() {
      this.item.quotationItems.push({
        id: '',
        invoice_id: this.item.id,
        add_product: false,
        product_name: '',
        product_id: 0,
        description: '',
        unit_id: 0,
        quantity: '',
        count: '',
        quantityError: false,
        productNameError: false,
        priceError: false
      })
    },
    changePrice() {
      this.item.ProductsCount = 0
      this.item.quotationItems.forEach((qitem) => {
        this.item.ProductsCount += qitem.quantity - 0
      })
    }
  },
  components: {
    dvider,
    addHeader,
    formInput,
    connectionInput,
    formTextarea,
    monyInput,
    selectInput,
    productsTable,
    loading,
    checkInput,
    subFileInput,

    VueCtkDateTimePicker,
    searchGroupInput
  },
  watch: {
    '$option.invoice_invoice_group_id': function (val) {
      this.item.invoice_group = val
    }
  }
}
</script>
